import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "../components/Style/Checkout.css"
import Header from "./Wholeseller/Header";
import BASE_URL from './Config';
import Cookies from "js-cookie";

function ForgotPin() {
  const phone_ref = useRef("");
  const [loader, setloader] = useState(false);
  const [user_data, setData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const user = localStorage.getItem("user");
  const [errors, seterrors] = useState(true);

  const initialValues = {
    phone: "",
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    } //We need to return it as a dependency
  }, [])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      check_phone();
    }
  }

  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!phone_ref.current.value) {
      errors.phone = "Enter Your Phone No";
    } else if (!phone_regex.test(phone_ref.current.value)) {
      errors.phone = "Enter a Valid phone no";
    }
    return errors;
  };

  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});

  const SetPhoneInLocalStorage = (phone_no) => {
    const phone = localStorage.getItem("phone");
    if (phone) {
      localStorage.removeItem("phone");
    }
    localStorage.setItem("phone", phone_no);
  };

  const SetPhoneInCookies = (phone_no) => {
    const phone = Cookies.get("phone");
    if (phone) {
      Cookies.remove("phone")
    }
    Cookies.set('phone', phone_no, { expires: 2 });
  };

  const SetOTPInLocalStorage = (OTP) => {
    const otp = localStorage.getItem("OTP");
    if (otp) {
      localStorage.removeItem("OTP");
    }
    localStorage.setItem("OTP", OTP);
  }

  const SetOTPInCookies = (OTP) => {
    const otp = Cookies.get("OTP");
    if (otp) {
      Cookies.remove("OTP");
    }
    Cookies.set("OTP", OTP, { expires: 2 });
  }

  const send_code = async (phone) => {
    var digits = '0123456789';
    var OTP = '';
    for (var i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    try {
      SetOTPInCookies(OTP)
      const response = await fetch(`${BASE_URL}/OTP/${phone}/${OTP}`, {
        method: 'POST',
      }).catch(error => {
        console.log("abc")
      });
      const Data = await response.text();
      console.log(Data);
    }
    catch (error) {
      toast.error("Please Try Again");
    }
  }

  useEffect(() => {
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0) {
      seterrors(false)
    }
  }, [formError])

  const check_phone = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    const errors_count = Object.keys(formError).length;
    if (!errors && errors_count === 0) {
      setloader(true);
      let phone_no = phone_ref.current.value;
      if (phone_no.charAt(0) === "+92") {
        phone_no = "0" + phone_no.slice(1);
      }
      const response = await fetch(`${BASE_URL}/OTP/${phone_no}`);
      const response_retailer = await fetch(`${BASE_URL}/Retailers/OTP/${phone_no}`);
      const response_wholeseller = await fetch(`${BASE_URL}/WholesellerControllerSignUp/OTP/${phone_no}`);

      if (response && response_retailer.status === 200 || response_wholeseller.status === 200) {
        SetPhoneInCookies(phone_no);
        // send_code(phone_no);
        // navigate(`/ForgotPinOTPVerification`);
        navigate("/SetNewPin")
      }
      if (response.status !== 200) {
        toast.error("Phone Number Not Found");
      }
      setloader(false);
    }
  };

  return (
    <div style={{ maxHeight: "100vh" }} className="overflow-hidden">
      <Header></Header>
      <ToastContainer></ToastContainer>
      <Row style={{ marginLeft: "2.45%" }}>
        <Col md={9}>
          {/* <div className="flex mt-3">
            <div className="mt-2">
              <h3 className="text-2xl font-bold">Enter your phone number</h3>
              <Form.Text muted className="text-xs">
                We will send you the 4 digit verification code
              </Form.Text>
            </div>
          </div>
          <Form>
            <Form.Group style={{ width: "36%" }} className="mt-4 mb-4">
              <Form.Control
                ref={phone_ref}
                style={{ border: "1px solid #333333" }}
                className="Poppins"
                onChange={changehandler}
                placeholder="+92-XXX-XXXXX"
              ></Form.Control>
              <Form.Text muted>
                <p className="text-xs text-red-500 absolute">
                  {formError.phone}
                </p>
              </Form.Text>
            </Form.Group>
            <div className="flex gap-3">
              <Button
                style={{ width: "31%" }}
                onClick={check_phone}
                className="submit-button"
              >
                Continue
              </Button>
              {loader && <Spinner></Spinner>}
            </div>
          </Form> */}
          <div className="mt-4 mb-4 flex gap-4">
            <h3 className="text-2xl font-bold">Forgot Pin</h3>
          </div>
          <div className="text-sm w-[80%] text-justify">
            Kindly direct your inquiries via email to <span className="font-medium text-blue-500">bookmyorder.pk@gmail.com</span>. Our team will promptly respond to your message at the earliest convenience. Thank you.
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default ForgotPin;
