export const action_type = {
    SET_USER : 'SET_USER',
    SET_CART_ITEMS: "SET_CART_ITEMS",
    SET_OTP:"SET_OTP",
}

const reducer = (state, action) =>{
  // console.log(action);
  if(action.type === 'SET_USER'){
      return{
          ...state, //whatever the state keep the state as it is
          user : action.user, //through action we update the user information
      }
  }
      //here we need to dispatch information in 2 places 
      //1: initially when the app is getting loaded (App.js) and 2: whenever we are saving it we need to dispatch the information
  if(action.type === 'SET_CART_ITEMS'){
      return{
          ...state,
          cartItems: action.cartItems,
      }
  }
  if(action_type === "SET_OTP"){
    return{
        ...state,
        otp : action.payload,
    }
  }
  return state;
};

export default reducer;
