import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import BASE_URL from '../Config';
import { Spinner } from "react-bootstrap";



function ColumnsModal(props) {
  const column_handler = (e) =>{
    const value = e.target.value;
    props.column_handler(value)
  }
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal style={{width:"50%", left:"30%", top:"20%"}} show={props.show} onHide={props.handleClose}>
        <p className="text-xs text-center">View columns</p>
        <Modal.Body>
            <label className="flex mb-2 hover:bg-slate-200"><span>Id</span><Form.Check checked={props.show_id} onChange={column_handler} className="ml-auto" value="id" type="checkbox" name="group1"></Form.Check></label>
            <label className="flex mb-2 hover:bg-slate-200"><span>Offer</span><Form.Check checked={props.show_offer} onChange={column_handler} className="ml-auto" value="offer" type="checkbox" name="group1"></Form.Check></label>
            <label className="flex mb-2 hover:bg-slate-200"><span>Bonus</span><Form.Check checked={props.show_bonus} onChange={column_handler} className="ml-auto" value="bonus" type="checkbox" name="group1"></Form.Check></label>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ColumnsModal;
