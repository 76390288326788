import AddForm from "./AddProductForm";
import AdminHeader from "./Admin-header";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditModal from "./EditModal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/CRUD.css";
import delete_icon from "../../images/delete-icon.svg";
import edit_icon from "../../images/edit-icon.svg";
import  search_icon  from "../../images/ri_search-line.svg";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import view_icon from "../../images/view-icon.svg"
import disable_icon from "../../images/disabled.svg"
import {motion, useAnimation } from "framer-motion";
import Aos from "aos";

function AdminPanel() {
  const [edit, setEdit] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [data, setData] = useState([]);
  const [id, setid] = useState(0);

  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(Cookies.get("user"));

  const [{ user }] = useStateValue();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [add, setAdd] = useState(false);
  const [error_msg, set_error_msg] = useState("");
  const [IsRetailer, setIsRetailer] = useState(false);
  const [loader, setloader] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [is_sticky, setIsSticky] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const controls = useAnimation();


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const menu = document.getElementById("menu")
      if(currentScrollPos > 200){
        setIsSticky(true)
      }
      else{
        setIsSticky(false);
      }
      
      setPrevScrollPos(currentScrollPos);
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    // Use Framer Motion to animate the search bar when it becomes sticky or not
    controls.start({ opacity: is_sticky ? 1 : 1, y: is_sticky ? -4 : 0 });
  }, [is_sticky, controls]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setMobileView(false);
      } // Set breakpoint here
      if (window.innerWidth <= 500) {
        setMobileView(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // const data = [
  //   {
  //     id: 1,
  //     product_name: "Panadol",
  //     manufacturer: "Pfizer",
  //     offer: 5,
  //     bonus: 1,
  //     Exp_date: "25/1/2023",
  //   },
  // ];

  useEffect(() => {
    if (user && user.type === "retailer") {
      setIsRetailer(true);
    }
  }, [])

  const get_data = async () => {
    setloader(true);
    const response = await fetch(
      `${BASE_URL}/Admin`).catch(error=>{
        console.log("abc")
      });
    const Data = await response.json();
    const loaded_data = [];
    for (const key in Data) {
      loaded_data.push({
        id: Data[key].id,
        product_name: Data[key].product_name,
        manufacturer: Data[key].manufacturer,
        offer: Data[key].offer,
        bonus: Data[key].bonus,
        total_price: Data[key].total_price,
        is_disabled: Data[key].is_disabled
      })
    }
    setloader(false);
    setData(loaded_data);
    if (!data || loaded_data.length === 0 || data.length === 0) {
      set_error_msg("Data Not Found");
    }
  };

  useEffect(() => {
    get_data();
  }, []);

  const EditHandler = (id) => {
    setEdit(true);
    setid(id);
  };

  const DeleteHandler = (id) => {
    setDelete(true);
    if (window.confirm("Are You Sure") === true) {
      axios
        .put(`${BASE_URL}/Admin/UpdateStatus/${id}`)
        .then((result) => {
          if (result.status === 200) {
            get_data();
            toast.success("Status Updated Successfully");
            CloseHandler();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const CloseHandler = () => {
    setEdit(false);
    setDelete(false);
    setAdd(false);
  };

  let categories = new Set();
  for (const key in data) {
    categories.add(data[key].manufacturer);
  }
  categories =  Array.from(categories).sort();

  let filter_items = [];

  const filter_categories = [];
  const filter_search_categories = [];
  for (const i of categories) {
    filter_categories.push({ cat: i });
    filter_items.push(data.filter((f) => f.manufacturer === i));
  }

  

  

  // const filter = data.filter((f) => {
  //   return f.manufacturer === "GSK";
  // });
  // console.log(filter);

  // const a = []

  const search_ref = useRef();

  const searchHandler = () => {
    if (search_ref.current.value !== "") {
      const newList = data.filter((d) => {
        return Object.values(d)
          .join(" ")
          .toLowerCase()
          .includes(search_ref.current.value.toLowerCase());
      });
      setSearchList(newList);
      for (const i of categories) {
        let a = searchList.filter((f) => f.manufacturer === i)
        if(a.length > 0){
          filter_search_categories.push({ cat: i });
          console.log(filter_search_categories);
        }
      }
    } else {
      get_data();
    }
  };


  return (
    <div className="overflow-hidden">
      <AdminHeader></AdminHeader>
      <ToastContainer />
      <Row style={{ marginLeft: "2%", marginRight: "0.5%" }}>
        <Col md={9} xs={12}>
          <div>
            <AddForm toast={toast} getData={get_data}></AddForm>
          </div>
          <div>
            <InputGroup id="search" >
                <Form.Control
                  className="search"
                  id="menu"
                  placeholder="Search"
                  onChange={searchHandler}
                  ref={search_ref}
                />
                <button onClick={searchHandler} class="search-icon w-16" type="button">
                    <img src={search_icon} className="ml-auto mr-auto w-5 h-5" ></img>
                </button>
            </InputGroup>
          </div>
          <div className="table-scroll text-sm md:text-base" style={{ fontFamily: "Poppins" }}>
            <Table id="table">
              <thead className="text-[11px] sm:text-xs md:text-base">
                <tr className="text-center">
                 {!mobileView && <th>ID</th>}
                  <th>Product</th>
                  <th>Offer</th>
                  <th>Bonus</th>
                  <th >T.P</th>
                  <th>Action</th>
                </tr>
              </thead>
              {filter_categories.length > 0 &&
                search_ref.current.value.length === 0 ? (
                filter_categories.map((item, index) => (
                  <tbody className="text-[11px] sm:text-xs md:text-sm xl:text-sm">
                    <tr
                      key={index.cat}
                      style={{ height: "50px" }}
                      className="Category"
                    >
                      <td
                        colspan="8"
                      >
                        <h1 className="text-lg">{item.cat}</h1>
                      </td>
                    </tr>
                    {data
                      .filter((f) => f.manufacturer === item.cat)
                      .map((item, index) => (
                        <tr key={index} className={item.is_disabled ? `text-center opacity-50` : `text-center`}>
                          {!mobileView && <td>{item.id}</td>}
                          <td>{item.product_name}</td>
                          <td>{item.offer}%</td>
                          <td>{item.bonus}</td>
                          <td>Rs. {item.total_price}/-</td>
                          <td >
                            <div className="flex gap-2 mt-[0.75rem] justify-center">
                              <img
                                onClick={() => EditHandler(item.id)}
                                className=" cursor-pointer w-5 h-5"
                                src={edit_icon}
                              ></img>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ))
              ) : (
                filter_categories.map((item, index) => (
                  <tbody className="text-[11px] sm:text-xs md:text-sm xl:text-sm">
                  {searchList.filter((f) => f.manufacturer === item.cat).length > 0 &&
                    <tr
                      key={index.cat}
                      style={{ height: "50px" }}
                      className="Category"
                    >
                      <td
                        colspan="8"
                      >
                        <h1 className="text-xl">{item.cat}</h1>
                      </td>
                    </tr>
                    }
                    {searchList
                      .filter((f) => f.manufacturer === item.cat)
                      .map((item, index) => (
                        <tr key={index} className={item.is_disabled ? `text-center opacity-50` : `text-center`}>
                         {!mobileView && <td>{item.id}</td>}
                          <td>{item.product_name}</td>
                          <td>{item.offer}%</td>
                          <td>{item.bonus}</td>
                          <td>Rs. {item.total_price}/-</td>
                          <td >
                            <div className="flex gap-2 mt-[0.75rem] justify-center">
                              <img
                                onClick={() => EditHandler(item.id)}
                                className=" cursor-pointer w-5 h-5"
                                src={edit_icon}
                              ></img>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ))
              )}
            </Table>
            {loader && <Spinner></Spinner>}
            {data.length === 0 && <p>{error_msg}</p>}
            {!searchList && <p>No Data Found</p>}
          </div>
        </Col>
      </Row>
      {edit && (
        <EditModal id={id} get_data={get_data} DeleteHandler={DeleteHandler} show={edit} handleClose={CloseHandler}></EditModal>
      )}
      {IsRetailer && (
        <AlertSignedInAs show={IsRetailer}></AlertSignedInAs>
      )}
    </div>
  )
}
export default AdminPanel