import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";


function SellerInfoModal(props) {
    const navigate = useNavigate();
    const[{user}, dispatch] = useStateValue();
    const [seller_info, setSellerInfo] = useState([]);
    const { url } = useParams();
    const [loader, setloader] = useState(false);

    useEffect(()=>{
        const SellerInfoHandler = async() =>{
          setloader(true)
            const response_wholeseller = await fetch(`${BASE_URL}/WholesellerCategory/url/${url}`);
            const data = await response_wholeseller.json();
            setSellerInfo(data);
            setloader(false);
        }
        SellerInfoHandler();
    },[])

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Container style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
          <Modal.Header className="font-semibold text-lg flex">
            <div>Seller Info</div>
            {loader && <Spinner size="sm"></Spinner>}
          </Modal.Header>
          <Modal.Body>
            <Container className="text-sm">
                <Row className="mb-6">
                    <Col className="flex gap-2">
                    <div className="font-bold">Name:</div>
                    <div>{seller_info.full_name}</div>
                    </Col>
                    <Col className="flex gap-2">
                    <div className="font-bold">Store:</div>
                    <div>{seller_info.busniess_name}</div>
                    </Col>
                </Row>
                <Row>
                    <Col className="flex gap-2">
                    <div className="font-bold">Address:</div>
                    <div>{seller_info.address}</div>
                    </Col>
                    <Col className="flex gap-2">
                    <div className="font-bold">Phone:</div>
                    <div>{seller_info.phone}</div>
                    </Col>
                </Row>      
            </Container>
          </Modal.Body>
          <Modal.Footer className="flex">
          </Modal.Footer>
        </Container>
      </Modal>
    </div>
  );
}
export default SellerInfoModal;
