import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import "../Style/otpVerification.css"
import CartSummary from "./CartSummary";
import { useStateValue } from "../../store/stateprovider";
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { action_type } from "../../store/reducer";

function PINVerification() {
  const digit_1 = useRef("");
  const digit_2 = useRef("");
  const digit_3 = useRef("");
  const digit_4 = useRef("");
  const [showCartSummary, setCartSummary] = useState(false);
  const navigate = useNavigate();
  const {url} = useParams();
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [loader, setloader] = useState(false);
  

  useEffect(()=>{  
    document.addEventListener('keydown',keyDownHandler);
  
    return ()=>{
      document.removeEventListener('keydown',keyDownHandler);
    } //We need to return it as a dependency
  },[])
  
  const keyDownHandler = (event) =>{
    if(event.key === 'Enter'){
      verifyPIN();
    }
  }


  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else{
            inputs[index + 1].focus();
            inputs[index + 1].select();
          }
        }
        if (input.value.length !== input.maxLength) {
          inputs[index - 1].focus();
          inputs[index - 1].select();

      }
      })
    })
  },[])

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1000){
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < 1000){
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  const CloseHandler = () => {
    setCartSummary(false);
    setcartmobile(false);
  }

  const setMessage = () =>{
    setcartmobile(true);
  }

  // const phone = localStorage.getItem('phone');
  const phone = Cookies.get("phone");

  const verifyPIN = async () => {
    setloader(true);
    const typed_pin = digit_1.current.value + digit_2.current.value + digit_3.current.value + digit_4.current.value;
    const response = await fetch(`${BASE_URL}/Retailers/${phone}/${typed_pin}/retailer`).catch(error=>{
      console.log("abc")
    });
    if(!response){
      setloader(false);
      return toast.error("Error Occured. Please try again");
    }
    if(cartItems.length === 0){
      setloader(false);
      return toast.error("Cart is Empty");
    }
    if (response.status === 200 && cartItems.length !== 0) {
      toast.success("PIN Verified");
      const Data = await response.json();
      // localStorage.setItem(
      //   "user",
      //   JSON.stringify({
      //     id: Data.id,
      //     email: Data.email,
      //     phone: Data.phone,
      //     fullName: Data.fullName,
      //     store: Data.store,
      //     address: Data.address,
      //     type: "retailer"
      //   }));

      Cookies.set("user",JSON.stringify({
        id: Data.id,
          email: Data.email,
          phone: Data.phone,
          fullName: Data.fullName,
          store: Data.store,
          address: Data.address,
          type: "retailer"
      }),{expires:2});
      dispatch({
        type: action_type.SET_USER,
        user: Data,
        //if you look at the console.log(response) you will see that providerData is an array
      });
      setCartSummary(true);
    }
    if(response.status !== 200) {
      toast.error("PIN does'nt Match, Try again");
    }
    
    setloader(false);
  }

  const send_code = async (phone) => {
    var digits = '0123456789';
    var OTP = '';
    for (var i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    try {
      SetOTPInLocalStorage(OTP)
      const otp_data = {
        userid : user.id,
        usr_type: user.type
      }
      const response = await fetch(`${BASE_URL}/OTP/${phone}/${OTP}`,{
        method: 'POST',
      });
      const Data = await response.text();
      console.log(Data);
      navigate(`/RetailerForgotPin/${url}`);
    }
    catch (error) {
      toast.error("Please Try Again");
    }
  }

  const SetOTPInLocalStorage = (OTP) => {
    const otp = localStorage.getItem("OTP");
    if (otp) {
      localStorage.removeItem("OTP");
    }
    localStorage.setItem("OTP", OTP);
  }

  const forgotPin = () => {
    try{
    // send_code(phone)
    navigate(`/RetailerSetNewPin/${url}`)
    }
    catch(error){
      toast.error("Error Occured Please try again")
    }
  }


  return (
    <div style={{maxHeight:"100vh"}} className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{ marginLeft: "2%" }}>
        <Col md={9} >
          <div className="breadcrum flex md:gap-3 sm:gap-9 ">
            {/* <p>Products</p>
            <div className="dot"></div>
            <p>Checkout/Register</p>
            <div className="dot"></div>
            <p className="active">OTP</p>
            <div className="dot"></div>
            <p>Set Passcode</p> */}
          </div>

          <div className="breadcrum flex mt-3">
            <h3 className="text-2xl font-bold text-black">Enter the PIN Code</h3>
          </div>
          <div>
            <p className=" text-sm -mt-1 font-medium Poppins text-emerald-600">{phone}</p>
          </div>
          <Form>
            <Row style={{ width: "70%" }} className="passcode mt-4 Poppins">
              <div className="flex  gap-3">
                <Col xs={2} sm={2} md={1} xl={1}>
                  <Form.Control type="password" minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333",fontWeight:"800", fontSize:"25px" }} ref={digit_1} className="mb-3 text-center  Poppins"></Form.Control>
                </Col>
                <Col xs={2} sm={2} md={1} xl={1}>
                  <Form.Control type="password" minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333",fontWeight:"800", fontSize:"25px" }} ref={digit_2} className="mb-3 text-center Poppins"></Form.Control>
                </Col>
                <Col xs={2} sm={2} md={1} xl={1}>
                  <Form.Control type="password" minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333",fontWeight:"800", fontSize:"25px" }} ref={digit_3} className="mb-3 text-center Poppins"></Form.Control>
                </Col>
                <Col xs={2} sm={2} md={1} xl={1}>
                  <Form.Control type="password" minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333",fontWeight:"800", fontSize:"25px"}} ref={digit_4} className="mb-3 text-center  Poppins"></Form.Control>
                </Col>
              </div>
            </Row>
            <p onClick={() => (navigate("/ForgotPin"))}  className=" font-medium hover:underline cursor-pointer">Forgot PIN?</p>
          </Form>
          <Button onClick={verifyPIN} className="submit-button w-[60%] sm:w-[40%] md:w-[32%]">Submit</Button>
        </Col>
        {showCart && <Col md={3} xs={5}>
          <Cart
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
      {cartmobile && <CartMobile
            CloseHandler={CloseHandler}
            >
          </CartMobile>}
      </Col>
      {showCartSummary && <CartSummary show={showCartSummary} handleClose={CloseHandler}></CartSummary>}
    </div>
  );
}
export default PINVerification;
