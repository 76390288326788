import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Header from "./Wholeseller/Header";
import "./Style/Checkout.css";
import "./Style/otpVerification.css"
import BASE_URL from './Config';

function ForgotPinOTPVerification() {
  const digit_1 = useRef("");
  const digit_2 = useRef(""); 
  const digit_3 = useRef(""); 
  const digit_4 = useRef("");

  useEffect(()=>{  
    document.addEventListener('keydown',keyDownHandler);
  
    return ()=>{
      document.removeEventListener('keydown',keyDownHandler);
    } //We need to return it as a dependency
  },[])
  
  const keyDownHandler = (event) =>{
    if(event.key === 'Enter'){
      verifyOTP();
    }
  }

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else{
            inputs[index + 1].focus();
          }
        }
        if (input.value.length !== input.maxLength) {
          inputs[index - 1].focus();

      }
      })
    })
  },[])


  const phone = localStorage.getItem('phone');
  const {id} = useParams();
  const navigate = useNavigate();

  const verifyOTP = async() => {
    const typed_otp = digit_1.current.value + digit_2.current.value + digit_3.current.value + digit_4.current.value;
    const sent_otp = localStorage.getItem("OTP");
    console.log(typed_otp);
    if(typed_otp === sent_otp){
        navigate("/SetNewPin")
    }
    if(typed_otp !== sent_otp){
      toast.error("OTP does'nt Match, Try again");
    }
  }


  return (
    <Fragment className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <Header></Header>
      <Row style={{marginLeft:"2.5%"}}>
        <Col md={9} xs={7}>
          <div className="breadcrum flex md:gap-3 sm:gap-9 ">
            {/* <p>Products</p>
            <div className="dot"></div>
            <p>Checkout/Register</p>
            <div className="dot"></div>
            <p className="active">OTP</p>
            <div className="dot"></div>
            <p>Set Passcode</p> */}
          </div>

          <div className="breadcrum flex">
            <h3 className="text-2xl font-bold text-black">Enter the OTP</h3>
            {/* <div style={{marginLeft:"20%"}} className="flex gap-2 mt-1">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
          </div>
          <div>
            <p className="text-sm font-medium flex gap-1">Sent to - <p className="text-sm font-medium Poppins text-emerald-600">{phone}</p></p>
          </div>
          <Form>
            <Row style={{ width: "50%" }} className="passcode mt-4">
                <div className="flex  gap-3">
                 <Col xs={6} md={2}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333" }} ref={digit_1} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={6} md={2}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333" }} ref={digit_2} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={6} md={2}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333" }} ref={digit_3} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={6} md={2}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333" }} ref={digit_4} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                </div>
              <Button onClick={verifyOTP} className="submit-button">Submit</Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
}
export default ForgotPinOTPVerification;
