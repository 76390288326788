import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";


function AlertSignedInAs(props) {
  const navigate = useNavigate();
  const[{user}, dispatch] = useStateValue();
  const logout = () => {
    // localStorage.removeItem('user');
    Cookies.remove("user");
    dispatch({
      type: action_type.SET_USER,
      user: "",
      //if you look at the console.log(response) you will see that providerData is an array
    });
    navigate("/");
  }
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Container style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <Container className=" text-center text-sm">
              <div className=" font-normal">You are Signed In as </div>
              <div className=" font-semibold">Retailer</div>
              <div className="font-normal">Please Sign-out and Login In again</div>
            </Container>
          </Modal.Body>
          <Modal.Footer className="flex">
            <Button variant="warning" onClick={logout}>
              Logout
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </div>
  );
}
export default AlertSignedInAs;
