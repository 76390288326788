import React from 'react';
import '../Style/dashboardheader.css';
import NotificationIcon from '../../images/notification.svg';
import SettingsIcon from '../../images/settings.svg';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../store/stateprovider';
import profilepic from "../../images/profilepic.png"


function DashboardHeader (props) {
    const [{ user }, dispatch] = useStateValue();

    return(
        <div className='dashbord-header-container'>
            {props.btnText && 
                <button className='dashbord-header-btn' onClick={props.onClick}>{props.btnText}</button>
            }

            <div className='dashbord-header-right'>
            
                <img 
                    src={NotificationIcon}
                    alt='notification-icon'
                    className='dashbord-header-icon' />
                    
                    
                {/* <img 
                    src={SettingsIcon}
                    alt='settings-icon'
                    className='dashbord-header-icon' /> */}
                {user && (
          <Link to={"/profile"}>
            <img
              className="dashbord-header-avatar"
              src="https://reqres.in/img/faces/9-image.jpg"
            />
          </Link>
        )}
        {!user && (
          <Link to={"/SignIn"}>
            <img
              className="dashbord-header-avatar"
              src={profilepic}
            />
          </Link>
        )}
            </div>
        </div>
    )
}

export default DashboardHeader;