import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Header from "./Wholeseller/Header";
import "./Style/Checkout.css";
import "./Style/otpVerification.css"
import BASE_URL from './Config';


function RegistrationOTPVerification() {
  const digit_1 = useRef("");
  const digit_2 = useRef(""); 
  const digit_3 = useRef(""); 
  const digit_4 = useRef("");

  const phone = localStorage.getItem('phone');
  const {id} = useParams();
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  useEffect(()=>{  
    document.addEventListener('keydown',keyDownHandler);
  
    return ()=>{
      document.removeEventListener('keydown',keyDownHandler);
    } //We need to return it as a dependency
  },[])
  
  const keyDownHandler = (event) =>{
    if(event.key === 'Enter'){
      verifyOTP();
    }
  }
  
  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else{
            inputs[index + 1].focus();
          }
        }
        if (input.value.length !== input.maxLength) {
          inputs[index - 1].focus();

      }
      })
    })
  },[])


  const SetPhoneInLocalStorage = (phone_no) => {
    const phone = localStorage.getItem("phone");
    if (phone) {
      localStorage.removeItem("phone");
    }
    localStorage.setItem("phone", phone_no);
  };

  const verifyOTP = async() => {
    setloader(true);
    const typed_otp = digit_1.current.value + digit_2.current.value + digit_3.current.value + digit_4.current.value;
    console.log(typed_otp);
    const retailer_data = JSON.parse(localStorage.getItem("retailer"));
    const wholeseller_data = JSON.parse(localStorage.getItem("wholeseller"));
    const sent_otp = localStorage.getItem("OTP");
    if(typed_otp === sent_otp){
      SetPhoneInLocalStorage(phone);
      toast.success("OTP Verified")
      if(retailer_data){
         const response = await fetch(`${BASE_URL}/Retailers`, {
          method: "POST",
          body: JSON.stringify(retailer_data),
          headers: { "Content-Type": "application/json" },
        }).catch(error=>{
          console.log("abc")
        });
        const data = await response.json();
        console.log(data);
        toast.success("Retailer Registered Successfully");
        localStorage.removeItem("retailer");
      }
      if(wholeseller_data){
         const response = await fetch(
          `${BASE_URL}/WholesellerControllerSignUp`,
          {
            method: "POST",
            body: JSON.stringify(wholeseller_data),
            headers: { "Content-Type": "application/json" },
          }
        ).catch(error=>{
          console.log("abc")
        })
        const data = await response.json();
        console.log(data);
        toast.success("Wholeseller Registered Successfully");
        localStorage.removeItem("wholeseller");
      }
      navigate("/registeredsuccessfully");
    }
    if(typed_otp !== sent_otp){
      toast.error("OTP does'nt Match, Try again");
    }
    setloader(false);
  }


  return (
    <Fragment className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <Header></Header>
      <Row style={{marginLeft:"2.5%"}}>
        <Col md={7} xs={9}>
          <div className="breadcrum flex md:gap-3 sm:gap-9 ">
            {/* <p>Products</p>
            <div className="dot"></div>
            <p>Checkout/Register</p>
            <div className="dot"></div>
            <p className="active">OTP</p>
            <div className="dot"></div>
            <p>Set Passcode</p> */}
          </div>

          <div className="breadcrum flex">
            <h3 className="text-2xl font-bold text-black">Enter the OTP</h3>
            {/* <div style={{marginLeft:"20%"}} className="flex gap-2 mt-1">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
          </div>
          <div>
            <p className="text-sm font-medium flex gap-1">Sent to - <p className="text-sm font-medium Poppins text-emerald-600">{phone}</p></p>
          </div>
          <Form>
            <Row style={{ width: "50%" }} className="passcode mt-4">
                <div className="flex  gap-3">
                 <Col xs={6} md={2}> 
                <Form.Control minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333" }} ref={digit_1} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={6} md={2}> 
                <Form.Control minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333" }} ref={digit_2} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={6} md={2}> 
                <Form.Control minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333" }} ref={digit_3} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={6} md={2}> 
                <Form.Control minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333" }} ref={digit_4} className="mb-4 text-center Poppins" ></Form.Control>
                </Col>
                </div>
              {loader ? <Button className="submit-button ml-3"><Spinner size="sm"></Spinner></Button> :
              <Button onClick={verifyOTP} className="submit-button ml-3">Submit</Button>}
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
}
export default RegistrationOTPVerification;
