import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';


function LoginCheckoutForm() {
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [flag, setFlag] = useState();
  const { store } = useParams();
  const navigate = useNavigate();
  const [tot, setTot] = useState(0);
  const [errors, seterrors] = useState(true);
  const [order_id, setOrder_id] = useState(0);
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [wholeseller_data, setwholeseller_data] = useState({});
  const [id, setid] = useState()
  let items = [];

  const name_ref = useRef("");
  const phone_ref = useRef("");
  const email_ref = useRef("");
  const store_ref = useRef("");
  const address_ref = useRef("");

  const initialValues = {
    name: "",
    phone: "",
    store: "",
    address: "",
  };

  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});

  useEffect(() => {
    let totalPrice = cartItems.reduce(function (accumulator, item) {
      return accumulator + item.quantity * item.total_price;
    }, 0);
    setTot(totalPrice);
  }, [tot, cartItems]);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1000){
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < 1000){
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearCart = () => {
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: [],
    });
    localStorage.setItem("cartItems", JSON.stringify([]));
  };

  const CloseHandler = () => {
    setcartmobile(false);
  }

  const setMessage = () =>{
    setcartmobile(true);
  }

  const WholesellerData = async() =>{
    const response_id = await fetch(`${BASE_URL}/WholesellerCategory/wholeseller/${store}`);
    const Id = await response_id.text();
    const response = await fetch(`${BASE_URL}/WholesellerControllerSignUp/${Id}`);
    setid(Id);
    return await response.json();
  }
  
  
  const getWholesellerData = async() =>{
    const a = await WholesellerData();
    setwholeseller_data(a)
  }

  useEffect(()=>{
    getWholesellerData();
  },[])

 console.log(wholeseller_data);


  const cartDispatch = () => {
    localStorage.setItem("cartItems", JSON.stringify(items));
    setFlag(1);
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: items,
    });
  };

  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!name_ref.current.value) {
      errors.name = "Product Name is Required";
    }
    if (phone_ref.current.value.length !== 11) {
      errors.phone = "Enter a valid phone no";
    }

    if (!store_ref.current.value) {
      errors.store = "Enter your store name";
    }

    if (address_ref.current.value.length < 10) {
      errors.address = "Plz enter your complete address";
    }
    return errors;
  };

  const handleCreateOrder = async () => {
    const product_data = {
      retailer_name: name_ref.current.value,
      total: tot,
      status: "Pending",
      seller_store: store,
      store: store_ref.current.value,
      phone_no: phone_ref.current.value,
      seller_phone_no: wholeseller_data.phone,
      address: address_ref.current.value,
      wholeseller_id: id,
    };
    const response = await fetch(`${BASE_URL}/Orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(product_data),
    });
    const order_id = await response.text();
    setOrder_id(order_id);
    return order_id;
  };

  const Buy_Now = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0 ) {
      const order_id = await handleCreateOrder();
      for (const key in cartItems) {
        const product_data = {
          productName: cartItems[key].product_name,
          quantity: cartItems[key].quantity,
          price: cartItems[key].total_price,
          orderId: order_id,
        };

        const response = await fetch(`${BASE_URL}/Orders/${order_id}/items`, {
          method: "POST",
          body: JSON.stringify(product_data),
          headers: { "Content-Type": "application/json" },
        });
        const data = await response.json();
        console.log(data);
      }
      seterrors(false);
    }
    if (cartItems.length >= 1) {
      clearCart();
      toast.success("Order Placed Succesfully");
      navigate(`/ordersRetailer/${store}`);
    }
    if (cartItems.length === 0) {
      toast.error("Add items to your cart");
    }
  };

  return (
    <Fragment className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{ marginLeft: "2.5%", fontFamily: "Poppins" }}>
        <Col xs={9}>
          <div className="mt-5">
            <h3 className="text-2xl font-bold">Checkout</h3>
          </div>
          <Form>
            <Form.Group style={{ width: "70%" }} className="mt-4">
              <Row className="gap-6">
                <Col>
                  <Form.Label className="text-sm">Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ border: "1px solid #333333" }}
                    ref={name_ref}
                    onChange={changehandler}
                  ></Form.Control>
                  <Form.Text muted>
                    <p className="text-xs text-red-500 absolute">
                      {formError.name}
                    </p>
                  </Form.Text>
                </Col>
                <Col>
                  <Form.Label className="text-sm">Phone</Form.Label>
                  <Form.Control
                    type="number"
                    style={{ border: "1px solid #333333" }}
                    ref={phone_ref}
                    onChange={changehandler}
                  ></Form.Control>
                  <Form.Text muted>
                    <p className="text-xs text-red-500 absolute">
                      {formError.phone}
                    </p>
                  </Form.Text>
                </Col>
              </Row>
              <Row className="gap-6 mt-5">
                <Col>
                  <Form.Label className="text-sm">Email (optional)</Form.Label>
                  <Form.Control
                    type="email"
                    style={{ border: "1px solid #333333" }}
                    ref={email_ref}
                    onChange={changehandler}
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Label className="text-sm">Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ border: "1px solid #333333" }}
                    ref={store_ref}
                    onChange={changehandler}
                  ></Form.Control>
                  <Form.Text muted>
                    <p className="text-xs text-red-500 absolute">
                      {formError.store}
                    </p>
                  </Form.Text>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <Form.Label className="text-sm">Address</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ border: "1px solid #333333" }}
                    ref={address_ref}
                    onChange={changehandler}
                  ></Form.Control>
                  <Form.Text muted>
                    <p className="text-xs text-red-500 absolute">
                      {formError.address}
                    </p>
                  </Form.Text>
                </Col>
                <Col></Col>
              </Row>
            </Form.Group>
          </Form>
        </Col>
       {showCart && <Col md={3}>
          <Cart
            onClick={Buy_Now}
            btnText={"Order Now"}
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
      {cartmobile && <CartMobile
            onClick={Buy_Now}
            btnText={"Order Now"}
            CloseHandler={CloseHandler}
            >
          </CartMobile>}
      </Col>
    </Fragment>
  );
}
export default LoginCheckoutForm;
