import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";
import Header from "../Wholeseller/Header";
import axios from "axios";
import HeaderRetailer from "./HeaderRetailer";
import AlertSignedInAs from "./AlertSignedInAS";
import BASE_URL from '../Config';
import Cookies from "js-cookie";

function RetailerProfile() {
    const name_ref = useRef("");
    const phone_ref = useRef("");
    const passcode_1 = useRef("");
    const passcode_2 = useRef("");
    const passcode_3 = useRef("");
    const passcode_4 = useRef("");
    const store_ref = useRef("");
    const address_ref = useRef("");
    const [IsSeller, setIsSeller] = useState(false);
    const [Store, setstore] = useState("");
    const [update_disable, set_update_disable] = useState(true);

    const [errors, seterrors] = useState(true);
    const [{ user }, dispatch] = useStateValue();
    const [loader, setloader] = useState(false);
    const [recentstores, setrecentstores] = useState([]);
    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
        if (user && user.type === "seller") {
            setIsSeller(true);
        }
    }, [])

    useEffect(() => {
        setstore(user.store);
    }, [])




    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000); // Set breakpoint here
        };
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);




    // const data = JSON.parse(localStorage.getItem("user"))

    useEffect(() => {
        const Edit = async () => {
            setloader(true);
            const response = await fetch(`${BASE_URL}/Retailers/${user.id}`).catch(error => {
                console.log("abc");
            });
            if (!response) {
                setloader(false);
                toast.error("Error Occured! Please try again");
            }
            const Data = await response.json();
            name_ref.current.value = Data.fullName
            phone_ref.current.value = Data.phone;
            passcode_1.current.value = Data.password[0];
            passcode_2.current.value = Data.password[1];
            passcode_3.current.value = Data.password[2];
            passcode_4.current.value = Data.password[3];
            store_ref.current.value = Data.store;
            address_ref.current.value = Data.address;
            setstore(Data.store);
            setloader(false);
        }

        Edit();
    }, [])

    const GetRecentStores = async () => {
        const response = await fetch(`${BASE_URL}/RecentStore/${user.id}`);
        const Data = await response.json();
        console.log(Data);
        const loaded_data = [];
        for (const key in Data) {
            loaded_data.push({
                url: `${Data[key].url}`
            })
            setrecentstores(loaded_data);
        }
    }

    useEffect(() => {
        GetRecentStores();
    }, [])


    useEffect(() => {
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input, index) => {
            input.addEventListener('input', () => {
                if (input.value.length === input.maxLength) {
                    if (index === inputs.length - 1) {
                        input.blur();
                    }
                    else {
                        inputs[index + 1].focus();
                        inputs[index + 1].select();
                    }
                }
            })
        })
    }, [])


    const initialValues = {
        name: "",
        phone: "",
        passcode_1: "",
        passcode_2: "",
        passcode_3: "",
        passcode_4: "",
        store: "",
        address: ""
    };
    const [formValues, setformValues] = useState(initialValues);
    const [formError, setFormErrors] = useState({});
    const [IsSignup, setisSignUp] = useState(false);
    const [order_id, setOrder_id] = useState();
    const navigate = useNavigate();



    const changehandler = (e) => {
        const { name, value } = e.target;
        setformValues({ ...formValues, [name]: value });
        set_update_disable(false);
    };

    const validate = () => {
        const errors = {};
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phone_regex =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        const name_regex = /^[a-zA-Z\s]+$/;

        if (!name_ref.current.value) {
            errors.name = "Full Name is Required";
            // } else if (!regex.test(email_ref.current.value)) {
            //   errors.email = "Enter a Valid Email address";
        }
        else if (!name_regex.test(name_ref.current.value)) {
            errors.name = "Alphabets only";
            // } else if (!regex.test(email_ref.current.value)) {
            //   errors.email = "Enter a Valid Email address";
        }

        if (!phone_ref.current.value) {
            errors.phone = "Phone No is Required";
        } else if (!phone_regex.test(phone_ref.current.value)) {
            errors.phone = "Enter a Valid Phone No";
        }
        else if (phone_ref.current.value.length < 11) {
            errors.phone = "Enter a Valid Phone No";
        }

        if (!passcode_1.current.value || !passcode_2.current.value || !passcode_3.current.value || !passcode_4.current.value) {
            errors.password = "Passcode is Required";
        }
        if (!store_ref.current.value) {
            errors.store = "Store is Required";
        }
        if (!address_ref.current.value) {
            errors.address = "Address No is Required";
        }

        return errors;
    };

    useEffect(() => {
        const errors_count = Object.keys(formError).length;
        if (errors_count === 0) {
            seterrors(false);
        }
        if (errors_count > 0) {
            seterrors(true);
        }
    }, [formError])

    const edituser = async () => {
        setloader(true);
        setFormErrors(validate(formValues));
        const errors_count = Object.keys(formError).length;
        if (!errors && errors_count === 0) {
            let phone_no = phone_ref.current.value;
            if (phone_no.charAt(0) === "+92") {
                phone_no = "0" + phone_no.slice(1);
            }

            const retailer_data = {
                "id": user.id,
                "fullName": name_ref.current.value,
                "email": "",
                "phone": phone_no,
                "store": store_ref.current.value,
                "address": address_ref.current.value,
                "password": passcode_1.current.value + passcode_2.current.value + passcode_3.current.value + passcode_4.current.value,
            };
            const url = `${BASE_URL}/Retailers/${user.id}`;
            axios
                .put(url, retailer_data)
                .then((result) => {
                    toast.success("Updated Successfully");
                    setloader(false);
                })
                .catch((error) => {
                    toast.error("Error Occured. Please try again");
                    console.log(error);
                });
            // localStorage.removeItem("user");
            // localStorage.setItem("user", JSON.stringify(retailer_data));
            Cookies.remove("user");
            Cookies.set("user", JSON.stringify(retailer_data));
            dispatch({
                type: action_type.SET_USER,
                user: retailer_data,
                //if you look at the console.log(response) you will see that providerData is an array
            });
            
        }
    };

    const Clear = () => {
        name_ref.current.value = "";
        phone_ref.current.value = "";
        passcode_1.current.value = "";
        passcode_2.current.value = "";
        passcode_3.current.value = "";
        passcode_4.current.value = "";
        store_ref.current.value = "";
        address_ref.current.value = "";
    };



    return (
        <Fragment className="overflow-hidden">
            <HeaderRetailer hidden={true} store={Store}></HeaderRetailer>
            <ToastContainer></ToastContainer>
            <Row style={{ marginLeft: "2.5%" }}>
                <Col md={7} xs={7}>
                    <div className="breadcrum flex gap-5 mt-4">
                        <h3 className="text-2xl font-bold text-black mt-1 Poppins">Profile Details</h3>
                        {loader && <Spinner></Spinner>}
                    </div>
                    <Form>
                        <Form.Group className="w-275 md:w-375 mt-1 Poppins text-sm">
                            <div className="flex gap-10 md:gap-32">
                                <Form.Label className=" ml-3 text-slate-500">Full Name</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.name}</Form.Label>
                            </div>
                            <Form.Control
                                style={{ border: "1px solid #333333" }}
                                className=" mb-2 text-slate-500" ref={name_ref} onChange={changehandler}>
                            </Form.Control>

                            <div className="flex gap-20 md:gap-32">
                                <Form.Label className="ml-3 text-slate-500">Phone</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.phone}</Form.Label>
                            </div>
                            <Form.Control disabled onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={phone_ref}></Form.Control>


                            <div className="flex gap-4">
                                <Form.Label className="ml-3 text-slate-500">Set Pin Code</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.password}</Form.Label>
                            </div>
                            <Row style={{ width: "50%" }} className="passcode mb-2">
                                <div className="flex  gap-3">
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" onChange={changehandler} minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333", fontWeight: "900", fontSize: "25px" }} className="text-center" ref={passcode_1}></Form.Control>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input2" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "900", fontSize: "25px" }} className="text-center" ref={passcode_2}></Form.Control>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input3" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "900", fontSize: "25px" }} className="text-center" ref={passcode_3} ></Form.Control>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input4" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "900", fontSize: "25px" }} className="text-center" ref={passcode_4}></Form.Control>
                                    </Col>
                                </div>
                            </Row>
                            <div className="flex gap-10 md:gap-32">
                                <Form.Label className="ml-3 text-slate-500">Busniess Name</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.store}</Form.Label>
                            </div>
                            <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={store_ref}></Form.Control>

                            <div className="flex gap-10 md:gap-32">
                                <Form.Label className="ml-3 text-slate-500">Address</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.address}</Form.Label>
                            </div>
                            <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={address_ref}></Form.Control>
                            <Button variant="success" disabled={update_disable} onClick={edituser} className="submit-button mt-2">Update</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            {IsSeller && <AlertSignedInAs show={IsSeller}></AlertSignedInAs>}
        </Fragment>
    );
}
export default RetailerProfile;
