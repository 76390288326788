import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateValue } from "../../store/stateprovider";
import "../Style/header.css";
import { useEffect, useState } from "react";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { action_type } from "../../store/reducer";
import logo from "../../images/bookmyorder Icon White-01.svg";

function Header() {
  // const name = localStorage.getItem("user");  
  const[{user}, dispatch] = useStateValue();
  const name = user;

  const navigate = useNavigate();
  const path =  window.location.pathname;
  const [IsRetailer, setIsRetailer] = useState(false);

  const [ActiveHomeLink, setActiveHomeLink] = useState(false);
  const [ActiveOrderLink, setActiveOrderLink] = useState(false);
  const [ActiveRegLink, setActiveRegLink] = useState(false);
  const [ActiveProfileLink, setActiveProfileLink] = useState(false)
  const [ActiveSelectProducts, setActiveSelectProducts] = useState(false);
  
  useEffect(()=>{
    if (path.includes("/orders")) {
      setActiveOrderLink(true);
    }
    else if (path.includes("/profile")) {
      setActiveProfileLink(true);
    }
    else if (path === `/Registration/:id`) {
      setActiveRegLink(true);
    }
    else if (path.includes('/WholesellerHome')) {
      setActiveHomeLink(true);
    }
    else if (path.includes('/SelectProducts')) {
      setActiveSelectProducts(true);
    }

  },[])

  useEffect(()=>{
    if(name && name.type === "retailer"){
      setIsRetailer(true);
    }
    if (!name) {
      if(path.includes("/ForgotPin") || path.includes("/Registration/:id") || path.includes("/Registration2/:id") || path.includes("/RegistrationOTPVerification")){
        console.log(null);
      }
      else{
        navigate("/");
      }
    }
  },[])

  

  

  const logout = () => {
    // localStorage.removeItem('user');
    Cookies.remove("user");
    dispatch({
      type: action_type.SET_USER,
      user: "",
      //if you look at the console.log(response) you will see that providerData is an array
    });
    navigate("/");
    
    // window.location.reload();
  }
  
  return (
    <header>
      <Navbar expand="lg" className="Poppins z-50">
        <Container className="container">
        
        {/* <Navbar.Brand className=" text-white absolute top-2 lg:top-5 left-[5%] md:left-[3%] font-semibold">{user ? user.busniess_name : "BookMyOrder"}</Navbar.Brand> */}
        <Navbar.Brand className=" text-white absolute top-2 mb-4 lg:top-5 left-[5%] md:left-[3%] font-semibold flex gap-2"><img src={logo} className="w-[10%] md:mt-[-1%]"></img><span>BookMyOrder</span></Navbar.Brand>
          <Navbar.Toggle className="absolute top-2 right-3 z-50" style={{ marginLeft: "82%" }} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="ml-[20%]">
            <Nav className="gap-14 navbar">
            {/* {name ? (<Nav.Link onClick={() => navigate(`/SelectProducts`)} className={ActiveSelectProducts ? `link-active` : `link`}>
                Add Products
              </Nav.Link>) : (
                <Nav.Link disabled hidden onClick={() => navigate(`/SelectProducts`)} className={ActiveSelectProducts ? `link-active` : `link`}>
                Add Products
              </Nav.Link>
              )} */}

              {name ? (<Nav.Link onClick={() => navigate(`/WholesellerHome`)} className={ActiveHomeLink ? `link-active` : `link`}>
                Products
              </Nav.Link>) : (
                <Nav.Link disabled hidden onClick={() => navigate(`/WholesellerHome`)} className={ActiveHomeLink ? `link-active` : `link`}>
                Products
              </Nav.Link>
              )}

              {name ? (<Nav.Link onClick={() => navigate(`/orders`)} className={ActiveOrderLink ? `link-active` : `link`}>
                Orders
              </Nav.Link>) : (
                <Nav.Link disabled hidden onClick={() => navigate(`/orders`)} className={ActiveOrderLink ? `link-active` : `link`}>
                Orders
              </Nav.Link>
              )}
              {name ? (<Nav.Link className={ActiveProfileLink ? `link-active` : `link`} onClick={()=>navigate(`/profile`)}>
                Profile
              </Nav.Link>) : (
                <Nav.Link onClick={()=>navigate("/Registration/:id")} className={ActiveRegLink ? `link-active` : `link`}>
                  Register
                </Nav.Link>
              )}
              <Navbar.Text>
                {name ? (
                  <button onClick={logout} className="logout-button">Logout</button>
                ) : (
                  <button onClick={() => navigate('/')} className="login-button">Login</button>
                )}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
export default Header;
