import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useStateValue } from "../../store/stateprovider";
import { useNavigate, useParams } from "react-router-dom";
import { action_type } from "../../store/reducer";
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    InstapaperIcon,
    LinkedinIcon,
    MailruIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,

} from "react-share";
import { MdContentCopy } from "react-icons/md";
import BASE_URL from '../Config';

function ShareModal(props) {
    return (
        <div
            className="modal show"
            style={{ display: "block", position: "initial" }}
        >
            <Modal show={props.show} onHide={props.handleClose}>
                <Container style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
                    <Modal.Header>
                        <h1 className="text-2xl">Share Store Link</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control value={props.link} className="mb-3"></Form.Control>
                        <div className="flex gap-2 justify-center">
                            <MdContentCopy className="w-7 h-7 mt-1 cursor-pointer"></MdContentCopy>
                            <EmailShareButton>
                            <EmailIcon round size={32}></EmailIcon>
                            </EmailShareButton>
                            <WhatsappShareButton>
                                <WhatsappIcon round size={32}></WhatsappIcon>
                            </WhatsappShareButton>
                            <FacebookShareButton>
                                <FacebookIcon round size={32}></FacebookIcon>
                            </FacebookShareButton>
                            <LinkedinShareButton>
                            <LinkedinIcon round size={32}></LinkedinIcon>
                            </LinkedinShareButton>
                           <TwitterShareButton>
                           <TwitterIcon round size={32}></TwitterIcon>
                            </TwitterShareButton> 
                            
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Container>
            </Modal>
        </div>
    );
}
export default ShareModal;
