import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import "../Style/otpVerification.css"
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';
import Cookies from "js-cookie";

function RetailerForgotPin() {
  const digit_1 = useRef("");
  const digit_2 = useRef("");
  const digit_3 = useRef("");
  const digit_4 = useRef("");

  // const phone = localStorage.getItem('phone');
  const phone = Cookies.get('phone');

  const { url } = useParams();
  const navigate = useNavigate();
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [loader, setloader] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    } //We need to return it as a dependency
  }, [])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      verifyOTP();
    }
  }

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else {
            inputs[index + 1].focus();
          }
        }
        if (input.value.length !== input.maxLength) {
          inputs[index - 1].focus();

        }
      })
    })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CloseHandler = () => {
    setcartmobile(false);
  }

  const setMessage = () => {
    setcartmobile(true);
  }


  const verifyOTP = () => {
    setloader(true);
    const typed_otp = digit_1.current.value + digit_2.current.value + digit_3.current.value + digit_4.current.value;
    console.log(typed_otp);
    const sent_otp = localStorage.getItem("OTP");
    if (typed_otp === sent_otp) {
      toast.success("OTP Verified")
      navigate(`/RetailerSetNewPin/${url}`)
    }
    if (typed_otp !== sent_otp) {
      toast.error("OTP does'nt Match, Try again");
    }
    setloader(false);
  }





  return (
    <div style={{ maxHeight: "100vh" }} className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{ marginLeft: "2.5%" }}>
        <Col md={9} xs={7}>
          {/* <div className="breadcrum flex mt-3">
            <h3 className="text-2xl font-bold text-black">Enter the OTP</h3>
          </div>
          <div>
            <p className="text-sm font-medium flex gap-1">Sent to - <p className="text-sm font-medium Poppins text-emerald-600">{phone}</p></p>
          </div>
          <Form>
            <Row style={{ width: "70%" }} className="passcode mt-3">
                <div className="flex  gap-3 mb-4">
                 <Col xs={4} sm={4} md={1}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333" }} ref={digit_1} className="text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={4} sm={4} md={1}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333" }} ref={digit_2} className="text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={4}  sm={4} md={1}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333" }} ref={digit_3} className="text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={4}  sm={4} md={1}> 
                <Form.Control type="password" minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333" }} ref={digit_4} className="text-center Poppins" ></Form.Control>
                </Col>
                </div>
            </Row>
            <Button onClick={verifyOTP} className="submit-button w-[60%] sm:w-[40%] md:w-[32%]">Submit</Button>
          </Form> */}
          <div className="mt-4 mb-4 flex gap-4">
            <h3 className="text-2xl font-bold">Forgot Pin</h3>
          </div>
          <div className="text-sm w-[80%] text-justify">
            Kindly direct your inquiries via email to <span className="font-medium text-blue-500">bookmyorder@gmail.com</span>. Our team will promptly respond to your message at the earliest convenience. Thank you.
          </div>
        </Col>
        {showCart && <Col md={3} xs={5}>
          <Cart
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
        {cartmobile && <CartMobile
          CloseHandler={CloseHandler}
        >
        </CartMobile>}
      </Col>
    </div>
  );
}
export default RetailerForgotPin;
