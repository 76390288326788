import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import { action_type } from "../../store/reducer";
import { useStateValue } from "../../store/stateprovider";
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import CartSummary from "./CartSummary";


function RetailerLogin() {
    const [{ user }, dispatch] = useStateValue();
    const phone_ref = useRef("");

    const passcode_1 = useRef("");
    const passcode_2 = useRef("");
    const passcode_3 = useRef("");
    const passcode_4 = useRef("");

    const [loader, setloader] = useState(false);
    const [user_data, setData] = useState([]);
    const navigate = useNavigate();
    const { url, id } = useParams();
    const [errors, seterrors] = useState(true);
    const [cartmobile, setcartmobile] = useState(false);
    const [showCart, setShowCart] = useState(true);
    const [showCartSummary, setCartSummary] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        } //We need to return it as a dependency
    }, [])

    const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
            sign_in(event);
        }
    }

    useEffect(() => {
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input, index) => {
            input.addEventListener('input', () => {
                if (input.value.length === input.maxLength) {
                    if (index === inputs.length - 1) {
                        input.blur();
                    }
                    else {
                        inputs[index + 1].focus();
                    }
                }
            })
        })
    }, [])

    const CloseHandler = () => {
        setcartmobile(false);
    }

    const setMessage = () => {
        setcartmobile(true);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1000) {
                setcartmobile(false);
                setShowCart(true);
            } // Set breakpoint here
        };
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1000) {
                setShowCart(false);
            } // Set breakpoint here
        };
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const initialValues = {
        phone: "",
    };

    const changehandler = (e) => {
        const { name, value } = e.target;
        setformValues({ ...formValues, [name]: value });
    };

    const validate = () => {
        const errors = {};
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phone_regex =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

        if (!phone_ref.current.value) {
            errors.phone = "Enter Your Phone No";
        } else if (!phone_regex.test(phone_ref.current.value)) {
            errors.phone = "Enter a Valid phone no";
        }
        if (!passcode_1.current.value || !passcode_2.current.value || !passcode_3.current.value || !passcode_4.current.value) {
            errors.password = "Passcode is Required";
        }

        return errors;
    };

    const [formValues, setformValues] = useState(initialValues);
    const [formError, setFormErrors] = useState({});



    useEffect(() => {
        const errors_count = Object.keys(formError).length;
        if (errors_count === 0) {
            seterrors(false);
        }
        if (errors_count > 0) {
            seterrors(true);
        }
    }, [formError])

    const sign_in = async (event) => {
        event.preventDefault();
        setloader(true);
        setFormErrors(validate(formValues));
        const errors_count = Object.keys(formError).length;
        const response_retailer = await fetch(`${BASE_URL}/Retailers/${phone_no}/${password}/retailer`);
        const password = passcode_1.current.value + passcode_2.current.value + passcode_3.current.value + passcode_4.current.value;
        let phone_no = phone_ref.current.value;
        if (phone_no.charAt(0) === "+92") {
            phone_no = "0" + phone_no.slice(1);
        }
        if (!errors && errors_count === 0 && response_retailer.status === 200) {
            const Data = await response_retailer.json();
            Cookies.set("user", JSON.stringify({
                id: Data.id,
                email: Data.email,
                phone: Data.phone,
                fullName: Data.fullName,
                store: Data.store,
                address: Data.address,
                type: "retailer"
            }), { expires: 2 });
            dispatch({
                type: action_type.SET_USER,
                user: Data,
                //if you look at the console.log(response) you will see that providerData is an array
            });
            setCartSummary(true);
        }
        if (response_retailer.status !== 200) {
            toast.error("PIN does'nt Match, Try again");
        }
        setloader(false);
    }


    return (
        <div style={{ maxHeight: "100vh" }} className="overflow-hidden">
            <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
            <Row style={{ marginLeft: "2.45%" }}>
                <Col md={9}>
                    <div className="flex mt-3">
                        <div className="mt-2">
                            <h3 className="text-2xl font-bold">Login</h3>
                            <Form.Text muted className="text-xs">
                                Enter your credentials
                            </Form.Text>
                        </div>
                    </div>
                    <Form>
                        <Form.Group style={{ width: "36%" }} className="mt-4 mb-4">

                            <Form.Label className="ml-3 text-slate-500 text-sm">Phone</Form.Label>
                            <Form.Control
                                type="number"
                                ref={phone_ref}
                                style={{ border: "1px solid #333333" }}
                                className="Poppins"
                                onChange={changehandler}
                            ></Form.Control>
                            <Form.Text muted>
                                <p className="text-xs text-red-500 absolute">
                                    {formError.phone}
                                </p>
                            </Form.Text>

                            <Form.Label className="ml-3 text-slate-500 mt-4 text-sm">Pin Code</Form.Label>
                            <Row style={{ width: "50%" }} className="passcode mb-2">
                                <div className="flex  gap-3">
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input1" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800" }} className="text-center" ref={passcode_1}></Form.Control>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input2" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800" }} className="text-center" ref={passcode_2}></Form.Control>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input3" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800" }} className="text-center" ref={passcode_3} ></Form.Control>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <Form.Control type="password" minLength={1} maxLength={1} id="input4" onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800" }} className="text-center" ref={passcode_4}></Form.Control>
                                    </Col>
                                </div>
                            </Row>
                            <Form.Text muted>
                                <p className="text-xs text-red-500 absolute">
                                    {formError.password}
                                </p>
                            </Form.Text>
                        </Form.Group>
                        <div className="flex gap-3">
                            {loader ? <Button
                                className="submit-button w-[60%] sm:w-[40%] md:w-[32%]"
                            >
                                <Spinner size="sm"></Spinner>
                            </Button> :
                                <Button
                                    onClick={sign_in}
                                    className="submit-button w-[60%] sm:w-[40%] md:w-[32%]"
                                >
                                    Login
                                </Button>
                            }
                        </div>
                    </Form>
                </Col>
                {showCart && <Col md={3}>
                    <Cart
                    ></Cart>
                </Col>}
            </Row>
            <Col md={5} xs={0} sm={0}>
                {cartmobile && <CartMobile
                    CloseHandler={CloseHandler}
                >
                </CartMobile>}
            </Col>
            {showCartSummary && <CartSummary show={showCartSummary} handleClose={CloseHandler}></CartSummary>}
        </div>

    );
}
export default RetailerLogin;
