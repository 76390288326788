import { fetchCart, fetchData } from "../utils/fetchlocalStorage"

const user_info = fetchData()
const cart_info = fetchCart()


export const initialState = {
    user: user_info,
    cartItems: cart_info,
    user_type: null,
    otp: null,
}