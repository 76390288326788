import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';


function CheckoutForm() {
  const { url } = useParams();
  const [{otp}, dispatch] = useStateValue();
  const [IsSignup, setisSignUp] = useState(false);
  const navigate = useNavigate();

  const name_ref = useRef("");
  const phone_ref = useRef("");
  const address_ref = useRef("");
  const url_ref = useRef("");
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  

  const CloseHandler = () => {
    setcartmobile(false);
  }

  const setMessage = () =>{
    setcartmobile(true);
  }

  

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1000){
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < 1000){
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const initialValues = {
    name: "",
    phone: "",
    store: "",
    address: "",
  };

  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});


  const addretailer = async (event) => {
    event.preventDefault();
    // const retailer_data = {
    //   email: email_ref.current.value,
    //   phone: phone_ref.current.value,
    //   password: password_ref.current.value,
    //   confirmPassword: confirmPassword_ref.current.value,
    // };
    //     const response = await fetch("${BASE_URL}/Retailers", {
    //       method: "POST",
    //       body: JSON.stringify(retailer_data),
    //       headers: { "Content-Type": "application/json" },
    //     });
    //     const data = await response.json();
    //     console.log(data);
    //     Clear();
    //     toast.success("Updated Succesfully");
    //     navigate("/");
    //   };
    //   const Clear = () => {
    //     email_ref.current.value = "";
    //     phone_ref.current.value = "";
    //     password_ref.current.value = "";
    //     confirmPassword_ref.current.value = "";
    //   };
    
    navigate(`/ordersRetailer/${url}`);
  };

  const SetOTPInLocalStorage = (OTP) =>{
    const otp = localStorage.getItem("OTP");
    if(otp){
      localStorage.removeItem("OTP");
    }
    localStorage.setItem("OTP",OTP);
  }

  const send_code = async() =>{
    const phone = '+' + phone_ref.current.value;
    var digits = '0123456789';
    var OTP = '';
    for (var i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    
    const response = await fetch(`${BASE_URL}/OTP/${phone}/${OTP}`,{
      method: 'POST',
    });
    const Data = await response.text();
    console.log(Data);
    if(response.status === 200){
      SetOTPInLocalStorage(OTP);
      navigate(`/otpVerification/${url}`);
    }
    else{
      toast.error("Try Again");
    }
}

  return (
    <Fragment className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{marginLeft:"2.5%"}}>
        <Col md={9}>
          {/* <div className="breadcrum flex gap-3">
            <p>Products</p>
            <div className="dot"></div>
            <p className="active">Checkout/Register</p>
            <div className="dot"></div>
            <p>OTP</p>
            <div className="dot"></div>
            <p>Set Passcode</p>
            <div style={{marginLeft:"20%"}} className="flex gap-2">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div>
          </div> */}

          <div className="breadcrum mt-2 flex">
            <h3 className="text-2xl font-bold text-black">Checkout / Register</h3>
            {/* <div style={{marginLeft:"20%"}} className="flex gap-2 mt-1">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
          </div>

          <Form>
            <Form.Group style={{width:"50%"}} className="mt-2">
              <Form.Label className="ml-4">Full Name</Form.Label>
              <Form.Control type="text" className="mb-3"></Form.Control>

              <Form.Label className="ml-4">Phone</Form.Label>
              <Form.Control type="text" ref={phone_ref} className="mb-3"></Form.Control>

              <Form.Label className="ml-4">Address</Form.Label>
              <Form.Control type="text" className="mb-3"></Form.Control>

              <Form.Label className="ml-4">Store Name</Form.Label>
              <Form.Control type="text" className="mb-3"></Form.Control>

              <Button onClick={send_code} className="submit-button">Send Code</Button>
            </Form.Group>
          </Form>
        </Col>
        {showCart && <Col md={3}>
          <Cart
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
      {cartmobile && <CartMobile
            CloseHandler={CloseHandler}
            >
          </CartMobile>}
      </Col>
    </Fragment>
  );
}
export default CheckoutForm;
