import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import BASE_URL from '../Config';
import { Spinner } from "react-bootstrap";



function EditModal(props) {
  const product_ref = useRef();
  const manufacturer_ref = useRef();
  const offer_ref = useRef();
  const bonus_ref = useRef();
  const total_price_ref = useRef();
  const [product_id, setproduct_id] = useState();
  const [wholeseller_id, setwholeseller_id] = useState();
  const [product_code,  set_product_code] = useState(null);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    } //We need to return it as a dependency
  }, [])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      updatehandler();
    }
  }

  useEffect(() => {
    const Edit = async () => {
      setloader(true);
      // axios.get(`${BASE_URL}/MedicalProducts/${props.id}`).then((result) => {
      //   product_ref.current.value = result.data.product_name;
      //   manufacturer_ref.current.value = result.data.manufacturer;
      //   unit_price_ref.current.value = result.data.unit_price;
      //   quantity_ref.current.value = result.data.quantity;
      //   discount_ref.current.value = result.data.discount;
      //   Exp_date_ref.current.value = result.data.expiryDate;
      // })
      // .catch((error) => {
      //   console.log(error);
      // });

      const response = await fetch(
        `${BASE_URL}/MedicalProducts/${props.id}`
      ).catch(error=>{
        console.log("abc")
      });
      const Data = await response.json();
      product_ref.current.value = Data.product_name;
      manufacturer_ref.current.value = Data.manufacturer;
      offer_ref.current.value = Data.offer;
      bonus_ref.current.value = Data.bonus;
      total_price_ref.current.value = Data.total_price;
      setproduct_id(Data.product_id);
      setwholeseller_id(Data.wholeseller_id);
      set_product_code(Data.product_code);
      setloader(false);
    };
    Edit();
  }, [props.id]);

  const initialValues = {
    product: "",
    manufacturer: "",
    offer: "",
    bonus: "",
    total_price: "",
    Exp_date: "",
  };

  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});
  const [update_disable, set_update_disable] = useState(true);

  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    set_update_disable(false);
  };

  const validate = () => {
    const errors = {};

    if (!offer_ref.current.value) {
      offer_ref.current.value = 0;
    }

    if (!bonus_ref.current.value) {
      bonus_ref.current.value = 0;
    }
    if(bonus_ref.current.value === "None"){
      bonus_ref.current.value = "";
    }

    if (!total_price_ref.current.value) {
      errors.total_price = "Total Price is Required";
    }
    return errors;
  };


  const updatehandler = (event) => {
    setloader(true);
    event.preventDefault();
    setFormErrors(validate(formValues));
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return toast.error("Please Enter all the required Fields");
    }

    const url = `${BASE_URL}/MedicalProducts/${props.id}`;
    const product_data = {
      "id": props.id,
      "product_name": product_ref.current.value,
      "product_id": product_id,
      "wholeseller_id": wholeseller_id,
      "manufacturer": manufacturer_ref.current.value,
      "offer": offer_ref.current.value,
      "bonus": bonus_ref.current.value,
      "total_price": total_price_ref.current.value,
      "product_code": product_code
    };
    axios
      .put(url, product_data)
      .then((result) => {
        toast.success("Updated Successfully");
        props.get_data();
      })
      .catch((error) => {
        toast.error("Error Occured. Please try again");
        console.log(error);
      });
    setloader(false);
    props.handleClose();
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Row style={{ display: "none" }}>
              <Col>
                <Form.Label className="text-xs text-black font-medium ml-2">Item Name</Form.Label>
                <Form.Control
                  required
                  placeholder="Product Name"
                  ref={product_ref}
                />
              </Col>
              <Col>
                <Form.Label className="text-xs text-black font-medium ml-2">Brand</Form.Label>
                <Form.Control
                  required
                  placeholder="Manufacturer"
                  ref={manufacturer_ref}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <Form.Label className="text-xs text-black font-medium ml-2">Offer(Rs/-)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Offer"
                  ref={offer_ref}
                  onChange={changehandler}
                />
              </Col>
              <Col>
                <Form.Label className="text-xs text-black font-medium ml-2">Bonus</Form.Label>
                {/* <Form.Control
                  required
                  placeholder="Bonus"
                  ref={bonus_ref}
                /> */}
                <Form.Select
                  style={{ height: "36px" }}
                  className="border-solid border border-gray-400"
                  ref={bonus_ref}
                  onChange={changehandler}
                >
                  <option>None</option>
                  <option>OLD TP</option>
                  <option>770 NET</option>
                  <option>77 NET</option>
                  <option>1600 NET</option>
                  <option>FRASH</option>
                  <option>ALPROZOLAM</option>
                  <option>OLD TPP</option>
                  <option>50 PR 7%</option>
                </Form.Select>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <Form.Label className="text-xs text-black font-medium ml-2">Total Price($)</Form.Label>
                <Form.Control
                  required
                  placeholder="Quantity"
                  ref={total_price_ref}
                  onChange={changehandler}
                />
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.total_price}</Form.Label>
              </Col>
              <Col></Col>
            </Row>
            <br></br>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          {loader ? <Button variant="success" onClick={updatehandler}><Spinner /></Button> :
          <Button disabled={update_disable} variant="success" onClick={updatehandler}>Save Changes</Button> }
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default EditModal;
