import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import "../Style/otpVerification.css"
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';
import Cookies from "js-cookie";

function OTPVerification() {
  const digit_1 = useRef("");
  const digit_2 = useRef(""); 
  const digit_3 = useRef(""); 
  const digit_4 = useRef("");

  // const phone = localStorage.getItem('phone');
  const phone = Cookies.get('phone');

  const { url} = useParams();
  const navigate = useNavigate();
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [loader,setloader] = useState(false);

  const CloseHandler = () => {
    setcartmobile(false);
  }

  const setMessage = () =>{
    setcartmobile(true);
  }

  useEffect(()=>{  
    document.addEventListener('keydown',keyDownHandler);
  
    return ()=>{
      document.removeEventListener('keydown',keyDownHandler);
    } //We need to return it as a dependency
  },[])
  
  const keyDownHandler = (event) =>{
    if(event.key === 'Enter'){
      verifyOTP();
    }
  }

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else{
            inputs[index + 1].focus();
          }
        }
        if (input.value.length !== input.maxLength) {
          inputs[index - 1].focus();

      }
      })
    })
  },[])

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1000){
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < 1000){
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const verifyOTP = () => {
    const typed_otp = digit_1.current.value + digit_2.current.value + digit_3.current.value + digit_4.current.value;
    console.log(typed_otp);
    // const sent_otp = localStorage.getItem("OTP");
    const sent_otp = Cookies.get("OTP");
    if(typed_otp === sent_otp){
      toast.success("OTP Verified")
      navigate(`/RetailerSignupCheckout/${url}`)
    }
    if(typed_otp !== sent_otp){
      return toast.error("OTP does'nt Match, Try again");
    }
  }

  const SetOTPInLocalStorage = (OTP) => {
    const otp = localStorage.getItem("OTP");
    if (otp) {
      localStorage.removeItem("OTP");
    }
    localStorage.setItem("OTP", OTP);
  }

  const SetOTPInCookies = (OTP) => {
    const otp = Cookies.get("OTP");
    if (otp) {
      Cookies.remove("OTP");
    }
    Cookies.set("OTP", OTP, {expires: 2});
  }

  const send_code = async (phone) => {
    let phone_no = phone;
      if (phone_no.charAt(0) === "+92") {
        phone_no = "0" + phone_no.slice(1);
    }
    var digits = '0123456789';
    var OTP = '';
    for (var i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    // const response = await fetch(`${BASE_URL}/OTP/${phone}/${OTP}`);
    // const Data = await response.json();
    try {
      SetOTPInCookies(OTP)
      const response = await fetch(`${BASE_URL}/OTP/${phone}/${OTP}`,{
        method: 'POST',
      }).catch(error=>{
        console.log("abc")
      });
      const Data = await response.status;
      console.log(Data);
    }
    catch (error) {
      toast.error("Please Try Again");
      console.log(error);
    }
  }

  


  return (
    <div style={{maxHeight:"100vh"}} className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{marginLeft:"2.5%"}}>
        <Col md={9}>
          <div className="breadcrum flex md:gap-3 sm:gap-9 ">
            {/* <p>Products</p>
            <div className="dot"></div>
            <p>Checkout/Register</p>
            <div className="dot"></div>
            <p className="active">OTP</p>
            <div className="dot"></div>
            <p>Set Passcode</p> */}
          </div>

          <div className="breadcrum flex mt-3">
            <h3 className="text-2xl font-bold text-black">Enter the OTP</h3>
            {/* <div style={{marginLeft:"20%"}} className="flex gap-2 mt-1">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
          </div>
          <div>
            <p className="text-sm font-medium flex gap-1">Sent to - <p className="text-sm font-medium Poppins text-emerald-600">{phone}</p></p>
          </div>
          <Form>
            <Row style={{ width: "70%" }} className="passcode mt-4">
                <div className="flex  gap-3 mb-4">
                 <Col xs={2} sm={2} md={1} xl={1}> 
                <Form.Control minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333" }} ref={digit_1} className="text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={2} sm={2} md={1} xl={1}> 
                <Form.Control minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333" }} ref={digit_2} className="text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={2}  sm={2} md={1} xl={1}> 
                <Form.Control minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333" }} ref={digit_3} className="text-center Poppins" ></Form.Control>
                </Col>
                <Col xs={2}  sm={2} md={1} xl={1}> 
                <Form.Control minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333" }} ref={digit_4} className="text-center Poppins" ></Form.Control>
                </Col>
                </div>
                <p onClick={()=>navigate(`/phoneVerification/${url}`)} className="text-xs font-medium hover:underline cursor-pointer">Did'nt Recieve? Resend OTP</p>
            </Row>
            
            {loader ? <button className="submit-button w-[60%] sm:w-[40%] md:w-[32%]"><Spinner></Spinner></button>:
            <button onClick={verifyOTP} className="submit-button w-[60%] sm:w-[40%] md:w-[32%]">Submit</button>
            }
          </Form>
        </Col>
        {showCart && <Col md={3} xs={5}>
          <Cart
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
      {cartmobile && <CartMobile
            CloseHandler={CloseHandler}
            >
          </CartMobile>}
      </Col>
    </div>
  );
}
export default OTPVerification;
