import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RefundedIcon from "../../images/refunded.svg";
import CancelIcon from "../../images/cancel.svg";
import DoneIcon from "../../images/done.svg";
import "../Style/CRUD.css";
import view_icon from "../../images/view-icon.svg"
import edit_icon from "../../images/edit-icon.svg";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import AdminHeader from "./Admin-header";
import disable_icon from "../../images/disabled.svg"
import InfoModal from "./InfoModal";

function ViewWholesellers() {
  const [wholesellers, setwholesellers] = useState([]);
  const [id, setid] = useState(0);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [{ user }] = useStateValue();

  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(Cookies.get("user"));

  const [pagination, setPagination] = useState([]);
  const [loader, setloader] = useState(false);
  const [showCartSummary, setCartSummary] = useState(false);
  const [order_Data, setOrder_Data] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  const [IsRetailer, setIsRetailer] = useState(false);
  const [error_msg, set_error_msg] = useState("");
  const [view_modal, set_view_modal] = useState(false);
  const [seller_data, set_data] = useState([]);

  useEffect(() => {
    if (user && user.type === "retailer") {
      setIsRetailer(true);
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setMobileView(true);
      } // Set breakpoint here
      if (window.innerWidth > 500) {
        setMobileView(false);
      }
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const get_wholesellers = async () => {
    setloader(true);
    const response = await fetch(
      `${BASE_URL}/WholesellerControllerSignUp`
    ).catch(error=>{
      console.log("abc")
    });
    const Data = await response.json();
    const loaded_data = [];

    for (const key in Data) {
      loaded_data.push({
        id: Data[key].id,
        full_name: Data[key].full_name,
        phone: Data[key].phone,
        busniess_name: Data[key].busniess_name,
        status: Data[key].status,
        address: Data[key].address,
        is_disabled: Data[key].is_disabled
      });
    }
    setwholesellers(loaded_data);
    if (wholesellers.length === 0 || loaded_data.length === 0) {
      set_error_msg("No wholesellers found");
    }

    setloader(false);
  };

  useEffect(() => {
    get_wholesellers();
  }, []);

  const EditHandler = (id) => {
    setEdit(true);
    setid(id);
  };

  const Modalhandler = (id, full_name, phone, busniess_name, address, is_disabled) =>{
    set_view_modal(true)
    const seller_data = {
      id:id,
      full_name:full_name,
      phone:phone,
      busniess_name:busniess_name,
      address:address,
      is_disabled:is_disabled
    }
    set_data(seller_data);
  }

  const UpdateHandler = (id) => {
    if (window.confirm("Are You Sure") === true) {
      setloader(true);
      axios
        .put(`${BASE_URL}/WholesellerControllerSignUp/UpdateStatus/${id}`)
        .then((result) => {
          if (result.status === 200) {
            get_wholesellers();
            toast.success("Status Updated Successfully");
            CloseHandler();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setloader(false);
  };


  const CloseHandler = () => {
    setEdit(false);
    setCartSummary(false);
    set_view_modal(false)
  };


  return (
    // <table className="order-table" striped>
    //         <thead style={{backgroundColor:"#CCEAFF"}}>
    //           <tr>
    //             <th>No</th>
    //             <th>Store</th>
    //             <th>Username</th>
    //             <th>Phone</th>
    //             <th>T.P</th>
    //             <th>Status</th>
    //             <th>Action</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //             {wholesellers && wholesellers.map((item, index) => (
    //                 <tr key={index}>
    //                   <td data-label="No">{item.id}</td>
    //                   <td data-label="Store">{item.store}</td>
    //                   <td data-label="Username">{item.retailer_name}</td>
    //                   <td data-label="Phone">{item.phone_no}</td>
    //                   <td data-label="T.P">${item.Total}</td>
    //                   <td data-label="Status">{item.status}</td>
    //                   <td data-label="Action">
    //                     <div className="flex gap-2  action-buttons">
    //                       <img
    //                       onClick={()=>CartSummaryHandler(item.id,item.retailer_name, item.phone_no, item.store, item.address, item.Total)}
    //                         className=" mt-3 cursor-pointer"
    //                         src={view_icon}
    //                       ></img>
    //                       <img
    //                       onClick={()=>(EditHandler(item.id))}
    //                         className=" mt-3 cursor-pointer"
    //                         src={edit_icon}
    //                       ></img>
    //                     </div>
    //                   </td>
    //                 </tr>
    //               ))}
    //           </tbody>
    //       </table>
    <Fragment>
      <AdminHeader></AdminHeader>
      <ToastContainer></ToastContainer>
      <Row style={{ marginLeft: "2.5%", marginRight: "1%", }}>
        <Col md={9}>
          <div className="mt-4 mb-4">
            <h3 className="text-2xl font-bold">Wholesellers</h3>
          </div>
          <div className="text-xs md:text-sm " style={{ fontFamily: "Poppins" }}>
            {!mobileView ? (<Table hover className="text-center" >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>FullName</th>
                  <th>Phone</th>
                  <th>Store</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                {wholesellers && wholesellers.map((item, index) => (
                  <tr key={index} onClick={() => Modalhandler(item.id, item.full_name, item.phone, item.busniess_name, item.address, item.is_disabled)} className={`cursor-pointer ${item.is_disabled ? `text-center opacity-50`: `text-center`}`}>
                    <td data-label="No">{item.id}</td>
                    <td data-label="Store">{item.full_name}</td>
                    <td data-label="Phone">{item.phone}</td>
                    <td data-label="T.P">{item.busniess_name}</td>
                    <td data-label="Status">{item.address}</td>
                    {/* <td>
                      <div className="flex gap-2 mt-[0.75rem] justify-center">
                        <img
                          onClick={() => Modalhandler(item.id, item.full_name, item.phone, item.busniess_name, item.address, item.is_disabled)}
                          className=" cursor-pointer w-5 h-5"
                          src={item.is_disabled ? disable_icon : view_icon}
                        ></img>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>) : (
              <div className=" overflow-x-hidden">
                {wholesellers && wholesellers.map((item, index) => (
                  <Table key={index} onClick={() => Modalhandler(item.id, item.full_name, item.phone, item.busniess_name, item.address, item.is_disabled)} className="mb-4">
                    <div className=" border">
                      <div className="flex gap-[40%] ml-1 mr-2 mt-3">
                        <div className="flex gap-2 border-0">
                          <b>Full Name: </b>
                          <p className=" text-sky-700 font-medium">{item.full_name}</p>
                        </div>
                        {/* <img
                          className=" cursor-pointer"
                          src={item.is_disabled ? disable_icon : view_icon}
                          onClick={() => Modalhandler(item.id, item.full_name, item.phone, item.busniess_name, item.address, item.is_disabled)}
                        ></img> */}
                      </div>
                    </div>
                    <div className="border">
                      <Row >
                        <Col xs={6} style={{ borderRight: "2px solid #dee2e6", marginLeft: "6px", paddingTop: "20px" }}>
                          <div className="flex m-2 gap-2 ">
                            <b>Phone: </b>
                            <p>{item.phone}</p>
                          </div>
                          <div className="flex m-2 gap-2">
                            <b>Store: </b>
                            <p className=" text-yellow-400">{item.busniess_name}</p>
                          </div>
                        </Col>
                        <Col style={{ paddingTop: "20px" }}>
                          <div className="flex mt-2 ml-1 gap-2">
                            <b>Address: </b>
                            <p>{item.address}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Table>
                ))}
              </div>
            )}
            {wholesellers.length === 0 && <p>{error_msg}</p>}
            {loader && <Spinner></Spinner>}
          </div>
        </Col>
      </Row>
      {view_modal && <InfoModal header={"Wholeseller Info"} show={view_modal} UpdateHandler={UpdateHandler} data={seller_data} handleClose={CloseHandler}></InfoModal>}
    </Fragment>
  );
}
export default ViewWholesellers;
