import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Pagination, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import StatusUpdateModal from "./StatusUpdateModal";
import RefundedIcon from "../../images/refunded.svg";
import CancelIcon from "../../images/cancel.svg";
import DoneIcon from "../../images/done.svg";
import "../Style/CRUD.css";
import view_icon from "../../images/view-icon.svg"
import edit_icon from "../../images/edit-icon.svg";
import print_icon from "../../images/mingcute_print-line.svg";
import Header from "./Header";
import OrderSummary from "./OrderSummary";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Navigate, useNavigate } from "react-router-dom";


function Orders(props) {
  const [orders, setorders] = useState([]);
  const [id, setid] = useState(0);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [{ user }] = useStateValue();

  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(Cookies.get("user"));


  const [length, setlength] = useState(0);
  const [pagination, setPagination] = useState([]);
  const [loader, setloader] = useState(false);
  const [showCartSummary, setCartSummary] = useState(false);
  const [order_Data, setOrder_Data] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  const [IsRetailer, setIsRetailer] = useState(false);
  const [error_msg, set_error_msg] = useState("");
  const [Page_records, setPage_records] = useState([]);
  const [search_data, set_search_data] = useState([]);
  const [print, setprint] = useState(false);
  const [flag, setflag] = useState(false);
  const navigate = useNavigate();
  const [sticky, setIsSticky] = useState(false);
  const [disable_print, set_disable_print] = useState(true);


  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > window.innerHeight / 6);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const GetOrderLength = async () => {
      const response = await fetch(`${BASE_URL}/OrdersCategory/seller/length/${user.id}`);
      const length = await response.text();

      setlength(length);
    }
    GetOrderLength();
  }, [])

  const [search, setsearch] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const recordsPerPage = 50;
  const npage = Math.ceil(length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  let lastIndex = currentPage * recordsPerPage;
  let firstIndex = lastIndex - recordsPerPage;
  let records = orders;


  useEffect(() => {
    if (user && user.type === "retailer") {
      setIsRetailer(true);
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setMobileView(true);
      } // Set breakpoint here
      if (window.innerWidth > 500) {
        setMobileView(false);
      }
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const get_orders = async () => {
    setloader(true);
    lastIndex = currentPage * recordsPerPage;
    firstIndex = lastIndex - recordsPerPage;
    const response = await fetch(
      `${BASE_URL}/OrdersCategory/seller/${user.id}/${firstIndex}/${lastIndex}`
    ).catch(error => {
      console.log("abc")
    });
    if (!response) {
      toast.error("Error Occured. Please try again");
      setloader(false);
    }
    const Data = await response.json();
    const loaded_data = [];


    for (const key in Data) {
      loaded_data.push({
        id: Data[key].id,
        retailer_name: Data[key].retailer_name,
        Wholeseller_id: Data[key].wholeseller_id,
        Total: Data[key].total,
        totalItems: Data[key].totalItems,
        status: Data[key].status,
        Order_Date: Data[key].order_Date,
        store: Data[key].store,
        products: Data[key].products,
        quantity: Data[key].quantity,
        phone_no: Data[key].phone_no,
        address: Data[key].address,
        checked: false,
      });
    }
    // loaded_data.sort((a, b)=>b.id - a.id);
    setorders(loaded_data);

    records = orders
    // setPage_records(loaded_data.slice(firstIndex, lastIndex));
    // setPage_records(loaded_data);

    if (orders.length === 0 || loaded_data.length === 0) {
      set_error_msg("No Orders have been placed");
    }

    setloader(false);
  };


  useEffect(() => {
    get_orders();
  }, [currentPage]);


  //Search 
  const [searchList, set_SearchList] = useState([]);
  const search_ref = useRef();


  const setSearchList = async (character) => {
    setloader(true)
    const response = await fetch(`${BASE_URL}/OrdersCategory/seller/search/${user.id}/${character}`);
    const Data = await response.json();
    const loaded_data = [];

    for (const key in Data) {
      loaded_data.push({
        id: Data[key].id,
        retailer_name: Data[key].retailer_name,
        Wholeseller_id: Data[key].wholeseller_id,
        Total: Data[key].total,
        totalItems: Data[key].totalItems,
        status: Data[key].status,
        Order_Date: Data[key].order_Date,
        store: Data[key].store,
        products: Data[key].products,
        quantity: Data[key].quantity,
        phone_no: Data[key].phone_no,
        address: Data[key].address,
        checked: false
      });
    }
    // loaded_data.sort((a, b)=>b.id - a.id);
    set_search_data(loaded_data);
    setloader(false);
  }


  const searchHandler = async () => {
    setloader(true);
    if (search_ref.current.value !== "" && search_ref.current.value.length >= 3) {
      setSearchList(search_ref.current.value);
      // const newList = search_data.filter((d) => {
      //     return Object.values(d)
      //       .join(" ")
      //       .toLowerCase()
      //       .includes(search_ref.current.value.toLowerCase());
      //   });
      const newList = search_data;
      set_SearchList(newList);
      setsearch(true);
    } else {
      get_orders();
      setsearch(false);
    }
    if (search_ref.current.value === "" || search_ref.current.value.length <= 3) {
      get_orders();
      setsearch(false);
    }
    setloader(false);
  };


  //Pagination
  const ChangeNpage = (id) => {
    setcurrentPage(id)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const NextPage = () => {
    if (currentPage !== npage) {
      setcurrentPage(currentPage + 1);
    }
  }

  const PrevPage = () => {
    if (currentPage !== 1) {
      setcurrentPage(currentPage - 1);
    }
  }


  const EditHandler = (id) => {
    setEdit(true);
    setid(id);
  };

  const CloseHandler = () => {
    setEdit(false);
    setCartSummary(false);
  };



  const CartSummaryHandler = (id, retailer_name, phone_no, store, address, total, order_date) => {
    setCartSummary(true)
    const date = new Date(order_date);
    const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
    const data = {
      id: id,
      retailer_name: retailer_name,
      phone_no: phone_no,
      store: store,
      address: address,
      total: total,
      order_date: formattedDate,
    }
    setOrder_Data(data)
    setprint(false);
  }

  const printref = useRef();

  const handlePrint = (id, retailer_name, phone_no, store, address, total) => {
    const data = {
      id: id,
      retailer_name: retailer_name,
      phone_no: phone_no,
      store: store,
      address: address,
      total: total,
    }
    setOrder_Data(data)
    setCartSummary(true)
    setprint(true);
  }

  const bulk_print_handler = () => {
    localStorage.removeItem("Items");
    let Items;
    let Items_id = [];

    if (!search) {
      Items = orders.filter((e) => e.checked === true);
      Items.map((item) => {
        Items_id.push(item.id);
      })
    }
    else {
      Items = searchList.filter((e) => e.checked === true);
      Items.map((item) => {
        Items_id.push(item.id);
      })
    }
    if (Items.length === 0) {
      return toast.error("Please select Order(s)");
      setloader(false);
    }
    
    localStorage.setItem("Items", JSON.stringify(Items_id));
    navigate("/printorders");
    window.history.pushState(null, '', "/");
  }

  const CheckHandler = (id) => {
    let Items;
    orders.map((item) => {
      if (item.id === id) {
        item.checked = !item.checked;
        setflag(flag + 1);
      }
    });
    searchList.map((item) => {
      if (item.id === id) {
        item.checked = !item.checked;
        setflag(flag + 1);
      }
    });
    if (!search) {
      Items = orders.filter((e) => e.checked === true);
    }
    else {
      Items = searchList.filter((e) => e.checked === true);
    }
    set_disable_print(!Items.length > 0);
  }

  return (
    // <table className="order-table" striped>
    //         <thead style={{backgroundColor:"#CCEAFF"}}>
    //           <tr>
    //             <th>No</th>
    //             <th>Store</th>
    //             <th>Username</th>
    //             <th>Phone</th>
    //             <th>T.P</th>
    //             <th>Status</th>
    //             <th>Action</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //             {orders && orders.map((item, index) => (
    //                 <tr key={index}>
    //                   <td data-label="No">{item.id}</td>
    //                   <td data-label="Store">{item.store}</td>
    //                   <td data-label="Username">{item.retailer_name}</td>
    //                   <td data-label="Phone">{item.phone_no}</td>
    //                   <td data-label="T.P">${item.Total}</td>
    //                   <td data-label="Status">{item.status}</td>
    //                   <td data-label="Action">
    //                     <div className="flex gap-2  action-buttons">
    //                       <img
    //                       onClick={()=>CartSummaryHandler(item.id,item.retailer_name, item.phone_no, item.store, item.address, item.Total)}
    //                         className=" mt-3 cursor-pointer"
    //                         src={view_icon}
    //                       ></img>
    //                       <img
    //                       onClick={()=>(EditHandler(item.id))}
    //                         className=" mt-3 cursor-pointer"
    //                         src={edit_icon}
    //                       ></img>
    //                     </div>
    //                   </td>
    //                 </tr>
    //               ))}
    //           </tbody>
    //       </table>
    <Fragment>
      <Header></Header>
      <ToastContainer></ToastContainer>
      <Row style={{ marginLeft: "2.5%", marginRight: "1%", }}>
        <Col md={9}>
          <div className="mt-4 mb-4 flex gap-4">
            <h3 className="text-2xl font-bold">Orders</h3>

            {loader && <Spinner></Spinner>}
          </div>
          <div className={`${sticky && "sticky drop-shadow-md bg-white"}`}>
            <Form.Group>
              <div className={`flex gap-3 ${sticky && "mt-3 transition-all ease-in-out duration-500"}`}>
                <Form.Control
                  style={
                    sticky ? {
                      width: "63%", height: "50px",
                      marginBottom: "1.5%",
                      marginLeft:"3.5%",
                    } :
                      {
                        height: "50px",
                        marginBottom: "1.5%",
                      }}
                  class="search"
                  placeholder="Search"
                  onChange={searchHandler}
                  ref={search_ref}
                />
                <button onClick={bulk_print_handler} className={`h-[50px] rounded-lg bg-[#0A9A73] hover:bg-[#157347] text-white flex gap-2 ${disable_print ? "opacity-70" :"opacity-100"}`}><span className='ml-3 bi bi-printer text-white mt-[0.80rem]'></span><p className="mt-[0.80rem] mr-3">Print</p></button>
              </div>
            </Form.Group>
          </div>
          <div className="text-xs md:text-sm mb-[2%]" style={{ fontFamily: "Poppins" }}>
            {!mobileView && !search && (<Table className="text-center">
              <thead >
                <tr>
                  <th></th>
                  <th>No</th>
                  <th>Store</th>
                  <th>Username</th>
                  <th>Phone</th>
                  <th>T.P</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input onChange={() => CheckHandler(item.id)} defaultChecked={item.checked} value={item.id} type="checkbox" className="w-5 h-5 ml-2 mt-3"></input>
                    </td>
                    <td data-label="No">{item.id}</td>
                    <td data-label="Store">{item.store}</td>
                    <td data-label="Username">{item.retailer_name}</td>
                    <td data-label="Phone">{item.phone_no}</td>
                    <td data-label="T.P">Rs. {item.Total}/-</td>
                    <td data-label="Status">{item.status}</td>
                    <td data-label="Action">
                      <div className="flex gap-2 mt-3 action-buttons justify-center">
                        <img
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total, item.Order_Date)}
                          className=" cursor-pointer"
                          src={view_icon}
                        ></img>
                        <img
                          onClick={() => (EditHandler(item.id))}
                          className=" cursor-pointer"
                          src={edit_icon}
                        ></img>
                        {/* <img
                          onClick={() => handlePrint(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total)}
                          className=" cursor-pointer opacity-50"
                          src={print_icon}
                        ></img> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>)}
            {mobileView && !search && <div className=" overflow-x-hidden">
              {orders && orders.map((item, index) => (
                <Table className="mb-4">
                  <div className=" border">
                    <div className="flex  ml-1 mr-2 mt-3">
                      <div className="flex gap-2 border-0 mr-auto">

                        <input onChange={() => CheckHandler(item.id)} defaultChecked={item.checked} value={item.id} type="checkbox" className="w-4 h-4 mr-[4%]"></input>

                        <b>Store: </b>
                        <p className=" text-sky-700 font-medium w-[10rem]">{item.store}</p>
                      </div>
                      <div className="flex gap-3 mb-3 border-0" >
                        <img
                          className=" cursor-pointer"
                          src={view_icon}
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total, item.Order_Date)}
                        ></img>
                        <img
                          onClick={() => (EditHandler(item.id))}
                          className=" cursor-pointer"
                          src={edit_icon}
                        ></img>
                        {/* <img
                          onClick={() => handlePrint(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total)}
                          className=" cursor-pointer opacity-50"
                          src={print_icon}
                        ></img> */}

                      </div>
                    </div>
                  </div>
                  <div className="border">
                    <Row >
                      <Col xs={6} style={{ borderRight: "2px solid #dee2e6", marginLeft: "6px", paddingTop: "20px" }}>
                        <div className="flex m-2 gap-2 ">
                          <b>Phone: </b>
                          <p>{item.phone_no}</p>
                        </div>
                        <div className="flex m-2 gap-2">
                          <b>Status: </b>
                          {item.status === "Payment Recieved" && (<p className=" text-sky-400">{item.status}</p>)}
                          {item.status === "Pending" && (<p className=" text-yellow-400">{item.status}</p>)}
                          {item.status === "Cancel" && (<p className=" text-red-500">{item.status}</p>)}
                          {item.status === "Shipped" && (<p className=" text-orange-500">{item.status}</p>)}
                          {item.status === "Delivered" && (<p className=" text-green-500">{item.status}</p>)}
                        </div>
                      </Col>
                      <Col style={{ paddingTop: "20px" }}>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>Total Price: </b>
                          <p>Rs. {item.Total}/-</p>
                        </div>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>No. of Items: </b>
                          <p>{item.totalItems}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Table>
              ))}
            </div>
            }


            {!mobileView && search && (<Table className="text-center">
              <thead >
                <tr>
                  <th></th>
                  <th>No</th>
                  <th>Store</th>
                  <th>Username</th>
                  <th>Phone</th>
                  <th>T.P</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders && searchList.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input onChange={() => CheckHandler(item.id)} defaultChecked={item.checked} value={item.id} type="checkbox" className="w-5 h-5 ml-2 mt-3"></input>
                    </td>
                    <td data-label="No">{item.id}</td>
                    <td data-label="Store">{item.store}</td>
                    <td data-label="Username">{item.retailer_name}</td>
                    <td data-label="Phone">{item.phone_no}</td>
                    <td data-label="T.P">Rs. {item.Total}/-</td>
                    <td data-label="Status">{item.status}</td>
                    <td data-label="Action">
                      <div className="flex gap-2 mt-3 action-buttons justify-center">
                        <img
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total, item.Order_Date)}
                          className=" cursor-pointer"
                          src={view_icon}
                        ></img>
                        <img
                          onClick={() => (EditHandler(item.id))}
                          className=" cursor-pointer"
                          src={edit_icon}
                        ></img>
                        {/* <img
                          onClick={() => handlePrint(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total)}
                          className=" cursor-pointer opacity-50"
                          src={print_icon}
                        ></img> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>)}
            {mobileView && search && <div className=" overflow-x-hidden">
              {orders && searchList.map((item, index) => (
                <Table className="mb-4">
                  <div className=" border">
                    <div className="flex  ml-1 mr-2 mt-3">
                      <div className="flex gap-2 border-0 mr-auto">

                        <input onChange={() => CheckHandler(item.id)} defaultChecked={item.checked} value={item.id} type="checkbox" className="w-4 h-4 mr-[4%]"></input>

                        <b>Store: </b>
                        <p className=" text-sky-700 font-medium w-[10rem]">{item.store}</p>
                      </div>
                      <div className="flex gap-3 mb-3 border-0" >
                        <img
                          className=" cursor-pointer"
                          src={view_icon}
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total, item.Order_Date)}
                        ></img>
                        <img
                          onClick={() => (EditHandler(item.id))}
                          className=" cursor-pointer"
                          src={edit_icon}
                        ></img>
                        {/* <img
                        onClick={() => handlePrint(item.id, item.retailer_name, item.phone_no, item.store, item.address, item.Total)}
                        className=" cursor-pointer opacity-50"
                        src={print_icon}
                      ></img> */}

                      </div>
                    </div>
                  </div>
                  <div className="border">
                    <Row >
                      <Col xs={6} style={{ borderRight: "2px solid #dee2e6", marginLeft: "6px", paddingTop: "20px" }}>
                        <div className="flex m-2 gap-2 ">
                          <b>Phone: </b>
                          <p>{item.phone_no}</p>
                        </div>
                        <div className="flex m-2 gap-2">
                          <b>Status: </b>
                          {item.status === "Payment Recieved" && (<p className=" text-sky-400">{item.status}</p>)}
                          {item.status === "Pending" && (<p className=" text-yellow-400">{item.status}</p>)}
                          {item.status === "Cancel" && (<p className=" text-red-500">{item.status}</p>)}
                          {item.status === "Shipped" && (<p className=" text-orange-500">{item.status}</p>)}
                          {item.status === "Delivered" && (<p className=" text-green-500">{item.status}</p>)}
                        </div>
                      </Col>
                      <Col style={{ paddingTop: "20px" }}>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>Total Price: </b>
                          <p>Rs. {item.Total}/-</p>
                        </div>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>No. of Items: </b>
                          <p>{item.totalItems}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Table>
              ))}
            </div>
            }
          </div>
          <p>{orders.length === 0 && error_msg}</p>

          {!search &&
            <ul className="pagination justify-center ">
              <li className="page-item cursor-pointer">
                <a href="#" className="page-link" onClick={PrevPage}>Prev</a>
              </li>
              {
                numbers.map((item, index) => (
                  <li onClick={() => ChangeNpage(item)} className={`page-link cursor-pointer ${currentPage === item ? 'active' : ''}`}>
                    {item}
                  </li>
                ))
              }
              <li className="page-item cursor-pointer">
                <a href="#" className="page-link" onClick={NextPage}>Next</a>
              </li>
            </ul>
          }
        </Col>
      </Row>

      {showCartSummary && <OrderSummary print={print} data={order_Data} show={showCartSummary} handleClose={CloseHandler}></OrderSummary>}
      {edit && <StatusUpdateModal get_orders={get_orders} toast={toast} id={id} show={edit} handleClose={CloseHandler}></StatusUpdateModal>}
      {IsRetailer && (
        <AlertSignedInAs show={IsRetailer}></AlertSignedInAs>
      )}

    </Fragment>
  );
}
export default Orders;
