import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { action_type } from "../store/reducer";
import background_pic from "../images/image 31.png";
import { useStateValue } from "../store/stateprovider";
import "./Style/SignIn.css";
import Header from "./Wholeseller/Header";
import BASE_URL from './Config';
import Cookies from "js-cookie";
import { MdSignalCellular2Bar } from "react-icons/md";
import { PinInput } from "react-input-pin-code";


function SignIn() {
  const [{ user }, dispatch] = useStateValue();
  const [verified, setIsVerified] = useState(false);
  const phone_ref = useRef("");
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const width = window.innerWidth.toString();
  const height = window.innerHeight.toString();
  // const usr = JSON.parse(localStorage.getItem("user"));

  const [values, setValues] = useState(['', '', '', '']);

  useEffect(()=>{  
  document.addEventListener('keydown',keyDownHandler);

  return ()=>{
    document.removeEventListener('keydown',keyDownHandler);
  } //We need to return it as a dependency
},[])

const keyDownHandler = (event) =>{
  if(event.key === 'Enter'){
    sign_in();
  }
}



  const passcode_1 = useRef("");
  const passcode_2 = useRef("");
  const passcode_3 = useRef("");
  const passcode_4 = useRef("");


  useEffect(() => {
    
    if (user && user.type === "seller") {
      navigate("/profile");
    }
  }, [])



  useEffect(() => {
    if (user && user.type === "retailer") {
      navigate("/retailerProfile");
    }
  }, [])

  useEffect(() => {
    if (user && user.type === "admin") {
      navigate("/AdminPanel");
    }
  }, [])



  const sign_in = async (event) => {
    event.preventDefault();
    setloader(true);
    const password = values[0] + values[1] + values[2] + values[3];
    let phone_no = phone_ref.current.value;
    if (phone_no.charAt(0) === "+92") {
      phone_no = "0" + phone_no.slice(1);
    }
    if (!user) {
      const response_seller = await fetch(
        `${BASE_URL}/WholesellerControllerSignUp/${phone_no}/${password}`
      ).catch(error=>{
        console.log("abc")
      });
      const response_retailer = await fetch(
        `${BASE_URL}/Retailers/${phone_no}/${password}`
      ).catch(error=>{
        console.log("abc")
      });      
      if(phone_no === "1234" && password === "1234"){
        const admin_data = JSON.stringify({type: "admin"})
        dispatch({
          type: action_type.SET_USER,
          user: admin_data,
          //if you look at the console.log(response) you will see that providerData is an array
        });
        Cookies.set('user',admin_data,{expires:2});
        navigate("/AdminPanel");
      }
      else if(!response_seller && !response_retailer){
        toast.error("Error Occured. Please try again");
        setloader(false);
      }
      else if (response_seller.status === 200) {
        const Data = await response_seller.json();
        console.log(Data);
        if(Data.is_disabled === true){
          setloader(false);
          return toast.error("Your Profile has been disabled by Admin");
      }

        setIsVerified(true);
        toast.success("Login Successful");
        dispatch({
          type: action_type.SET_USER,
          user: Data,
          //if you look at the console.log(response) you will see that providerData is an array
        });
        Cookies.set('user', JSON.stringify({
          id: Data.id,
          full_name: Data.full_name,
          address: Data.address,
          busniess_name: Data.busniess_name,
          email: Data.email,
          phone: Data.phone,
          url: Data.url,
          type: "seller",
        }),{expires:2});

        

        // localStorage.setItem(
        //   "user",
        //   JSON.stringify({
        //     id: Data.id,
        //     full_name: Data.full_name,
        //     address: Data.address,
        //     busniess_name: Data.busniess_name,
        //     email: Data.email,
        //     phone: Data.phone,
        //     type: "seller",
        //   }));
        navigate("/WholesellerHome");
      } else if (response_retailer.status === 200) {
        const Data = await response_retailer.json();
        if(Data.is_disabled == true){
          setloader(false);
          return toast.error("Your Profile has been disabled by Admin");
      }
        setIsVerified(true);
        toast.success("Login Successful");
        dispatch({
          type: action_type.SET_USER,
          user: Data,
          //if you look at the console.log(response) you will see that providerData is an array
        });

        Cookies.set('user',JSON.stringify({
          id: Data.id,
          email: Data.email,
          phone: Data.phone,
          fullName: Data.fullName,
          store: Data.store,
          address: Data.address,
          type: "retailer",
        }),{expires:2});

        // localStorage.setItem(
        //   "user",
        //   JSON.stringify({
        //     id: Data.id,
        //     email: Data.email,
        //     phone: Data.phone,
        //     fullName: Data.fullName,
        //     store: Data.store,
        //     address: Data.address,
        //     type: "retailer",
        //   }));
          
        navigate(`/ordersRetailer`);
      } else {
        
        toast.error("Phone or Pin doesn't match please try again");
      }
    }
    setloader(false);
  };

  const handleFocus = (event) => {
    event.target.select();
}
  


  return (
    <div className=" overflow-y-hidden overflow-x-hidden">
      <img
        src={background_pic}
        className="fixed opacity-80"
        style={{ width: `100%`, height: `${height}px` }}
      ></img>
      <div className="background">
        <div>
          {/* <Header></Header> */}
        </div>
        <ToastContainer></ToastContainer>
          <div className="centeredDiv">
            <div className="Container w-350 sm:w-375 md:w-400 ml-auto mr-auto">
              <h3>Login</h3>
              <div className="line"></div>
              <Form>
                <Form.Group className="input" >
                  <Form.Label className="text-sm ml-3">Phone No</Form.Label>
                  <Form.Control ref={phone_ref} className="input_box mb-3"></Form.Control>

                  <Row className="w-[87%]">
                    <Form.Label className="text-sm ml-3">Pin Code</Form.Label>
                    {/* <div className="flex gap-3">
                      <Col xs={2} md={2}>
                        <Form.Control type="password" minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_1}></Form.Control>
                      </Col>
                      <Col xs={2} md={2}>
                        <Form.Control type="password" minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_2}></Form.Control>
                      </Col>
                      <Col xs={2} md={2}>
                        <Form.Control type="password" minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_3} ></Form.Control>
                      </Col>
                      <Col xs={2} md={2}>
                        <Form.Control type="password" minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_4}></Form.Control>
                      </Col>
                    </div> */}
                    <PinInput onFocus={handleFocus} values={values} onChange={(value, index, values) => setValues(values)} focusBorderColor="rgb(13,110,253, 0.7)" validate={false} showState={false} mask={true} placeholder="" size="lg" inputStyle={{ border: "1px solid #666666", fontSize: "20px" }} inputClassName="bg-white font-[900]" containerClassName="justify-between"></PinInput>
                  </Row>
                  {loader ? <button style={{ height: "48px" }}><Spinner></Spinner></button> :
                    <button className=" rounded-md hover:bg-green-900 transition-all" style={{ height: "48px" }} onClick={sign_in}>Sign In</button>
                  }
                </Form.Group>
                <p onClick={() => (navigate("/ForgotPin"))} className="font-semibold text-center text-xs hover:underline cursor-pointer pb-3">Forgot PIN?</p>
              </Form>
            </div>
            <div className=" text-white text-sm font-semibold text-center mt-12 Poppins">
              <p>Designed and Developed By <span> <u className="cursor-pointer hover:font-bold" onClick={()=>navigate("//logic-unit.com")}>Logic Unit</u></span></p>
            </div>
          </div>
        </div>
      </div>
  );
}

export default SignIn;
