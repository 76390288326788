import "./App.css";
import { Fragment, useEffect, useState } from "react";
import CRUD from "./components/Wholeseller/CRUD";
import Header from "./components/Wholeseller/Header";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Customer from "./components/Wholeseller/Customer";
import Orders from "./components/Wholeseller/Orders";
import WholesellerSignUp from "./components/Wholeseller/WholesellerSignUp";
import SignIn from "./components/SignIn";
import RetailerSignup from "./components/Retailer/RetailerSignupCheckout";
import HeaderRetailer from "./components/Retailer/HeaderRetailer";
import Home from "./components/Retailer/Home"
import MainRetailer from "./components/Retailer/MainRetailer";
import Main from "./components/Wholeseller/Main";
import Cart from "./components/Retailer/Cart";
import { Button, Col, Row } from "react-bootstrap";
import OrdersRetailer from "./components/Retailer/OrdersRetailer";
import CheckoutForm from "./components/Retailer/CheckoutForm";
import OTPVerification from "./components/Retailer/OTPverification";
import LoginCheckoutForm from "./components/Retailer/LoginCheckout";
import PhoneVerification from "./components/Retailer/PhoneVerification";
import RetailerSignupCheckout from "./components/Retailer/RetailerSignupCheckout";
import PINVerification from "./components/Retailer/PINverification";
import Registration from "./components/Registration";
import RegistrationOTPVerification from "./components/RegistrationOTPVerification";
import RetailerProfile from "./components/Retailer/RetailerProfile";
import RetailerLogin from "./components/Retailer/RetailerLogin";
import ForgotPin from "./components/ForgotPin";
import ForgotPinOTPVerification from "./components/ForgotPinOTPVerification";
import SetNewPin from "./components/SetNewPin";
import RetailerForgotPin from "./components/Retailer/RetailerForgotPin";
import RetailerSetNewPin from "./components/Retailer/RetailerSetNewPin";
import Profile from "./components/Wholeseller/Profile";
import OrderPlacedSplashScreen from "./components/Retailer/OrderPlacedSplashScreen";
import AdminPanel from "./components/Admin/AdminPanel";
import ViewRetailers from "./components/Admin/ViewRetailers";
import ViewWholesellers from "./components/Admin/ViewWholesellers";
import SelectProducts from "./components/Wholeseller/SelectProducts";
import { useStateValue } from "./store/stateprovider";
import RecentlyVisitedUrls from "./components/Retailer/RecentVisitedUrlsHome";
import RegisteredSuccessfully from "./components/RegisteredSuccessfully";
import SignIn2 from "./components/SignIn2";
import Registration2 from "./components/Registration2";
import PrintOrders from "./components/Wholeseller/PrintOrders";

function App() {
  const [id, setid] = useState(0);
  const navigate = useNavigate();
  const path = window.location.pathname;
  const [{ user }] = useStateValue();
  // const page = () =>{
  //   if(user.type === "seller"){
  //     navigate("/");
  //   }
  // }

  // useEffect(()=>{
  //   if(!user){
  //     navigate("/")
  //   }
  // },[])

  



  return (
    <div>
      {/* {!user && <Navigate replace to={"/SignIn"}></Navigate>}  */}
      {/* {user && user.type === "seller" && <Navigate replace to={"/"}></Navigate>} */}
      <Routes>
        <Route path="/AdminPanel" element={<AdminPanel />}></Route>
        <Route path="/ViewRetailers" element={<ViewRetailers />}></Route>
        <Route path="/ViewWholesellers" element={<ViewWholesellers />}></Route>

        <Route path="/wholesellerSignup" element={<WholesellerSignUp />}></Route>
        <Route path="/RetailerSignupCheckout/:url" element={<RetailerSignupCheckout />}></Route>
        <Route path="/signIn" element={<SignIn />}></Route>
        <Route path="/ForgotPin" element={<ForgotPin />}></Route>
        <Route path="/ForgotPinOTPVerification" element={<ForgotPinOTPVerification />}></Route>
        <Route path="/SetNewPin" element={<SetNewPin />}></Route>
        <Route path="/Registration/:url" element={<Registration />}></Route>
        <Route path="/Registration2/:url" element={<Registration2 />}></Route>
        <Route path="/RegistrationOTPVerification" element={<RegistrationOTPVerification />}></Route>
        <Route path="/WholesellerHome" element={<CRUD />}></Route>
        <Route path="/SelectProducts" element={<SelectProducts />}></Route>
        <Route path="/orders" element={<Orders />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/printorders" element={<PrintOrders />}></Route>

        <Route path="/*" element={<SignIn2></SignIn2>}></Route>
        <Route path="/:url" element={<Home id={id} />}></Route>
        <Route path="/cart/:url" element={<Cart />}></Route>
        <Route path="/ordersRetailer" element={<OrdersRetailer />}></Route>
        <Route path="/checkout" element={<CheckoutForm />}></Route>
        <Route path="/otpVerification/:url" element={<OTPVerification />}></Route>
        <Route path="/loginCheckout/:url" element={<LoginCheckoutForm />}></Route>
        <Route path="/phoneVerification/:url" element={<PhoneVerification />} ></Route>
        <Route path="/PINVerification/:url" element={<PINVerification />} ></Route>
        <Route path="/retailerProfile" element={<RetailerProfile />}></Route>
        <Route path="/retailerlogin/:url" element={<RetailerLogin />}></Route>
        <Route path="/retailerForgotPin/:url" element={<RetailerForgotPin></RetailerForgotPin>}></Route>
        <Route path="/retailerSetNewPin/:url" element={<RetailerSetNewPin></RetailerSetNewPin>}></Route>
        <Route path="/orderplacedsucessfully/:url" element={<OrderPlacedSplashScreen></OrderPlacedSplashScreen>}></Route>
        <Route path="/registeredsuccessfully" element={<RegisteredSuccessfully />}></Route>
        <Route path="/Home" element={<RecentlyVisitedUrls></RecentlyVisitedUrls>}></Route>
      </Routes>
    </div>
  );
}

export default App;
