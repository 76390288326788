import { Fragment, useEffect, useRef, useState } from "react";
import { Button, InputGroup, Modal, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/AddForm.css";
import  search_icon  from "../../images/ri_search-line.svg";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";

function AddForm(props) {
  const [imageAsset, setImageAsset] = useState(null);

  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(Cookies.get("user"));
  const [{ user }] = useStateValue();

  const [errors, seterrors] = useState(true);
  const [loader, setloader] = useState(false);

  const initialValues = {
    product: "",
    manufacturer: "",
    offer: "",
    bonus: "",
    total_price: "",
    Exp_date: "",
  };
  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});

  const product_ref = useRef("");
  const manufacturer_ref = useRef("");
  const offer_ref = useRef("");
  const bonus_ref = useRef("");
  const total_price_ref = useRef("");

  // const submitHandler = (event) => {
  //   event.preventDefault();

  //   const product_data = {
  //     "product": product_ref.current.value,
  //     "manufacturer": manufacturer_ref.current.value,
  //     "offer": offer_ref.current.value,
  //     "total_price": total_price_ref.current.value,
  //     "ExpiryDate": Exp_date_ref.current.value,
  //   };

  //   const url = "${BASE_URL}/MedicalProducts";
  //   axios.post(url, product_data).then((result) => {
  //     //post then result, if result is successful then call getData()
  //     Clear();
  //   });
  // };

  //making validations
  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const encryptValue = (value) => {
    let encrypted = '';
    for (let i = 0; i < value.length; i++) {
      encrypted += (value.charCodeAt(i) + 1); // Simple encryption: increment ASCII value by 1
    }
    return encrypted.trim().slice(0,4);
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!product_ref.current.value) {
      errors.product = "Product Name is Required";
    }
    if (manufacturer_ref.current.value === "Select") {
      errors.manufacturer = "Select a Manufacturer";
    }

    if (!offer_ref.current.value) {
      offer_ref.current.value = 0;
    }

    if (!bonus_ref.current.value) {
      bonus_ref.current.value = 0;
    }
    if(bonus_ref.current.value === "None"){
      bonus_ref.current.value = "";
    }

    if (!total_price_ref.current.value) {
      errors.total_price = "Total Price is Required";
    }
    return errors;
  };

  useEffect(() => {
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0) {
      seterrors(false)
    }
  }, [formError])

  const add = async (event) => {
    setloader(true);
    event.preventDefault();
    setFormErrors(validate(formValues));
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return toast.error("Please Enter all the required Fields");
    }
    const product_code = encryptValue(product_ref.current.value);
    if (!errors) {
      const product_data = {
        product_code: product_code,
        product_name: product_ref.current.value,
        manufacturer: manufacturer_ref.current.value,
        offer: offer_ref.current.value,
        total_price: total_price_ref. current.value,
        bonus: bonus_ref.current.value,
      };

      const response = await fetch(
        `${BASE_URL}/Admin`,
        {
          method: "POST",
          body: JSON.stringify(product_data),
          headers: { "Content-Type": "application/json" },
        }
      ).catch(error=>{
        console.log("abc")
      });
      const data = await response.json();
      console.log(data);
      if (response.status === 201 || response.status === 200) {
        props.toast.success("Added Succesfully");
        props.getData();
        // window.location.reload(true);
      }
      if(response.status === 400 && product_ref.current.value){
        props.toast.error("Product Already added for the company");
      }
      if (!user) {
        props.toast.error("Please Login");
      }
    }
    setloader(false);
    Clear();
  };

  const Clear = () => {
    product_ref.current.value = "";
    offer_ref.current.value = "";
    total_price_ref.current.value = "";
    manufacturer_ref.current.value = "Select";
    bonus_ref.current.value = "None";
  };

  return (
    // <Modal
    // <div
    //   className="modal show"
    //   style={{ display: "block", position: "initial" }}
    // >
    //   <Modal show={props.show} onHide={props.handleClose}>
    //     <Modal.Header closeButton>
    //       <Modal.Title>Add Form</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <Form>
    //         <Row>
    //           <Col>
    //             <Form.Control
    //               required
    //               placeholder="Product Name"
    //               ref={product_ref}
    //               onChange={changehandler}
    //             />
    //             <Form.Text muted>
    //               <p className="text-xs text-red-500 absolute">
    //                 {formError.product}
    //               </p>
    //             </Form.Text>
    //           </Col>
    //           <Col>
    //             <Form.Select ref={manufacturer_ref} onChange={changehandler}>
    //               <option>Select Category</option>
    //               <option>GSK</option>
    //               <option>GetPharmz</option>
    //               <option>Abott</option>
    //             </Form.Select>
    //             <Form.Text muted>
    //               <p className="text-xs text-red-500 absolute">
    //                 {formError.manufacturer}
    //               </p>
    //             </Form.Text>
    //           </Col>
    //         </Row>
    //         <br></br>
    //         <Row>
    //           <Col>
    //             <Form.Control
    //               type="number"
    //               placeholder="Unit Price"
    //               ref={offer_ref}
    //               onChange={changehandler}
    //             />
    //             <Form.Text muted>
    //               <p className="text-xs text-red-500 absolute">
    //                 {formError.offer}
    //               </p>
    //             </Form.Text>
    //           </Col>
    //           <Col>
    //             <Form.Control
    //               required
    //               type="number"
    //               placeholder="bonus"
    //               ref={bonus_ref}
    //               onChange={changehandler}
    //             />
    //           </Col>
    //         </Row>
    //         <br></br>
    //         <Row>
    //           <Col>
    //             <Form.Control
    //               required
    //               placeholder="total_price"
    //               ref={total_price_ref}
    //               onChange={changehandler}
    //             />
    //             <Form.Text muted>
    //               <p className="text-xs text-red-500 absolute">
    //                 {formError.total_price}
    //               </p>
    //             </Form.Text>
    //           </Col>
    //           <Col>
    //             <Form.Control
    //               type="date"
    //               placeholder="ExpiryDate"
    //               ref={Exp_date_ref}
    //               onChange={changehandler}
    //             />
    //             <Form.Text muted>
    //               <p className="text-xs text-red-500 absolute">
    //                 {formError.Exp_date}
    //               </p>
    //             </Form.Text>
    //           </Col>
    //         </Row>
    //         <br></br>
    //         <Row>
    //           <Col>
    //             <Form.Control type="file" />
    //           </Col>
    //         </Row>
    //       </Form>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="secondary" onClick={props.handleClose}>
    //         Close
    //       </Button>
    //       <Button variant="primary" onClick={add}>
    //         Save Changes
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>>

    <Fragment className="overflow-hidden">
      <Form style={{ marginTop: "23px" }}>
        <Form.Group className="mb-4">
          <Row>
            <Col md={3} xs={5}>
              <Form.Text className="label">Item Name</Form.Text>
              <Form.Control
                type="text"
                style={{ height: "36px" }}
                className="border-solid border border-gray-400"
                ref={product_ref}
                onChange={changehandler}
              ></Form.Control>
              <Form.Text muted>
                <p className="text-xs text-red-500 absolute">
                  {formError.product}
                </p>
              </Form.Text>
            </Col>
            <Col md={3} xs={5}>
              <Form.Text className="label">Brand</Form.Text>
              <Form.Select
                style={{ height: "36px" }}
                className="border-solid border border-gray-400"
                ref={manufacturer_ref}
                onChange={changehandler}
              >
                <option>Select</option>
                <option>ABBOTT</option>
                <option>A.R Laboratories</option>
                <option>Ali Gohar</option>
                <option>Asian Continental</option>
                <option>ATCO</option>
                <option>B H</option>
                <option>Bayer</option>
                <option>Bioganic</option>
                <option>Bosch</option>
                <option>C C L</option>
                <option>Coris</option>
                <option>DKT Pakistan</option>
                <option>Efroz Sons</option>
                <option>Feroz Sons</option>
                <option>Genix</option>
                <option>Genome Pharmaceuticals</option>
                <option>GETZ</option>
                <option>GSK</option>
                <option>Healthaid LTD</option>
                <option>Helix</option>
                <option>Highnoon</option>
                <option>Hilton</option>
                <option>Himont</option>
                <option>Horizon Pharma</option>
                <option>Hudson Pharma</option>
                <option>ICI Pakistan</option>
                <option>Indus Pharma</option>
                <option>Janssen</option>
                <option>Kaizen</option>
                <option>Lazma Bio Lab</option>
                <option>LundBeck</option>
                <option>Macter</option>
                <option>Martin Dow</option>
                <option>Mass Pharma</option>
                <option>Matrix</option>
                <option>Maxitech</option>
                <option>Medics Pharma</option>
                <option>Mendoza</option>
                <option>Merck</option>
                <option>Mixx Item</option>
                <option>MSD</option>
                <option>Nabiqasim</option>
                <option>Neutro Pharma</option>
                <option>Next Pharma</option>
                <option>Novartis</option>
                <option>Opal Laboratories</option>
                <option>Pacific</option>
                <option>Parke Davis</option>
                <option>Pfizer</option>
                <option>Pharmadic</option>
                <option>Pharmatec</option>
                <option>Pharmevo</option>
                <option>Platinum</option>
                <option>R G Pharmacutica</option>
                <option>Ramigton</option>
                <option>Pharmevo</option>
                <option>Pharmevo</option>
                <option>Pharmevo</option>
                <option>Pharmevo</option>
              </Form.Select>
              <Form.Text muted>
                <p className="text-xs text-red-500 absolute">
                  {formError.manufacturer}
                </p>
              </Form.Text>
            </Col>
            <Col>
              <Form.Text className="label">Offer(%)</Form.Text>
              <Form.Control
                type="number"
                style={{ width: "80px", height: "36px" }}
                className="border-solid border border-gray-400"
                ref={offer_ref}
                onChange={changehandler}
              ></Form.Control>
            </Col>
            <Col style={{ marginLeft: "-2%" }}>
              <Form.Text className="label">Bonus</Form.Text>
              {/* <Form.Control
                type="text"
                style={{ width: "100%", height: "36px" }}
                className="border-solid border border-gray-400"
                ref={bonus_ref}
                onChange={changehandler}
              ></Form.Control> */}
              <Form.Select
                style={{ height: "36px" }}
                className="border-solid border border-gray-400"
                ref={bonus_ref}
                onChange={changehandler}
              >
                <option>None</option>
                <option>OLD TP</option>
                <option>770 NET</option>
                <option>77 NET</option>
                <option>1600 NET</option>
                <option>FRASH</option>
                <option>ALPROZOLAM</option>
                <option>OLD TPP</option>
                <option>50 PR 7%</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Text className="label">T.P</Form.Text>
              <Form.Control
                type="text"
                style={{ width: "100%", height: "36px" }}
                className="border-solid border border-gray-400"
                ref={total_price_ref}
                onChange={changehandler}
              ></Form.Control>
              <Form.Text muted>
                <p className="text-xs text-red-500 absolute">
                  {formError.total_price}
                </p>
              </Form.Text>
            </Col>
            <Col className="mt-4">
              {loader ? <Button
                style={{ width: "100%", height: "36px" }}
                className="add_button"
              >
                <Spinner size="sm"></Spinner>
              </Button> :
                <Button
                  style={{ width: "100%", height: "36px" }}
                  className="add_button"
                  onClick={add}
                >
                  <p>Add</p>
                </Button>
              }
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </Fragment>
  );
}
export default AddForm;
