import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import "../Style/otpVerification.css"
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { PinInput } from "react-input-pin-code";

function RetailerSignupCheckout() {

  const name_ref = useRef("");
  const phone_ref = useRef("");
  const passcode_1 = useRef("");
  const passcode_2 = useRef("");
  const passcode_3 = useRef("");
  const passcode_4 = useRef("");
  const store_ref = useRef("");
  const address_ref = useRef("");

  const [errors, seterrors] = useState(true);
  const { url } = useParams();
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [tot, setTot] = useState(0)
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [wholeseller_data, setwholeseller_data] = useState({});
  const [loader, setloader] = useState(false);
  const [id, setid] = useState(null);

  // const phone = localStorage.getItem('phone');
  const phone = Cookies.get('phone');

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else {
            inputs[index + 1].focus();
            inputs[index + 1].select()
          }
        }
      })
    })
  }, [])

  const [values, setValues] = useState(['', '', '', '']);

  const CloseHandler = () => {
    setcartmobile(false);
  }

  const setMessage = () => {
    setcartmobile(true);
  }

  const WholesellerData = async () => {
    const response_id = await fetch(`${BASE_URL}/WholesellerCategory/wholeseller/${url}`).catch(error=>{
      console.log("abc")
    });
    if(!response_id){
      setloader(false);
      toast.error("Error Occured Please try again");
    }
    const Id = await response_id.text();
    const response = await fetch(`${BASE_URL}/WholesellerControllerSignUp/${Id}`);
    setid(Id)
    return await response.json();
  }


  const getWholesellerData = async () => {
    const a = await WholesellerData();
    setwholeseller_data(a)
  }

  useEffect(() => {
    getWholesellerData();
  }, [])

  console.log(wholeseller_data);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  const initialValues = {
    name: "",
    phone: "",
    passcode_1: "",
    passcode_2: "",
    passcode_3: "",
    passcode_4: "",
    store: "",
    address: ""
  };
  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});
  const [IsSignup, setisSignUp] = useState(false);
  const [order_id, setOrder_id] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let totalPrice = cartItems.reduce(function (accumulator, item) {
      return accumulator + item.quantity * item.total_price;
    }, 0);
    setTot(totalPrice);
  }, [tot, cartItems]);

  // useEffect(() => {
  //   const tableContainer = document.querySelector('.table-scroll');

  //   // Check if the table overflows its container element
  //   if (tableContainer.scrollHeight > tableContainer.clientHeight) {
  //     // Show the scroll bar
  //     tableContainer.style.overflowY = 'scroll';
  //     tableContainer.style.maxHeight = "70%";
  //   }
  // }, []);


  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const name_regex = /^[a-zA-Z\s]+$/;

      if (!name_ref.current.value) {
        errors.name = "Full Name is Required";
        // } else if (!regex.test(email_ref.current.value)) {
        //   errors.email = "Enter a Valid Email address";
      }
      else if (!name_regex.test(name_ref.current.value)) {
        errors.name = "Alphabets only";
        // } else if (!regex.test(email_ref.current.value)) {
        //   errors.email = "Enter a Valid Email address";
      }
    if (!phone_ref.current.value) {
      errors.phone = "Phone No is Required";
    } else if (!phone_regex.test(phone_ref.current.value)) {
      errors.phone = "Enter a Valid Phone No";
    }

    if (values[0] === '' || values[1] === '' || values[2] === '' || values[3] === '') {
      errors.password = "Passcode is Required";
    }
    if (!store_ref.current.value) {
      errors.store = "Store is Required";
    }
    if (!address_ref.current.value) {
      errors.address = "Phone No is Required";
    }

    return errors;
  };

  useEffect(() => {
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0) {
      seterrors(false);
    }
    if (errors_count > 0) {
      seterrors(true);
    }
  }, [formError])

  const addretailer = async (event) => {
    // event.preventDefault();
    setFormErrors(validate(formValues));
    const errors_count = Object.keys(formError).length;
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return
    }
    
      let phone_no = phone_ref.current.value;
      if (phone_no.charAt(0) === "+92") {
        phone_no = "0" + phone_no.slice(1);
      }

      const retailer_data = {
        fullName: name_ref.current.value,
        email: "",
        phone: phone_no,
        store: store_ref.current.value,
        address: address_ref.current.value,
        password: values[0] + values[1] + values[2] + values[3],

      };

      const response = await fetch(`${BASE_URL}/Retailers`, {
        method: "POST",
        body: JSON.stringify(retailer_data),
        headers: { "Content-Type": "application/json" },
      }).catch(error=>{
        console.log("abc")
      });
      if(!response){
        toast.error("Error Occured. Please try again");
        setloader(false);
      }
      const retailer_id = await response.text();
      console.log(retailer_id);

      const retailer_data2 = {
        id: retailer_id,
        fullName: name_ref.current.value,
        email: "",
        phone: phone_no,
        store: store_ref.current.value,
        address: address_ref.current.value,
      };

      dispatch({
        type: action_type.SET_USER,
        user: retailer_data2,
        //if you look at the console.log(response) you will see that providerData is an array
      });

      Cookies.set('user', JSON.stringify(retailer_data2), { expires: 2 });
      // localStorage.removeItem("user");
      // localStorage.setItem("user",JSON.stringify(retailer_data2));
      return retailer_id;
  };

  const Clear = () => {
    name_ref.current.value = "";
    phone_ref.current.value = "";
    setValues(['','','','']);
    store_ref.current.value = "";
    address_ref.current.value = "";
  };

  const clearCart = () => {
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: [],
    });
    localStorage.setItem("cartItems", JSON.stringify([]));
  };

  const handleCreateOrder = async (event) => {
    const retailer_id = await addretailer(event);
    console.log(id);
    const product_data = {
      retailer_id: retailer_id,
      retailer_name: name_ref.current.value,
      total: tot,
      totalItems: cartItems.length,
      status: "Pending",
      seller_store: wholeseller_data.busniess_name,
      store: store_ref.current.value,
      phone_no: phone_ref.current.value,
      seller_phone_no: wholeseller_data.phone,
      address: address_ref.current.value,
      wholeseller_id: id,
    };
    const response = await fetch(`${BASE_URL}/Orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(product_data),
    }).catch(error=>{
      console.log("abc")
    });
    if(!response){
      toast.error("Error Occured. Please try again");
      setloader(false);
    }
    const order_id = await response.text();
    setOrder_id(order_id);

    return order_id;
  };

  const Buy_Now = async (event) => {
    setloader(true);
    event.preventDefault();
    setFormErrors(validate(formValues));
    const errors_count = Object.keys(formError).length;
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return
    }
    
      const order_id = await handleCreateOrder();
      for (const key in cartItems) {
        const product_data = {
          productName: cartItems[key].product_name,
          quantity: cartItems[key].quantity,
          price: cartItems[key].total_price,
          orderId: order_id,
        };

        const response = await fetch(`${BASE_URL}/Orders/${order_id}/items`, {
          method: "POST",
          body: JSON.stringify(product_data),
          headers: { "Content-Type": "application/json" },
        }).catch(error=>{
          console.log("abc")
        });
        if(!response){
          toast.error("Error Occured. Please try again");
          setloader(false);
        }
        const data = await response.json();
        console.log(data);
      }
      seterrors(false);
    if (cartItems.length >= 1) {
      Clear();
      clearCart();
      toast.success("Retailer Registered Successfully");
      // toast.success("Order Placed Succesfully");
      // navigate(`/ordersRetailer/${url}/${id}`);
      navigate(`/orderplacedsucessfully/${url}`);
    }
    if (cartItems.length === 0) {
      return toast.error("Add items to your cart");
    }
    
    setloader(false);
  };

  const ConfirmOrder = (event) => {
    Buy_Now(event);
  }

  const handleFocus = (event) => {
    event.target.select();
}


  return (
    <div style={{ maxHeight: "100vh" }} className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{ marginLeft: "2.5%" }}>
        <Col md={9} xs={11}>
          <div style={{overflowY:"scroll" ,fontFamily: "Poppins", maxHeight: "85vh" }}>
            <div className="breadcrum flex">
              <h3 className="text-2xl text-black mt-2 Poppins font-bold">Registration</h3>
              {/* <div style={{marginLeft:"20%"}} className="flex gap-2 mt-1">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
            </div>
            <Form>
              <Form.Group className="ml-1 w-275 md:w-375 mt-2 Poppins text-sm">
                <div className="flex gap-10 md:gap-32">
                  <Form.Label className=" ml-3 text-slate-500">Full Name</Form.Label>
                  <Form.Label className=" text-red-600 ml-3 text-xs">{formError.name}</Form.Label>
                </div>
                <Form.Control
                  style={{ border: "1px solid #333333" }}
                  className=" mb-2 text-slate-500" ref={name_ref} onChange={changehandler}>
                </Form.Control>

                <div className="flex gap-20 md:gap-32">
                  <Form.Label className="ml-3 text-slate-500">Phone</Form.Label>
                  <Form.Label className=" text-red-600 ml-3 text-xs">{formError.phone}</Form.Label>
                </div>
                <Form.Control disabled onChange={changehandler} value={phone} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={phone_ref}></Form.Control>


                <div className="flex gap-4">
                  <Form.Label className="ml-3 text-slate-500">Set Pin Code</Form.Label>
                  <Form.Label className=" text-red-600 ml-3 text-xs">{formError.password}</Form.Label>
                </div>
                <Row style={{ width: "50%" }} className="passcode mb-2">
                  {/* <div className="flex  gap-3">
                    <Col xs={6} md={4}>
                      <Form.Control type="password" minLength={1} maxLength={1} id="input1" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_1}></Form.Control>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Control type="password" minLength={1} maxLength={1} id="input2" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_2}></Form.Control>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Control type="password" minLength={1} maxLength={1} id="input3" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_3} ></Form.Control>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Control type="password" minLength={1} maxLength={1} id="input4" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_4}></Form.Control>
                    </Col>
                  </div> */}
                  <PinInput onFocus={handleFocus} values={values} onChange={(value, index, values) => setValues(values)} focusBorderColor="rgb(13,110,253, 0.7)" validate={false} showState={false} mask={true} placeholder="" size="lg" inputStyle={{ border: "1px solid #666666", fontSize: "20px" }} inputClassName="bg-white font-[900]" containerClassName="gap-3"></PinInput>
                </Row>
                <div className="flex gap-10 md:gap-32">
                  <Form.Label className="ml-3 text-slate-500">Busniess Name</Form.Label>
                  <Form.Label className=" text-red-600 ml-3 text-xs">{formError.store}</Form.Label>
                </div>
                <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={store_ref}></Form.Control>

                <div className="flex gap-10 md:gap-32">
                  <Form.Label className="ml-3 text-slate-500">Address</Form.Label>
                  <Form.Label className=" text-red-600 ml-3 text-xs">{formError.address}</Form.Label>
                </div>

                <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={address_ref}></Form.Control>

                <div className="flex gap-10 md:gap-32">
                  <Form.Label className="ml-3 text-slate-500">Registered As</Form.Label>
                  <Form.Label className=" text-red-600 ml-3 text-xs">{formError.user}</Form.Label>
                </div>
                <div className="flex gap-4">
                  <label className="flex gap-2 opacity-80"><span>Wholesaler</span><Form.Check disabled value="wholeseller" type="radio" name="group1"></Form.Check></label>
                  <label className="flex gap-2"><span>Retailer</span><Form.Check checked value="retailer" type="radio" name="group1"></Form.Check></label>
                </div>
                {loader ? <Button className="submit-button mt-3 mb-2"><Spinner></Spinner></Button> :
                  <Button onClick={ConfirmOrder} className="submit-button mt-3 mb-2">Submit</Button>
                }
              </Form.Group>
            </Form>
          </div>
        </Col>
        {showCart && <Col md={3} xs={5}>
          <Cart
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
        {cartmobile && <CartMobile
          CloseHandler={CloseHandler}
        >
        </CartMobile>}
      </Col>
    </div>
  );
}
export default RetailerSignupCheckout;
