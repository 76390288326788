import Cookies from "js-cookie";

export const fetchData = () => {
  // const user_info =
  //   localStorage.getItem("user") !== "undefined"
  //     ? localStorage.getItem("user") //JSON.parse(localStorage.getItem("user"))
  //     : localStorage.clear();
  
  const user_info = Cookies.get('user');
  if(user_info){
    const data = JSON.parse(user_info);
    return data;
  }
};

export const fetchCart = () => {
  const cart_info =
    localStorage.getItem("cartItems") !== "undefined"
      ? JSON.parse(localStorage.getItem("cartItems"))
      : localStorage.clear();

    return cart_info ? cart_info : []
};