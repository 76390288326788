import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { action_type } from "../store/reducer";
import background_pic from "../images/image 31.png";
import { useStateValue } from "../store/stateprovider";
import "./Style/SignIn.css";
import Header from "./Wholeseller/Header";
import BASE_URL from './Config';
import Cookies from "js-cookie";
import { MdSignalCellular2Bar } from "react-icons/md";
import { PinInput } from "react-input-pin-code";
import logo from "../images/bookmyorder - logo Green.svg"

function SignIn2() {
    const [{ user }, dispatch] = useStateValue();
    const [verified, setIsVerified] = useState(false);
    const phone_ref = useRef("");
    const navigate = useNavigate();
    const [loader, setloader] = useState(false);
    const width = window.innerWidth.toString();
    const height = window.innerHeight.toString();
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        if (user && user.type === "seller") {
          navigate("/profile");
        }
        if (user && user.type === "retailer") {
          navigate("/retailerProfile");
        }
        if (user && user.type === "admin") {
          navigate("/AdminPanel");
        }
      }, [])

    // const usr = JSON.parse(localStorage.getItem("user"));

    const [values, setValues] = useState(['', '', '', '']);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        } //We need to return it as a dependency
    }, [])

    const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
            sign_in();
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                setMobileView(true);
            } // Set breakpoint here
            else {
                setMobileView(false);
            }
        };
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const passcode_1 = useRef("");
    const passcode_2 = useRef("");
    const passcode_3 = useRef("");
    const passcode_4 = useRef("");
    passcode_1.current = values[0];
    passcode_2.current = values[1];
    passcode_3.current = values[2];
    passcode_4.current = values[3];


    const sign_in = async (event) => {
        setloader(true);
        const password = passcode_1.current + passcode_2.current + passcode_3.current + passcode_4.current;
        let phone_no = phone_ref.current.value;
        if (phone_no.charAt(0) === "+92") {
            phone_no = "0" + phone_no.slice(1);
        }
        if (!user) {
            const response_seller = await fetch(
                `${BASE_URL}/WholesellerControllerSignUp/${phone_no}/${password}`
            ).catch(error => {
                console.log("abc")
            });
            const response_retailer = await fetch(
                `${BASE_URL}/Retailers/${phone_no}/${password}`
            ).catch(error => {
                console.log("abc")
            });
            if (phone_no === "1234" && password === "1234") {
                const admin_data = JSON.stringify({ type: "admin" })
                dispatch({
                    type: action_type.SET_USER,
                    user: admin_data,
                    //if you look at the console.log(response) you will see that providerData is an array
                });
                Cookies.set('user', admin_data, { expires: 2 });
                navigate("/AdminPanel");
            }
            else if (!response_seller && !response_retailer) {
                toast.error("Error Occured. Please try again");
                setloader(false);
            }
            else if (response_seller.status === 200) {
                const Data = await response_seller.json();
                console.log(Data);
                if (Data.is_disabled === true) {
                    setloader(false);
                    return toast.error("Your Profile has been disabled by Admin");
                }

                setIsVerified(true);
                toast.success("Login Successful");
                dispatch({
                    type: action_type.SET_USER,
                    user: Data,
                    //if you look at the console.log(response) you will see that providerData is an array
                });
                Cookies.set('user', JSON.stringify({
                    id: Data.id,
                    full_name: Data.full_name,
                    address: Data.address,
                    busniess_name: Data.busniess_name,
                    email: Data.email,
                    phone: Data.phone,
                    url: Data.url,
                    type: "seller",
                }), { expires: 2 });



                // localStorage.setItem(
                //   "user",
                //   JSON.stringify({
                //     id: Data.id,
                //     full_name: Data.full_name,
                //     address: Data.address,
                //     busniess_name: Data.busniess_name,
                //     email: Data.email,
                //     phone: Data.phone,
                //     type: "seller",
                //   }));
                navigate("/WholesellerHome");
            } else if (response_retailer.status === 200) {
                const Data = await response_retailer.json();
                if (Data.is_disabled == true) {
                    setloader(false);
                    return toast.error("Your Profile has been disabled by Admin");
                }
                setIsVerified(true);
                toast.success("Login Successful");
                dispatch({
                    type: action_type.SET_USER,
                    user: Data,
                    //if you look at the console.log(response) you will see that providerData is an array
                });

                Cookies.set('user', JSON.stringify({
                    id: Data.id,
                    email: Data.email,
                    phone: Data.phone,
                    fullName: Data.fullName,
                    store: Data.store,
                    address: Data.address,
                    type: "retailer",
                }), { expires: 2 });

                // localStorage.setItem(
                //   "user",
                //   JSON.stringify({
                //     id: Data.id,
                //     email: Data.email,
                //     phone: Data.phone,
                //     fullName: Data.fullName,
                //     store: Data.store,
                //     address: Data.address,
                //     type: "retailer",
                //   }));

                navigate(`/ordersRetailer`);
            } else {

                toast.error("Phone or Pin doesn't match please try again");
            }
        }
        setloader(false);
    };

    const handleFocus = (event) => {
        event.target.select();
    }
    return (
        <div className="overflow-y-hidden overflow-x-hidden" >
            <ToastContainer></ToastContainer>
            {!mobileView ? <div className="centeredDiv">
                <Row className="justify-between">
                    <Col md={5} >
                        <div className="md:ml-[18%]">
                            <img src={logo} className="w-[15%] h-[5%] overflow-y-hidden"></img>
                            <div className="flex gap-2">
                                <div className="column-head md:text-[2.5rem]">BookMyOrder</div>
                            </div>
                            <div className="column-sub-head md:text-[2.5rem]">Welcome</div>
                            <p className="text-sm font-semibold mt-[3%]">We only need your email and passcode , and you'll be on your way.</p>
                            <div className="text-sm font-semibold Poppins mt-[12rem]">
                                <p>Designed and Developed By <span> <u className="cursor-pointer hover:font-bold" onClick={() => navigate("//logic-unit.com")}>Logic Unit</u></span></p>
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className="md:ml-[20%] w-[40%]">
                            <div className="mt-[10%] text-2xl font-medium">Login</div>
                            <Form.Group className="mt-[5%]">
                                <Form.Label className="text-sm">Phone No.</Form.Label>
                                <Form.Control ref={phone_ref} type="number" className="border-[rgba(0, 0, 0, 0.23)] mb-[3%]"></Form.Control>
                                <div className="mb-[5%]">
                                    <Form.Label className=" text-sm">Passcode</Form.Label>
                                    {/* <Row className="passcodes gap-[5%]">
                                        <Col>
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input1" className="text-center" ref={passcode_1}></Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input2" className="text-center" ref={passcode_2}></Form.Control>
                                        </Col>
                                        <Col >
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input3" className="text-center" ref={passcode_3} ></Form.Control>
                                        </Col>
                                        <Col >
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input4" className="text-center" ref={passcode_4}></Form.Control>
                                        </Col>
                                    </Row> */}
                                    <PinInput onFocus={handleFocus} values={values} onChange={(value, index, values) => setValues(values)} focusBorderColor="rgb(13,110,253, 0.7)" validate={false} showState={false} mask={true} placeholder="" size="lg" inputStyle={{ border: "1px solid rgb(0,0,0, 0.23)", fontSize: "20px" }} inputClassName="bg-white font-[900]" containerClassName="justify-between"></PinInput>
                                </div>
                                <label className="ml-[2%] flex gap-2"><Form.Check className="" value="remember me" type="checkbox" name="group1"></Form.Check><span>Remember me</span></label>
                                {loader ? <Button variant="success" className="mt-[5%] w-[100%] h-[41px]"><Spinner size="sm"></Spinner></Button> :
                                    <Button onClick={sign_in} variant="success" className="mt-[5%] w-[100%] h-[41px]">SIGN IN</Button>}
                                <div onClick={() => (navigate("/ForgotPin"))} className="flex gap-1 text-xs mt-[5%] cursor-pointer hover:underline">
                                    <div>Forgot Your Password? <span className="font-semibold">Reset</span></div>
                                </div>
                                <div className="mt-[5%] flex gap-1">
                                    <hr className="w-[50%] border-dashed"></hr>
                                    <p className=" pt-1 text-sm">OR</p>
                                    <hr className="w-[50%] border-dashed"></hr>
                                </div>
                                <button onClick={() => navigate("/Registration/:id")} className="w-[100%] mt-[5%] border-solid border-[1px] border-[#0A9A73] text-[#0A9A73] h-[41px] rounded-md hover:text-white hover:bg-[#157347] transition-all ease-out duration-300">SIGN UP</button>
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
            </div> :
                <div className="m-[5%]">
                    <div className="flex gap-2">
                        <img src={logo} className="w-[10%] h-[5%] overflow-y-hidden mt-[2%]"></img>
                        <div className="column-head md:text-[2.5rem]">BookMyOrder</div>
                    </div>
                    <div className="md:ml-[20%] w-[80%]">
                        <div className="mt-[10%] text-2xl font-medium">Login</div>
                        <Form.Group className="mt-[5%]">
                            <Form.Label className="text-sm">Phone No.</Form.Label>
                            <Form.Control ref={phone_ref} type="number" className="border-[rgba(0, 0, 0, 0.23)] mb-[3%]"></Form.Control>
                            <div className="mb-[5%]">
                                <Form.Label className=" text-sm">Passcode</Form.Label>
                                {/* <Row className="passcodes gap-[5%]">
                                        <Col>
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input1" className="text-center" ref={passcode_1}></Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input2" className="text-center" ref={passcode_2}></Form.Control>
                                        </Col>
                                        <Col >
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input3" className="text-center" ref={passcode_3} ></Form.Control>
                                        </Col>
                                        <Col >
                                            <Form.Control type="password" minLength={1} maxLength={1} id="input4" className="text-center" ref={passcode_4}></Form.Control>
                                        </Col>
                                    </Row> */}
                                <PinInput onFocus={handleFocus} values={values} onChange={(value, index, values) => setValues(values)} focusBorderColor="rgb(13,110,253, 0.7)" validate={false} showState={false} mask={true} placeholder="" size="lg" inputStyle={{ border: "1px solid rgb(0,0,0, 0.23)", fontSize: "20px" }} inputClassName="bg-white font-[900]" containerClassName="justify-between"></PinInput>
                            </div>
                            <label className="ml-[2%] flex gap-2"><Form.Check className="" value="remember me" type="checkbox" name="group1"></Form.Check><span>Remember me</span></label>
                            {loader ? <Button variant="success" className="mt-[5%] w-[100%] h-[41px]"><Spinner size="sm"></Spinner></Button> :
                                <Button onClick={sign_in} variant="success" className="mt-[5%] w-[100%] h-[41px]">SIGN IN</Button>}
                            <div onClick={() => (navigate("/ForgotPin"))} className="flex gap-1 text-xs mt-[5%] cursor-pointer hover:underline">
                                <div>Forgot Your Password? <span className="font-semibold">Reset</span></div>
                            </div>
                            <div className="mt-[5%] flex gap-1">
                                <hr className="w-[50%] border-dashed"></hr>
                                <p className=" pt-1 text-sm">OR</p>
                                <hr className="w-[50%] border-dashed"></hr>
                            </div>
                            <button onClick={() => navigate("/Registration/:id")} className="w-[100%] mt-[5%] border-solid border-[1px] border-[#0A9A73] text-[#0A9A73] h-[41px] rounded-md hover:text-white hover:bg-[#157347] transition-all ease-out duration-300">SIGN UP</button>
                        </Form.Group>
                    </div>
                    <div className="text-sm font-semibold Poppins mt-[20%]">
                        <p>Designed and Developed By <span> <u className="cursor-pointer hover:font-bold" onClick={() => navigate("//logic-unit.com")}>Logic Unit</u></span></p>
                    </div>
                </div>
            }

        </div>

    )
}

export default SignIn2