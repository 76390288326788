import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";

function InfoModal(props) {

    const UpdateHandler = () =>{
        props.UpdateHandler(props.data.id);
    }

    return (
        <div
            className="modal show"
            style={{ display: "block", position: "initial" }}
        >
            <Modal show={props.show} onHide={props.handleClose}>
                <Container style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
                    <Modal.Header className="font-semibold text-lg flex">
                        <div>{props.header}</div>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="text-sm">
                            <Row className="mb-6">
                                <Col className="flex gap-2">
                                    <div className="font-bold">Name:</div>
                                    <div>{props.data.full_name}</div>
                                </Col>
                                <Col className="flex gap-2">
                                    <div className="font-bold">Store:</div>
                                    <div>{props.data.busniess_name}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="flex gap-2">
                                    <div className="font-bold">Address:</div>
                                    <div>{props.data.address}</div>
                                </Col>
                                <Col className="flex gap-2">
                                    <div className="font-bold">Phone:</div>
                                    <div>{props.data.phone}</div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className="flex">
                        {!props.data.is_disabled ? <Button onClick={()=>UpdateHandler()}>Deactivate</Button> : <Button onClick={()=>UpdateHandler()}>Activate</Button>}
                    </Modal.Footer>
                </Container>
            </Modal>
        </div>
    );
}
export default InfoModal