import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import pic from "../../images/heropic.jpg";
import { action_type } from "../../store/reducer";
import { useStateValue } from "../../store/stateprovider";
import HeaderRetailer from "./HeaderRetailer";
import { useNavigate, useParams } from "react-router-dom";
import "../Style/Cart.css";
import delete_icon from "../../images/delete-icon.svg";
import BASE_URL from '../Config';


function Cart(props) {
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [flag, setFlag] = useState(1);
  const [tot, setTot] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState([]);
  let items = [];

  useEffect(() => {
    const tableContainer = document.querySelector('.cart-container');
    
    // Check if the table overflows its container element
    if (tableContainer.scrollHeight > tableContainer.clientHeight) {
      // Show the scroll bar
      tableContainer.style.overflowY = 'scroll';
    }
  }, [items]);

  useEffect(() => {
    items = cartItems;
  }, [items]);

  const clearCart = () => {
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: [],
    });
    localStorage.setItem("cartItems", JSON.stringify([]));
  };

  const cartDispatch = () => {
    localStorage.setItem("cartItems", JSON.stringify(items));
    setFlag(1);
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: items,
    });
  };

  const remove_item = (id) => {
    items = cartItems.filter((item) => item.id !== id);
    cartDispatch();
  };

  useEffect(() => {
    let totalPrice = cartItems.reduce(function (accumulator, item) {
      return accumulator + item.quantity * item.total_price;
    }, 0);
    setTot(totalPrice);
  }, [tot, cartItems, props.addqty, props.subqty]);

  const width = window.innerWidth.toString();

 




  // const Buy_Now = async (event) => {
  //   event.preventDefault();
  //   for (const key in cartItems) {
  //     const product_data = {
  //       retailer_name: "PQRS",
  //       total: tot,
  //       status: "Pending",
  //       products: cartItems[key].product_name,
  //       wholeseller_id: id,
  //     };

  //     const response = await fetch("${BASE_URL}/Orders", {
  //       method: "POST",
  //       body: JSON.stringify(product_data),
  //       headers: { "Content-Type": "application/json" },
  //     });
  //     const data = await response.json();
  //     console.log(data);
  //   }
  //   toast.success("Updated Succesfully");
  //   clearCart();
  //   navigate(`/ordersRetailer/${id}`);
  // };
  
  return (
    // <Fragment>
    //   <HeaderRetailer></HeaderRetailer>
    //   <br></br>
    //   <Row style={{ gap: "5px" }}>
    //     {cartItems && cartItems.length > 0 ? (
    //       cartItems.map((item, index) => (
    //         <Card key={index} style={{ position: "relative", width: "14rem" }}>
    //           <Card.Img variant="top" src={pic} />
    //           <Card.Body>
    //             <Card.Title>{item.product_name}</Card.Title>
    //             <Card.Text>
    //               Some quick example text to build on the card title and make up
    //               the bulk of the card's content.
    //             </Card.Text>
    //           </Card.Body>
    //           <ListGroup className="list-group-flush">
    //             <ListGroup.Item>
    //               <b>Manufacturer: </b>
    //               {item.manufacturer}
    //             </ListGroup.Item>
    //             <ListGroup.Item>
    //               <b>Unit Price: </b>${item.unit_price}
    //             </ListGroup.Item>
    //             <ListGroup.Item>
    //               <b>Discount: </b>
    //               {item.discount}%
    //             </ListGroup.Item>
    //             <ListGroup.Item>
    //               <b>Expiry Date: </b>
    //               {item.expiryDate}
    //             </ListGroup.Item>
    //           </ListGroup>
    //         </Card>
    //       ))
    //     ) : (
    //       <h1>Loading...</h1>
    //     )}
    //   </Row>
    //   <br></br>
    //   <Button onClick={Buy_Now}>Buy Now</Button>
    // </Fragment>
    // <div
    //   className="modal show"
    //   style={{ display: "block", position: "initial", fontFamily:"Poppins" }}
    // >
    //   <Modal show={props.show} onHide={props.handleClose}>
    //     <Modal.Header closeButton>
    //       <Modal.Title>Cart Items</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body className="justify-center max-h-72 overflow-y-scroll flex gap-3">
    //       <MDBContainer>
    //         {cartItems && cartItems.length > 0 ? (
    //           cartItems.map((item, index) => (
    //             <MDBCard
    //               className="rounded-3 mt-5"
    //               style={{ height: "100px" }}
    //               key={index}
    //             >
    //               <MDBCardBody className="p-4 -mt-6">
    //                 <MDBRow className="justify-content-between align-items-center">
    //                   <MDBCol md="3">
    //                     <MDBCardImage
    //                       className="rounded-3 w-20"
    //                       src={pic}
    //                       alt="Medicine"
    //                     />
    //                   </MDBCol>
    //                   <MDBCol md="4" style={{ fontSize: "15px" }}>
    //                     <p className="lead fw-normal mb-2">
    //                       {item.product_name}
    //                     </p>
    //                     <p>
    //                       <span className="text-muted">
    //                         Unit Price: {item.unit_price}
    //                       </span>
    //                       <br></br>
    //                       <span className="text-muted">
    //                         Quantity: {item.quantity}
    //                       </span>
    //                     </p>
    //                   </MDBCol>
    //                   <MDBCol className="d-flex align-items-center justify-content-around"></MDBCol>
    //                   <MDBCol>
    //                     <h5>Total: ${item.unit_price * item.quantity}</h5>
    //                   </MDBCol>
    //                   <MDBCol className="text-end">
    //                     <AiOutlineCloseCircle
    //                       onClick={() => remove_item(item.id)}
    //                       className="cursor-pointer"
    //                     ></AiOutlineCloseCircle>
    //                   </MDBCol>
    //                 </MDBRow>
    //               </MDBCardBody>
    //             </MDBCard>
    //           ))
    //         ) : (
    //           <h5>No Items Added</h5>
    //         )}
    //       </MDBContainer>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <MDBCol>
    //       <h6>No of Items: <b>{cartItems.length}</b></h6>
    //       <h6>Total Amount: <b>{tot}</b></h6>
    //       </MDBCol>
    //       <Button variant="secondary" onClick={props.handleClose}>
    //         Close
    //       </Button>
    //       <Button onClick={Buy_Now} variant="primary">Buy Now</Button>
    //     </Modal.Footer>
    //   </Modal>
    // </div>
    <div className="overflow-hidden" style={{borderLeft:"1px solid #9da1a6",height:"100vh", maxHeight:"100vh"}}>
      <div className="cart-header text-sm">
        <p className="pt-4 pl-3">Your Order</p>
      </div>
      <div className="underline"></div>
      <div style={{maxHeight:"340px"}} className="cart-container overflow-x-hidden">
          <div className="cart-item pl-3">
            {cartItems && cartItems.length > 0 ? (
              cartItems.map((item, index) => (
                <Row key={index}>
                  <Col md={8}>
                    <h6>{item.product_name}</h6>
                    <div className="flex gap-2">
                      <p className="text-sm font-semibold text-green-600">
                        {item.total_price}
                      </p>
                      <div className="flex gap-2 text-sm font-medium text-gray-600">
                        <p>x</p>
                        <p>{item.quantity}</p>
                        <p>=</p>
                      </div>
                      <p className="flex gap-2 text-sm font-medium text-sky-400">
                        Rs. {item.total_price*item.quantity}/-
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <img className="pr-3 mt-2" onClick={() =>remove_item(item.id)} src={delete_icon}></img>
                  </Col>
                  <div className="underline opacity-30 ml-3"></div>
                </Row>
              ))
            ) : (
              <p>No items added</p>
            )}
          </div>
        <div  className={!props.btnText ? `md:absolute bottom-0 sm:relative w-[25%]` : `md:absolute bottom-2 sm:relative w-[25%]`}>
        <div
          className={`total`}
          style={{width:"30rem", overflowX:"clip"}}
        >
          <div className="flex gap-4">
            <p style={{ marginLeft:"5%", marginTop: "5%" }}>Total</p>
            <h6 className="font-bold" style={{ marginTop: "5%" }}>
              Rs. {tot}/-
            </h6>
          </div>
        </div>
        {props.btnText && <div className="w-full"><Button onClick={props.onClick} className="button">{props.btnText}</Button></div>}
        </div>
      </div>
    </div>
  );
}
export default Cart;
