import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { motion } from 'framer-motion';

function OrderPlacedSplashScreen() {
    const navigate = useNavigate();
    const { url } = useParams();
    const location = useLocation();
    const [countdown, setCountdown] = useState(5);
    const queryParams = new URLSearchParams(location.search);

    console.log(queryParams.get("id"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    if (countdown === 0) {
      clearTimeout(timer);
      navigate(`/ordersRetailer`)
    }

    return () => clearTimeout(timer);
  }, [countdown]);


    return (
        <motion.div 
        initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 1}}
        className="bg-green-600 h-screen overflow-hidden">
            <Container className="text-center mt-52">
                <h1>Your Order Has Been Placed</h1>
                <h1 className="text-white"><b>Succesfully</b></h1>
                
                <div className="mt-5">
                <h5>Redirecting to Orders in</h5>
                <h5><b className="text-white">{countdown}</b> Seconds</h5>
                </div>
            </Container>
        </motion.div>
    );
}
export default OrderPlacedSplashScreen;