import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import BASE_URL from '../Config';
import { Spinner } from "react-bootstrap";

function StatusUpdateModal(props) {
  const status_ref = useRef("");
  const [data, setData] = useState([]);
  const [loader, setloader] = useState(false);

  useEffect(()=>{  
    document.addEventListener('keydown',keyDownHandler);
  
    return ()=>{
      document.removeEventListener('keydown',keyDownHandler);
    } //We need to return it as a dependency
  },[])
  
  const keyDownHandler = (event) =>{
    if(event.key === 'Enter'){
      updatehandler();
    }
  }

  useEffect(() => {
    const Edit = async () => {
      setloader(true);
      const response = await fetch(
        `${BASE_URL}/Orders/${props.id}`
      ).catch(error=>{
        console.log("abc")
      });
      const Data = await response.json();
      status_ref.current.value = Data[0].status;

      const loaded_data = [];
      for (const key in Data) {
        loaded_data.push({
          id: Data[key].id,
          retailer_id: Data[key].retailer_id,
          retailer_name: Data[key].retailer_name,
          Wholeseller_id: Data[key].wholeseller_id,
          Total: Data[key].total,
          totalItems: Data[key].totalItems,
          status: Data[key].status,
          Order_Date: Data[key].order_Date,
          store: Data[key].store,
          seller_store: Data[key].store,
          phone_no: Data[key].phone_no,
          seller_phone_no : Data[key].seller_phone_no,
          address: Data[key].address
        });
      }
      setData(loaded_data);
      console.log(loaded_data);
      setloader(false);
    };
    Edit();
  }, [props.id]);

  const updatehandler = () => {
    setloader(true);
    if(status_ref.current.value === ""){
      setloader(false);
      return toast.error("Please select product status");
    }
    const url = `${BASE_URL}/Orders/${props.id}`;
    const product_data = {
      "id": props.id,
      "retailer_id": data[0].retailer_id,
      "retailer_name": data[0].retailer_name,
      "total": data[0].Total,
      "totalItems": data[0].totalItems,
      "status": status_ref.current.value,
      "order_Date": data[0].Order_Date,
      "store": data[0].store,
      "seller_store": data[0].seller_store,
      "phone_no":data[0].phone_no,
      "seller_phone_no":data[0].seller_phone_no,
      "address":data[0].address,
      "wholeseller_id": data[0].Wholeseller_id
    };
    axios
      .put(url, product_data)
      .then((result) => {
        setloader(false);
        props.toast.success("Updated Successfully");
        props.get_orders();
        props.handleClose();
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
      
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <select ref={status_ref}>
                  <option value="">Update the Status</option>
                  <option value="Payment Recieved">Payment Recieved</option>
                  <option value="Pending">Pending</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </Col>
            </Row>
            <br></br>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          {loader ? <Button variant="primary"><Spinner></Spinner></Button> :
          <Button variant="primary" onClick={updatehandler}>Save Changes</Button>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default StatusUpdateModal;
