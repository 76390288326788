import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";
import BASE_URL from '../Config';

function QuantityModal(props) {
  const navigate = useNavigate();
  
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [quantity, setquantity] = useState(1);
  const [flag, setflag] = useState(0);


  const quantity_ref = useRef("");

  useState(async () => {
    quantity_ref.current.value = props.item.quantity;
  })

  





  const local = localStorage.getItem("cartItems");
  const addqty = (id) => {
    props.data.map((item) => {
      if (item.id === id) {
        setquantity(quantity + 1);
        props.item.quantity++;

      }
    });
  };

  const subqty = (id) => {
    props.data.map((item) => {
      if (item.id === id) {
        if (item.quantity > 1) {
          setquantity(quantity - 1);
          props.item.quantity--;
        }
      }
    });
  };

  // const addtoCart = (item) => {
  //   // const existingCartItemIndex = cartItems.findIndex(
  //   //   (item) => item.id === item.id
  //   // );
  //   // const existingCartItem = items[existingCartItemIndex];
  //   let cart = [];
  //   for (const key in props.items) {
  //     const product_data = {
  //       id: props.items[key].id + 1,
  //       product_name: props.items[key].product_name,
  //       manufacturer: props.items[key].manufacturer,
  //       offer: props.items[key].offer,
  //       quantity: props.items[key].quantity,
  //       bonus: props.items[key].bonus,
  //       total_price: props.items[key].total_price,
  //     }
  //     cart.push(product_data);
  //   }

  //   dispatch({
  //     type: action_type.SET_CART_ITEMS,
  //     cartItems: cart,
  //   });

  //   console.log(cart);
  //   localStorage.setItem("cartItems", JSON.stringify(cart));
  //   cart.slice(0);

  // }

  // useEffect(() => {
  //   addtoCart();
  // }, [props.items]);

  const addItem = async (item) => {
    await props.setItems([...props.cartItems, item])
    props.handleClose();
  }



  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial"}}
    >
      <Modal show={props.show} onHide={props.handleClose} style={{marginTop:"50%"}}>
        <Container style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <Container className=" text-center text-sm">
              <div className=" font-bold text-xl mb-2">Select Quantity</div>
              <div className="flex gap-1 justify-center">
                <Button
                  onClick={() => subqty(props.item.id)}
                  className="qty-btn rounded w-8 h-7 bg-white text-black"
                >
                  <p className="mb-auto">-</p>
                </Button>
                <span className="qty-text rounded text-center h-7 w-16 bg-white">
                  <p className="text-base mt-1">{props.item.quantity}</p>
                </span>
                <Button
                  onClick={() => addqty(props.item.id)}
                  className="qty-btn rounded w-8 h-7 bg-white text-black"
                >
                  +
                </Button>
              </div>

            </Container>
          </Modal.Body>
          <Modal.Footer className="flex">
            <Button variant="success" onClick={() => addItem(props.item)}>
              Add
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </div>
  );
}
export default QuantityModal
