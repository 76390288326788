import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useStateValue } from "../../store/stateprovider";
import { useNavigate, useParams } from "react-router-dom";
import { action_type } from "../../store/reducer";
import BASE_URL from '../Config';


function CartSummary(props) {
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [OrderItems, setOrderItems] = useState([]);
  const [OrderData, setOrderData] = useState([]);
  const [loader, setloader] = useState(null);
  const [order_id, setOrder_id] = useState(null);
  const [wholeseller_data, setwholeseller_data] = useState({});
  const [tot, setTot] = useState(0);
  const { url } = useParams();
  const [success, setsuccess] = useState(null);
  const [id, setid] = useState();

  const navigate = useNavigate();


  // const user = JSON.parse(localStorage.getItem("user"));
  let items = [];

  useEffect(() => {
    const tableContainer = document.querySelector('.cart-scroll');

    // Check if the table overflows its container element
    if (tableContainer.scrollHeight > tableContainer.clientHeight) {
      // Show the scroll bar
      tableContainer.style.overflowY = 'scroll';
    }
  }, [items]);

  useEffect(() => {
    items = cartItems;
  }, [items]);
  useEffect(()=>{  
    document.addEventListener('keydown',keyDownHandler);
  
    return ()=>{
      document.removeEventListener('keydown',keyDownHandler);
    } //We need to return it as a dependency
  },[])
  
  const keyDownHandler = (event) =>{
    
  }



  const WholesellerData = async () => {
    const response_id = await fetch(`${BASE_URL}/WholesellerCategory/wholeseller/${url}`).catch(error=>{
      console.log("abc")
    });
    if(!response_id) {
      setloader(false);
      toast.error("Error Occured, Try again");
    }
    const Id = await response_id.text();
    const response = await fetch(`${BASE_URL}/WholesellerControllerSignUp/${Id}`).catch(error=>{
      console.log("abc")
    });
    return await response.json();
  }


  // const getWholesellerData = async () => {
  //   const wholeseller_data = await WholesellerData();
  //   setwholeseller_data(wholeseller_data)
  // }

  // useEffect(() => {
  //   getWholesellerData();
  // }, [])

  console.log(wholeseller_data);



  const clearCart = () => {
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: [],
    });
    localStorage.setItem("cartItems", JSON.stringify([]));
  };


  const handleCreateOrder = async () => {
    const wholeseller_data = await WholesellerData();
    const product_data = {
      retailer_id: user.id,
      retailer_name: user.fullName,
      total: tot,
      totalItems: cartItems.length,
      status: "Pending",
      seller_store: wholeseller_data.busniess_name,
      store: user.store,
      phone_no: user.phone,
      seller_phone_no: wholeseller_data.phone,
      address: user.address,
      wholeseller_id: wholeseller_data.id,
    };
    const response = await fetch(`${BASE_URL}/Orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(product_data),
    }).catch(error=>{
      console.log("abc")
    });
    const order_id = await response.text();
    setOrder_id(order_id);
    console.log(order_id)
    return order_id;
  };

  const Authentication_Request = async() =>{
    setloader(true);
    const body = {
      "api_key": "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2TVRBMk1UYzNMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuQ2NZbUlTMTNmTW1ydjRoQkZhOXBucXc2WDlPVGRldTVVb0lkRFlZU0F2U25LS2lRSDEzMnRtekdDYmNyZ0VEejI3WkxUdE5mT3haRGk3MnFTTjdaMnc=",
      "username":"3168481064",
      "password": "Logicunit&*9"
  }
    const response = await fetch(`https://pakistan.paymob.com/api/auth/tokens`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    }).catch(error=>{
      console.log("Error Occured")
      setloader(false);
    });
    const data = await response.json();
    console.log(data.token);
    localStorage.setItem("token",data.token);

    
    Order_Registration_Request();
    
  }

  const Order_Registration_Request = async() =>{
    const token = localStorage.getItem("token");
    if(token){
      const order_id = Math.floor(Math.random() * 1000);
    const body = {
      "auth_token":  token,
      "delivery_needed": "false",
      "amount_cents": "100",
      "currency": "PKR",
      "merchant_order_id": order_id,
      "items": [
        {
            "name": "ASC1515",
            "amount_cents": "500000",
            "description": "Smart Watch",
            "quantity": "1"
        },
        { 
            "name": "ERT6565",
            "amount_cents": "200000",
            "description": "Power Bank",
            "quantity": "1"
        }
        ],
      "shipping_data": {
        "apartment": "803", 
        "email": "claudette09@exa.com", 
        "floor": "42", 
        "first_name": "Clifford", 
        "street": "Ethan Land", 
        "building": "8028", 
        "phone_number": "+86(8)9135210487", 
        "postal_code": "01898", 
         "extra_description": "8 Ram , 128 Giga",
        "city": "Jaskolskiburgh", 
        "country": "CR", 
        "last_name": "Nicolas", 
        "state": "Utah"
      },
        "shipping_details": {
            "notes" : " test",
            "number_of_packages": 1,
            "weight" : 1,
            "weight_unit" : "Kilogram",
            "length" : 1,
            "width" :1,
            "height" :1,
            "contents" : "product of some sorts"
        }
    }
    const response = await fetch(`https://pakistan.paymob.com/api/ecommerce/orders`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    }).catch(error=>{
      console.log("Error Occured");
      setloader(false);
    });
    const data = await response.json();
    console.log(data);
    localStorage.setItem("order_id",data.id);
  }
  else{
    console.log("Error Occured");
  }
  Payment_key_request();
    }

  const Payment_key_request =async() =>{
    const token = localStorage.getItem("token");
    const order_id =  localStorage.getItem("order_id");

    const body = {
      "auth_token": `${token}`,
      "amount_cents": "100", 
      "expiration": 3600, 
      "order_id": `${order_id}`,
      "billing_data": {
        "apartment": "803", 
        "email": "claudette09@exa.com", 
        "floor": "42", 
        "first_name": "Clifford", 
        "street": "Ethan Land", 
        "building": "8028", 
        "phone_number": "+86(8)9135210487", 
        "shipping_method": "PKG", 
        "postal_code": "01898", 
        "city": "Jaskolskiburgh", 
        "country": "CR", 
        "last_name": "Nicolas", 
        "state": "Utah"
      }, 
      "currency": "PKR", 
      "integration_id": 116179,
      "lock_order_when_paid": "false",
    }    
    const response = await fetch(`https://pakistan.paymob.com/api/acceptance/payment_keys`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    }).catch(error=>{
      console.log("Error Occured")
    });
    const data = await response.json();
    console.log(data);
    localStorage.setItem("token2",  data.token);
    setloader(false);

    navigate(`//pakistan.paymob.com/api/acceptance/iframes/133450?payment_token=${data.token}`);

  }
    

  const Buy_Now = async (event) => {
    setloader(true);
    event.preventDefault();
    const order_id = await handleCreateOrder();
    for (const key in cartItems) {
      const product_data = {
        productName: cartItems[key].product_name,
        quantity: cartItems[key].quantity,
        price: cartItems[key].total_price,
        orderId: order_id,
      };

      const response = await fetch(`${BASE_URL}/Orders/${order_id}/items`, {
        method: "POST",
        body: JSON.stringify(product_data),
        headers: { "Content-Type": "application/json" },
      }).catch(error=>{
        console.log("abc")
      });
      const data = await response.json();
      console.log(data);
    }

    if (cartItems.length >= 1) {
      clearCart();
      // toast.success("Order Placed Succesfully");
      // navigate(`/ordersRetailer/${store}/${id}`);
      navigate(`/orderplacedsucessfully/${url}`);
    }
    setloader(false);
  };


  useEffect(() => {
    let totalPrice = cartItems.reduce(function (accumulator, item) {
      return accumulator + item.quantity * item.total_price;
    }, 0);
    setTot(totalPrice);
  }, [tot, cartItems, props.addqty, props.subqty]);

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Container style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
          <Modal.Header>
            <h1 className="text-2xl">Order Confirmation</h1>
            {OrderData && OrderData.map((item, index) => {
              <Container className="Poppins text-xs">
                {/* <Row key={index}> 
                  <Col>
                    <div>Order No</div>
                    <p className=" font-semibold">{item.id}</p>
                  </Col>

                  <Col>
                    <div>url Name</div>
                    <p>{item.store}</p>
                  </Col>

                  <Col>
                    <div>Contact Number</div>
                    <p>{item.phone_no}</p>
                  </Col>
                </Row>
                <Row>
                  <div>Address</div>
                  <p>{item.address}</p>
                </Row> */}
              </Container>
            })}
          </Modal.Header>
          <Modal.Body>
            <Container className="cart-summary">
              <div className=" cart-scroll max-h-72">
                <Table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Unit Price</th>
                      <th>Qty</th>
                      <th>T.P</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems && cartItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.product_name}</td>
                        <td>Rs. {item.total_price}/-</td>
                        <td>{item.quantity}</td>
                        <td>Rs. {item.total_price * item.quantity}/-</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                  <tr className="bg-white font-semibold">
                    <td colSpan={3}>Subtotal</td>
                    <td colSpan={2} style={{width:"20rem"}} className="font-medium">Rs. {tot}/-</td>
                  </tr>
                </tfoot>
                  {cartItems.length === 0 && <p>No Data Found</p>}
                </Table>
              </div>
              <br></br>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {loader ? <Button variant="success"><Spinner size="sm"></Spinner></Button> :
              <Button variant="success" onClick={Buy_Now}>Place Order</Button>
            }
            <Button variant="secondary" onClick={props.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </div>
  );
}
export default CartSummary;

