import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateValue } from "../../store/stateprovider";
import "../Style/header.css";
import { useEffect, useState } from "react";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { action_type } from "../../store/reducer";

function AdminHeader() {
  // const name = localStorage.getItem("user");  
  const[{user}, dispatch] = useStateValue();
  const name = user;

  const navigate = useNavigate();
  const path =  window.location.pathname;
  const [IsRetailer, setIsRetailer] = useState(false);

  const [ActiveHomeLink, setActiveHomeLink] = useState(false);
  const [ActiveOrderLink, setActiveOrderLink] = useState(false);
  const [ActiveRegLink, setActiveRegLink] = useState(false);
  const [ActiveProfileLink, setActiveProfileLink] = useState(false)
  


  useEffect(()=>{
    if(path === "/ViewRetailers"){
      setActiveHomeLink(false);
      setActiveOrderLink(true);
      setActiveRegLink(false);
      setActiveProfileLink(false);
    }
    else if(path === "/ViewWholesellers"){
      setActiveRegLink(true);
      setActiveHomeLink(false);
      setActiveOrderLink(false);
      setActiveProfileLink(false);

    }
    else if(path === "/profile"){
      setActiveProfileLink(true);
      setActiveRegLink(false);
      setActiveHomeLink(false);
      setActiveOrderLink(false);

    }
    else if(path === "/AdminPanel"){
      setActiveRegLink(false);
      setActiveOrderLink(false);
      setActiveHomeLink(true);
      setActiveProfileLink(false);

    }
  },[navigate])

  useEffect(()=>{
    if(name && name.type === "retailer"){
      setIsRetailer(true);
    }
  },[])

  

  const logout = () => {
    // localStorage.removeItem('user');
    Cookies.remove("user");
    dispatch({
      type: action_type.SET_USER,
      user: "",
      //if you look at the console.log(response) you will see that providerData is an array
    });
    navigate("/");
    
    // window.location.reload();
  }
  
  return (
    <header>
      <Navbar expand="lg" className="Poppins z-50">
        <Container className="container">
        
        <Navbar.Brand className="text-white absolute top-2 lg:top-5 left-[5%] md:left-[3%] font-semibold">Admin Panel</Navbar.Brand>
          <Navbar.Toggle className="absolute top-2 right-3 z-50" style={{ marginLeft: "82%" }} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className=" z-50">
            <Nav className="gap-14 navbar">
              <Nav.Link onClick={() => navigate(`/AdminPanel`)} className={ActiveHomeLink ? `link-active` : `link`}>
                Products
              </Nav.Link>

                <Nav.Link  onClick={() => navigate(`/ViewRetailers`)} className={ActiveOrderLink ? `link-active` : `link`}>
                View Retailers
              </Nav.Link>

                <Nav.Link onClick={()=>navigate("/ViewWholesellers")} className={ActiveRegLink ? `link-active` : `link`}>
                  View Wholesellers
                </Nav.Link>
              
              <Navbar.Text>
              {name ? (
                  <button onClick={logout} className="logout-button">Logout</button>
                ) : (
                  <button onClick={() => navigate('/')} className="login-button">Login</button>
                )}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
export default AdminHeader;
