import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateValue } from "../../store/stateprovider";
import "../Style/header.css"
import { useEffect, useState } from "react";
import { FaShoppingCart, FaBars } from 'react-icons/fa';
import { motion } from "framer-motion";
import BASE_URL from '../Config';
import Cookies from "js-cookie";
import { action_type } from "../../store/reducer";
import SellerInfoModal from "./SellerInfoModal";
import info from "../../images/info_icon.svg"


function HeaderRetailer(props) {
  // const data = localStorage.getItem("user");
  const [{ user }] = useStateValue();
  const name = user;
  const { url } = useParams();
  const navigate = useNavigate();
  const path = window.location.pathname;
  const [ActiveHomeLink, setActiveHomeLink] = useState(false);
  const [ActiveOrderLink, setActiveOrderLink] = useState(false);
  const [ActiveProfileLink, setActiveProfileLink] = useState(false);
  const [ActiveRegLink, setActiveRegLink] = useState(false);
  const [Store, setstore] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [message, setMessage] = useState('');
  const [{ cartItems }, dispatch] = useStateValue();
  const [tot, setTot] = useState(0);
  const [wholeseller_data, setwholeseller_data] = useState();


  let items = [];

  const handleChildMessage = () => {
    props.setMessage();
  }

  const SellerInfoHandler = () => {
    props.set_seller_info()
  }



  useEffect(() => {
    items = cartItems;
  }, [items]);

  useEffect(() => {
    let totalPrice = cartItems.reduce(function (accumulator, item) {
      return accumulator + item.quantity * item.total_price;
    }, 0);
    setTot(totalPrice);
  }, [tot, cartItems, props.addqty, props.subqty]);

  // useEffect(()=>{
  //   try{
  //   setstore(store.replace(/ /g,"%20"));
  //   console.log(Store);
  //   }
  //   catch{
  //     console.log('');
  //   }
  // },[navigate])




  // useEffect(async()=>{
  //   const response = await fetch(`${BASE_URL}/WholesellerControllerSignUp/${id}`);
  //   const Data = await response.json();
  //   setstore(Data.busniess_name);
  // },[])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000); // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (path.includes("/ordersRetailer")) {
      setActiveOrderLink(true);
    }
    else if (path.includes("/retailerProfile")) {
      setActiveProfileLink(true);
    }
    else if (path === `/Registration`) {
      setActiveRegLink(true);
    }
    else if (path.includes('/Home')) {
      setActiveHomeLink(true);
    }
  }, [])

  useEffect(() => {

    const setSTORE = async () => {
      if (url) {
        const response_wholeseller = await fetch(`${BASE_URL}/WholesellerCategory/url/${url}`);
        const data = await response_wholeseller.json();
        setstore(data.busniess_name);
      }
    }
    setSTORE();
  }, [])


  const logout = () => {
    // localStorage.removeItem('user');
    Cookies.remove("user");
    dispatch({
      type: action_type.SET_USER,
      user: "",
      //if you look at the console.log(response) you will see that providerData is an array
    });
    navigate("/");
    // window.location.reload();

  }

  // const navigate_Orders = () => {
  //   if (url !== "undefined") {
  //     navigate(`/ordersRetailer/${url}`);
  //   }
  //   else {
  //     navigate(`/ordersRetailer`);
  //   }
  // }

  // const navigate_Profile = () => {
  //   if (url !== "undefined") {
  //     navigate(`/retailerProfile/${url}`);
  //   }
  //   else {
  //     navigate(`/retailerProfile`);
  //   }
  // }


  return (
    <header>
      {/* <Navbar expand="lg">
        <Container>
          <Navbar.Brand className="brand-header">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{ marginLeft: "62%" }} className="gap-14">
              <Nav.Link onClick={() => navigate(`/home/${id}`)} className={ActiveHomeLink ? `link-active` : `link`}>
                Home
              </Nav.Link>
              <Nav.Link onClick={() => navigate(`/ordersRetailer/${id}`)} className={ActiveOrderLink ? `link-active` : `link`}>
                Orders
              </Nav.Link>
              {name ? (<Nav.Link className="link">
                Profile
              </Nav.Link>) : (
                <Nav.Link className="link">
                  Register
                </Nav.Link>
              )}
              <Navbar.Text>
                {name ? (
                  <div onClick={logout} className="logout-button">
                    <p className="logout-link-text">Logout</p>
                  </div>
                ) : (
                  <div onClick={() => navigate('/SignIn')} className="login-button">
                    <p className="login-link-text">Login</p>
                  </div>
                )}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <Navbar expand="lg" className="Poppins">
        <Container className="container">
          {props.store ? <Navbar.Brand className="text-white absolute top-2 lg:top-5 left-[5%] md:left-[3%] font-semibold" >{props.store} </Navbar.Brand> :
            <div className="flex absolute top-2 lg:top-5 left-[5%] md:left-[3%]">
                <Navbar.Brand className="text-white font-semibold">{Store}</Navbar.Brand>
                <img src={info} onClick={SellerInfoHandler} className="text-white w-[15%] h-[15%] font-semibold cursor-pointer mt-auto mb-auto ml-[-8px]" ></img>
            </div>
          }
          {isMobile && !props.hidden && <Nav onClick={handleChildMessage} className="flex -mr-[32%] cursor-pointer">
            <Nav.Link style={{ top: "0.1rem", paddingTop: "24px" }} className="absolute right-[39%]" href="#cart"><FaShoppingCart /></Nav.Link>
            <div className=" top-1 right-[35%] ml-2 absolute w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center">
              <p className="text-xs text-white font-semibold pt-3">
                {cartItems.length}
              </p>
            </div>
            <div className="absolute top-1 right-[22%] flex items-center justify-center pt-1">
              <p className="Poppins rounded-xl text-sm  font-semibold mt-3 ">
                {tot}/-
              </p>
            </div>
          </Nav>}
          <Navbar.Toggle className="absolute top-2 right-5 z-50" aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className=" ml-[20%]" id="basic-navbar-nav">
            <Nav className="gap-9 md:gap-14 navbar">
              {!name ? <Nav.Link disabled onClick={() => navigate(`/Home`)} className={ActiveHomeLink ? `link-active` : `link`}>
                
              </Nav.Link> :
                <Nav.Link onClick={() => navigate(`/Home`)} className={ActiveHomeLink ? `link-active` : `link`}>
                  Home
                </Nav.Link>
              }
              {!name ? <Nav.Link disabled onClick={() => navigate(`/ordersRetailer`)} className={ActiveOrderLink ? `link-active` : `link`}>
                
              </Nav.Link> :
                <Nav.Link onClick={() => navigate(`/ordersRetailer`)} className={ActiveOrderLink ? `link-active` : `link`}>
                  My Orders
                </Nav.Link>
              }
              {name ? (<Nav.Link onClick={() => navigate(`/retailerProfile`)} className={ActiveProfileLink ? `link-active` : `link`}>
                Profile
              </Nav.Link>) : (
                <Nav.Link className={ActiveRegLink ? `link-active` : `link`} onClick={() => navigate(`/Registration/${url}`)}>
                  Register
                </Nav.Link>
              )}
              <Navbar.Text>
                {name ? (
                  <button onClick={logout} className="logout-button">Logout</button>
                ) : (
                  <button onClick={() => navigate('/')} className="login-button">Login</button>
                )}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>

  );
}
export default HeaderRetailer;
