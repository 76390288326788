import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "../Style/Checkout.css";
import "../Style/otpVerification.css";
import axios from "axios";
import HeaderRetailer from "./HeaderRetailer";
import BASE_URL from '../Config';
import Cookies from "js-cookie";

function RetatilerSetNewPin() {
    const digit_1 = useRef("");
    const digit_2 = useRef("");
    const digit_3 = useRef("");
    const digit_4 = useRef("");

    // const phone = localStorage.getItem('phone');
    const phone = Cookies.get('phone');

    const { url,id } = useParams();
    const navigate = useNavigate();
    const [loader, setloader] = useState(false);

    useEffect(() => {
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input, index) => {
          input.addEventListener('input', () => {
            if (input.value.length === input.maxLength) {
              if (index === inputs.length - 1) {
                input.blur();
              }
              else{
                inputs[index + 1].focus();
              }
            }
            if (input.value.length !== input.maxLength) {
                inputs[index - 1].focus();
    
            }
          })
        })
      },[])


    const setpin = async () => {
        setloader(true);
        const response_retailer = await fetch(`${BASE_URL}/Retailers/OTP/${phone}`);
        const response_wholeseller = await fetch(`${BASE_URL}/WholesellerControllerSignUp/OTP/${phone}`);
        const password = digit_1.current.value + digit_2.current.value + digit_3.current.value + digit_4.current.value;
        if (response_retailer.status === 200) {
            const Data_retailer = await response_retailer.json();
            const retailer_data = {
                "id": Data_retailer.id,
                "fullName": Data_retailer.fullName,
                "email": Data_retailer.email,
                "phone": Data_retailer.phone,
                "store": Data_retailer.store,
                "address": Data_retailer.address,
                "password": password,
            };
            if (Data_retailer.password === password || !password) {
                toast.error("Write a new Password");
            }
            else {
                const url = `${BASE_URL}/Retailers/${Data_retailer.id}`;
                axios
                    .put(url, retailer_data)
                    .then((result) => {
                        toast.success("Updated Successfully");
                        // localStorage.removeItem("OTP");
                        Cookies.remove("OTP");
                        navigate(`/pinVerification/${id}`);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        if (response_wholeseller.status === 200) {
            const Data_wholeseller = await response_wholeseller.json();
            const wholeseller_data = {
                "id": Data_wholeseller.id,
                "full_name": Data_wholeseller.full_name,
                "busniess_name": Data_wholeseller.busniess_name,
                "address": Data_wholeseller.address,
                "email": Data_wholeseller.email,
                "phone": Data_wholeseller.phone,
                "password": password
            };
            if (Data_wholeseller.password === password || !password) {
                toast.error("Write a new Password");
            }
            else {
                const url = `${BASE_URL}/WholesellerControllerSignUp/${Data_wholeseller.id}`;
                axios
                    .put(url, wholeseller_data)
                    .then((result) => {
                        toast.success("Updated Successfully");
                        // localStorage.removeItem("OTP");
                        Cookies.remove("OTP");
                        navigate(`/pinVerification/${url}`);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        setloader(false);
    }


    return (
        <Fragment className="overflow-hidden">
            <ToastContainer></ToastContainer>
            <HeaderRetailer hidden={true}></HeaderRetailer>
            <Row style={{ marginLeft: "2%" }}>
                <Col md={9} xs={7}>
                    <div className="breadcrum flex md:gap-3 sm:gap-9 ">
                        {/* <p>Products</p>
            <div className="dot"></div>
            <p>Checkout/Register</p>
            <div className="dot"></div>
            <p className="active">OTP</p>
            <div className="dot"></div>
            <p>Set Passcode</p> */}
                    </div>

                    <div className="breadcrum flex">
                        <h3 className="text-2xl font-bold text-black">Set a New Pin</h3>
                        {/* <div style={{marginLeft:"20%"}} className="flex gap-2 mt-1">
              <h6 className="text-black">Already have an account?</h6>
              <p className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
                    </div>
                    <div>
                        <p className="text-sm font-medium flex gap-1">Enter a new four digit Pin</p>
                    </div>
                    <Form>
                        <Row style={{ width: "70%" }} className="passcode mt-4">
                            <div className="flex  gap-3">
                                <Col xs={6} md={1}>
                                    <Form.Control type="password" minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333" }} ref={digit_1} className="mb-4 text-center Poppins" ></Form.Control>
                                </Col>
                                <Col xs={6} md={1}>
                                    <Form.Control type="password" minLength={1} maxLength={1} id="input2" style={{ border: "1px solid #333333" }} ref={digit_2} className="mb-4 text-center Poppins" ></Form.Control>
                                </Col>
                                <Col xs={6} md={1}>
                                    <Form.Control type="password" minLength={1} maxLength={1} id="input3" style={{ border: "1px solid #333333" }} ref={digit_3} className="mb-4 text-center Poppins" ></Form.Control>
                                </Col>
                                <Col xs={6} md={1}>
                                    <Form.Control type="password" minLength={1} maxLength={1} id="input4" style={{ border: "1px solid #333333" }} ref={digit_4} className="mb-4 text-center Poppins" ></Form.Control>
                                </Col>
                            </div>
                        </Row>
                        {loader ? <Button className="submit-button w-[60%] sm:w-[40%] md:w-[32%]"><Spinner size="sm"></Spinner></Button> : 
                        <Button onClick={setpin} className="submit-button w-[60%] sm:w-[40%] md:w-[32%]">Save</Button>
                        }
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
}
export default RetatilerSetNewPin;
