import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from '../Config';
import { useReactToPrint } from "react-to-print";
import logo from "../../images/bookmyorder Icon White-01.svg";

function OrderSummary(props) {
  const [OrderItems, setOrderItems] = useState([]);
  const [OrderData, setOrderData] = useState([]);
  const [loader, setloader] = useState(null);
  const [ismapping, setismapping] = useState(true);

  
  

  const get_data = async () => {
    setloader(true);
    const response = await fetch(
      `${BASE_URL}/Orders/${props.data.id}/items`
    ).catch(error=>{
      console.log("abc")
    });
    if(!response){
      toast.error("Error Occured. Please try again");
      setloader(false);
    }

    if (response.status === 200) {
      const Data = await response.json();
      const loaded_data = [];
      for (const key in Data) {
        loaded_data.push({
          id: Data[key].id,
          productName: Data[key].productName,
          quantity: Data[key].quantity,
          price: Data[key].price,
        });
      }
      setOrderItems(loaded_data);
      if(props.print){
        setismapping(true);
      }
      
    }
    if(!response){
      toast.error("Error Occured. Please try again");
      setloader(false);
    }
    setloader(false);
  };

  const get_orderData = async () => {
    const response_order = await fetch(
      `${BASE_URL}/Orders/${props.data.id}`
    );
    if (response_order.status === 200) {
      const loaded_data = [];
      const Data = await response_order.json()
      for (const key in Data) {
        loaded_data.push({
          id: Data[key].id,
          retailer_name: Data[key].retailer_name,
          store: Data[key].store,
          address: Data[key].address,
        });
        setOrderData(loaded_data);
      }
      setloader(false)
    }

  }

  const printref = useRef();

  const handlePrint = useReactToPrint({
    content : () => printref.current,
  })

  

  // const get_Order = async () => {
  //   const response = await fetch(
  //     `${BASE_URL}/Orders/${props.id}`
  //   );
  //   if (response.status === 200) {
  //     const Data = await response.json();
  //     setOrderData(Data);
  //     const loaded_data = [];
  //     for (const key in Data) {
  //       loaded_data.push({
  //         id: Data[key].id,
  //         store: Data[key].store,
  //         phone_no: Data[key].phone_no,
  //         address: Data[key].address
  //       });
  //     }
  //     setOrderData(loaded_data);
  //   }
  // }

  useEffect(() => {
  const a = get_data();
    const timer = setInterval(() => {
      setismapping(false);  
    }, 1000);
    
  }, [])

  useEffect(()=>{
    if(props.print && !ismapping){
      setloader(true);
      handlePrint();
      const timer = setInterval(() => {
        props.handleClose()
      }, 5000);
    }
  },[ismapping])

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
      
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Container  style={{ backgroundColor: "#EFEFEF", overflow: "hidden" }}>
         {!props.print && <div>
          <Modal.Header>
            <Container className=" mt-2 Poppins text-[10px] ">
            <Row>
                <Col>
                  <div>Order No</div>
                  <p className=" text-xs md:text-sm font-semibold">{props.data.id}</p>
                </Col>

                <Col>
                  <div >Store Name</div>
                  <p className="text-xs md:text-sm font-semibold">{props.data.store}</p>
                </Col>

                <Col>
                  <div>Contact </div>
                  <p className="text-xs md:text-sm font-semibold">{props.data.phone_no}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                <div>Address</div>
                <p className="text-xs md:text-sm font-semibold">{props.data.address}</p>
                </Col>
                <Col>
                <div>Order Date</div>
                <p className="text-xs md:text-sm font-semibold">{props.data.order_date}</p>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container className="cart-summary">
              <div className=" max-h-72 overflow-y-scroll">
                <Table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price/unit</th>
                      <th>Qty</th>
                      <th>T.P</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderItems && OrderItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>Rs. {item.price}/-</td>
                        <td>{item.quantity}</td>
                        <td>Rs. {item.price * item.quantity}/-</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                  <tr className="bg-white font-semibold">
                    <td colSpan={3}>Subtotal</td>
                    <td colSpan={2} style={{width:"20rem"}} className=" font-medium">Rs. {props.data.total}/-</td>
                  </tr>
                </tfoot>
                  {OrderItems.length === 0 && <p>No Data Found</p>}
                </Table>
              </div>
            </Container>
          </Modal.Body>
        </div>}

        {props.print && <div ref={printref}>
          <Modal.Header>
            <Container className="mt-2 Poppins text-[11px]">
            <div className="mb-5 justify-center flex gap-3">
              <img src={logo} className="w-[15%]"></img>
              <span className="text-2xl font-medium mt-[6%]">Bookmyorder</span>
              </div>
            <div className="text-center flex mr-5">
                <Col>
                  <div>Order No</div>
                  <p className=" text-sm font-semibold">{props.data.id}</p>
                </Col>

                <Col>
                  <div >Store Name</div>
                  <p className=" text-sm font-semibold">{props.data.store}</p>
                </Col>

                <Col>
                  <div>Contact </div>
                  <p className=" text-sm font-semibold">{props.data.phone_no}</p>
                </Col>
              </div>
              <div className="ml-[12.5%]">
                <div>Address</div>
                <p className=" text-sm font-semibold">{props.data.address}</p>
              </div>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container style={{width:"90%"}} className="cart-summary">
              <div className=" max-h-72 overflow-y-scroll">
                <Table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price/unit</th>
                      <th>Qty</th>
                      <th>T.P</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderItems && OrderItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>Rs. {item.price}/-</td>
                        <td>{item.quantity}</td>
                        <td>Rs. {item.price * item.quantity}/-</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                  <tr className="bg-white font-semibold">
                    <td colSpan={3}>Subtotal</td>
                    <td colSpan={2} style={{width:"20rem"}} className="font-medium">Rs. {props.data.total}/-</td>
                  </tr>
                </tfoot>
                  {OrderItems.length === 0 && <p>No Data Found</p>}
                </Table>
              </div>
            </Container>
          </Modal.Body>

        </div>}
          <Modal.Footer>
            {loader ? <Button variant="secondary"><Spinner></Spinner></Button> :
            <Button variant="secondary" onClick={props.handleClose}>Close</Button>
            }
          </Modal.Footer>
        </Container>
      </Modal>
    </div>
  );
  
}
export default OrderSummary;
