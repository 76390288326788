import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import HeaderRetailer from "./HeaderRetailer";
import Cart from "./Cart";
import "../Style/Checkout.css";
import CartMobile from "./CartMobile";
import BASE_URL from '../Config';
import { useStateValue } from "../../store/stateprovider";
import Cookies from "js-cookie";

function PhoneVerification() {
  const phone_ref = useRef("");
  const [loader, setloader] = useState(false);
  const [user_data, setData] = useState([]);
  const navigate = useNavigate();
  const { url } = useParams();
  // const user = localStorage.getItem("user");
  const [{ user, cartItems }, dispatch] = useStateValue();
  const [errors, seterrors] = useState(null);
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    } //We need to return it as a dependency
  }, [])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      check_phone(event);
    }
  }

  const CloseHandler = () => {
    setcartmobile(false);
  }

  const setMessage = () => {
    setcartmobile(true);
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setShowCart(false);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initialValues = {
    phone: "",
  };

  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!phone_ref.current.value) {
      errors.phone = "Enter Your Phone No";
    } else if (!phone_regex.test(phone_ref.current.value)) {
      errors.phone = "Enter a Valid phone no";
    }
    return errors;
  };

  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});

  const SetPhoneInLocalStorage = (phone_no) => {
    const phone = localStorage.getItem("phone");
    if (phone) {
      localStorage.removeItem("phone");
    }
    localStorage.setItem("phone", phone_no);
  };

  const SetPhoneInCookies = (phone_no) => {
    const phone = Cookies.get("phone");
    if (phone) {
      Cookies.remove("phone")
    }
    Cookies.set('phone', phone_no, { expires: 2 });
  }

  const SetOTPInLocalStorage = (OTP) => {
    const otp = localStorage.getItem("OTP");
    if (otp) {
      localStorage.removeItem("OTP");
    }
    localStorage.setItem("OTP", OTP);
  }


  const SetOTPInCookies = (OTP) => {
    const otp = Cookies.get("OTP");
    if (otp) {
      Cookies.remove("OTP");
    }
    Cookies.set("OTP", OTP, { expires: 2 });
  }

  const send_code = async (phone) => {
    var digits = '0123456789';
    var OTP = '';
    let phone_no_otp = phone_ref.current.value;
    let phone_no = phone_ref.current.value;
    
    if (phone_no.charAt(0) === "+92") {
        phone_no = "0" + phone_no.slice(1);
    }
    if (phone_no.charAt(0) === "0") {
        phone_no_otp = "92" + phone_no.slice(1);
    }
    for (var i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    
    // const response = await fetch(`${BASE_URL}/OTP/${phone}/${OTP}`);
    // const Data = await response.json();
    try {
      const apiKey = "LKN6WHCDD0CMKDXC6DY4";
      const phoneNumber = `${phone_no_otp}`;
      const message = `Your OTP is ${OTP}`;

      SetOTPInCookies(OTP);
      const response = await fetch(`https://panel.rapiwha.com/send_message.php?apikey=${apiKey}&number=${phoneNumber}&text=${message}`, {
        method: 'GET'
      });
      const Data = await response.json();
      console.log(Data);
      if (Data.success === true) {
        SetPhoneInLocalStorage(phone_no);
        navigate(`/OTPVerification/${url}`);
      }
      else{
        return toast.error(`${Data.description}`);
      }
    }
    catch (error) {
      toast.error("Please Try Again");
      console.log(error);
    }
    
  }

  useEffect(() => {
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0) {
      seterrors(false);
    }
    if (errors_count > 0) {
      seterrors(true);
    }
  }, [formError, setFormErrors])

  const check_phone = async (event) => {
    setloader(true);
    event.preventDefault();
    setFormErrors(validate(formValues));
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return toast.error("Please Enter all the required Fields");
    }
    if (cartItems.length === 0) {
      setloader(false);
      return toast.error("Cart is Empty");
    }
    const errors_count = Object.keys(formError).length;
    let phone_no = phone_ref.current.value;
    if (phone_no.charAt(0) === "+92") {
      phone_no = "0" + phone_no.slice(1);
    }
    const response_wholeseller = await fetch(`${BASE_URL}/WholesellerControllerSignUp/OTP/${phone_no}`).catch(error => {
      console.log("abc")
    });

    if (response_wholeseller.status === 200) {
      setloader(false);
      return toast.error("This Number is Registered as Wholeseller")
    }



    const response = await fetch(`${BASE_URL}/OTP/${phone_no}`).catch(error => {
      console.log("abc")
    });



    if (!response) {
      setloader(false);
      toast.error("Error Occured. Please try again");
    }

    if (response.status === 200) {
      SetPhoneInCookies(phone_no)
      navigate(`/PINVerification/${url}`);
    }

    if (!errors && response.status !== 200) {
      SetPhoneInCookies(phone_no);
      //send_code(phone_no);
      
      navigate(`/RetailerSignupCheckout/${url}`)
    }
    setloader(false);
  };

  return (
    <div style={{ maxHeight: "100vh" }} className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer setMessage={setMessage}></HeaderRetailer>
      <Row style={{ marginLeft: "2.45%" }}>
        <Col md={9} xs={11}>
          <div className="flex mt-3">
            <div className="mt-2 mr-[10%] ">
              <h3 className="text-[17px] sm:text-base md:text-2xl font-bold">Enter your phone number</h3>
              <p muted className=" text-[10px] sm:text-xs md:text-sm Poppins text-gray-600 font-medium">
                We will send you the 4 digit verification code, if you will be new user
              </p>
            </div>
            {/* {!user && (
              <div className="mt-2 md:mt-4 flex-col md:flex-row xl:flex gap-1 Poppins text-xs sm:text-sm md:text-base">
                <h6 className="text-black text-xs sm:text-sm md:text-sm">Already have an account?</h6>
                <p onClick={() => navigate(`/retailerlogin/${url}`)} className="text-xs sm:text-sm md:text-sm text-blue-500 cursor-pointer hover:underline">
                  Login
                </p>
              </div>
            )} */}
          </div>
          <Form>
            <Form.Group className="mt-4 mb-4 w-[60%] sm:w-[45%] md:w-[37%] xl:w-[40%]">
              <Form.Control
                type="number"
                ref={phone_ref}
                style={{ border: "1px solid #333333" }}
                className="Poppins"
                onChange={changehandler}
              ></Form.Control>
              <Form.Text muted>
                <p className="text-xs text-red-500 absolute">
                  {formError.phone}
                </p>
              </Form.Text>
            </Form.Group>
            <div className="flex gap-3">
              {loader ? <Button
                style={{ width: "31%" }}
                className="submit-button w-[60%] sm:w-[40%] md:w-[32%]"
              >
                <Spinner size="sm"></Spinner>
              </Button> :
                <Button
                  style={{ width: "31%" }}
                  onClick={check_phone}
                  className="submit-button w-[60%] sm:w-[40%] md:w-[32%]"
                >
                  Continue
                </Button>
              }
            </div>
          </Form>
        </Col>
        {showCart && <Col md={3} >
          <Cart
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
        {cartmobile && <CartMobile
          CloseHandler={CloseHandler}
        >
        </CartMobile>}
      </Col>
    </div>
  );
}
export default PhoneVerification;
