import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRadio,
  MDBTextArea,
  MDBValidation
}
from 'mdb-react-ui-kit';
import BASE_URL from '../Config';

function WholesellerSignUp() {
  const name_ref = useRef("");
  const address_ref = useRef("");
  const email_ref = useRef("");
  const phone_ref = useRef("");
  const password_ref = useRef("");
  const confirmPassword_ref = useRef("");

  const initialValues = {name: "", address : "", email : "", phone:"", password:"", confirm_password: ""};
  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});
  

  const [IsSignup, setisSignUp] = useState(false);
  const [error, seterror] = useState(false);
  const navigate = useNavigate();
  
  
  //making validations 
  const changehandler = (e) =>{
    const {name, value} = e.target
    setformValues({...formValues, [name]: value});
  }

  const validate = () =>{
    const errors = {}
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if(!name_ref.current.value){
      errors.name = "Busniess Name is Required";
    }
    else if(name_ref.current.value.length < 5){
      errors.name = "Busniess should consists of atleast 5 characters"
    }

    if(!address_ref.current.value){
      errors.address = "Address is Required";
    }
    else if(address_ref.current.value.length > 50 && address_ref.current.value.length < 10){
      errors.address = "Address should consists of maximum 50 characters";
    }

    if(!email_ref.current.value){
      errors.email = "Email is Required";
    }
    else if(!regex.test(email_ref.current.value)){
      errors.email = "Enter a Valid Email address";
    }

    if(!phone_ref.current.value){
      errors.phone = "Phone No is Required";
    }
    else if(!phone_regex.test(phone_ref.current.value)){
      errors.phone = "Enter a Valid Phone No";
    }

    if(!password_ref.current.value){
      errors.password = "Password is Required";
    }
    else if(password_ref.current.value.length < 3){
      errors.password = "Password should be of atleast 3 characters"
    }

    if(!confirmPassword_ref.current.value || confirmPassword_ref.current.value !== password_ref.current.value){
      errors.ConfirmPassword = "Passwords does'nt match";
    }
    return errors;
  }

  const addwholeseller = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0 ) {
    const wholeseller_data = {
      busniess_name: name_ref.current.value,
      address: address_ref.current.value,
      email: email_ref.current.value,
      phone: phone_ref.current.value,
      password: password_ref.current.value,
    };

    const response = await fetch(
      `${BASE_URL}/WholesellerControllerSignUp`,
      {
        method: "POST",
        body: JSON.stringify(wholeseller_data),
        headers: { "Content-Type": "application/json" },
      }
    ).catch(error=>{
      console.log("abc")
    });
    const data = await response.json();
    console.log(data);
    Clear();
    toast.success("SignUp Successful");
    navigate("/");
    }
  };

  const Clear = () => {
    name_ref.current.value = "";
    address_ref.current.value = "";
    email_ref.current.value = "";
    phone_ref.current.value = "";
    password_ref.current.value = "";
    confirmPassword_ref.current.value = "";
  };

  return (
    <div className="bg-info" style={{height: '100vh'}}>
      <ToastContainer></ToastContainer>
      <MDBContainer style={{fontFamily:'Poppins'}}>
<MDBRow className='justify-content-center align-items-center max-w-3xl m-auto'>

  <MDBCard className="mt-5">
    <MDBCardBody className='px-4'>

      <h3 className="fw-bold mb-4 pb-2 pb-md-0">Registration Form</h3>

      <MDBRow>
        <MDBCol md='6'>
          <p className="text-rose-600 text-sm" style={{marginBottom:"-2px"}}>{formError.name}</p>
          <MDBInput wrapperClass='mb-4' label='Full Name' size='lg' id='form1' type='text' onChange={changehandler} ref={name_ref}/>
        </MDBCol>

        <MDBCol md='6'>
          <p className=" text-rose-600 text-sm" style={{marginBottom:"-2px"}}>{formError.address}</p>
          <MDBInput wrapperClass='mb-4' label='Address'  size='lg' id='form2' type='text'  onChange={changehandler} ref={address_ref} />
        </MDBCol>

      </MDBRow>

      <MDBRow>

        <MDBCol md='6'>
          <p className=" text-rose-600 text-sm" style={{marginBottom:"-2px"}}>{formError.email}</p>
          <MDBInput wrapperClass='mb-4' label='Email' size='lg' id='form3' type='email'  onChange={changehandler} ref={email_ref} />
        </MDBCol>

        <MDBCol md='6'>
          <p className=" text-rose-600 text-sm" style={{marginBottom:"-2px"}}>{formError.phone}</p>
          <MDBInput wrapperClass='mb-4' label='Phone Number' size='lg' id='form3' type='number'  onChange={changehandler} ref={phone_ref}/>
        </MDBCol>

      </MDBRow>

      <MDBRow>

        <MDBCol md='6'>
          <p className=" text-rose-600 text-sm" style={{marginBottom:"-2px"}}>{formError.password}</p>
          <MDBInput wrapperClass='mb-4' label='Password' size='lg' id='form4' type='password'   onChange={changehandler} ref={password_ref}/>
        </MDBCol>

        <MDBCol md='6'>
          <p className=" text-rose-600 text-sm" style={{marginBottom:"-2px"}}>{formError.ConfirmPassword}</p>
          <MDBInput wrapperClass='mb-4' label='Confirm Password' size='lg' id='form5' type='password' onChange={changehandler} ref={confirmPassword_ref} />
        </MDBCol>

      </MDBRow>

      <Button className='mb-4' onClick={addwholeseller}>Submit</Button>

    </MDBCardBody>
  </MDBCard>

</MDBRow>
</MDBContainer>
      {IsSignup && <Navigate replace to={"/SignIn"}></Navigate>}
    </div>
  );
}
export default WholesellerSignUp;
