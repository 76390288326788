import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from '../Config';
import { useReactToPrint } from "react-to-print";
import logo from "../../images/bookmyorder Icon-01.svg";
import { useNavigate } from "react-router-dom";
import "../Style/print.css";


function PrintOrders(props) {
    const [OrderItems, setOrderItems] = useState([]);
    const [OrderDetails, setOrderDetails] = useState([]);
    const [loader, setloader] = useState(null);
    const [ismapping, setismapping] = useState(true);
    const [flag, setflag] = useState(0);
    const navigate = useNavigate();

    const get_data = async () => {
        const Items = JSON.parse(localStorage.getItem("Items"));
        let Order_Items = [];
        let Order_Details = [];
        setloader(true);
        Items.map(async (id) => {
            const response = await fetch(`${BASE_URL}/Orders/${id}/items/details`).catch(error => { console.log("abc") });
            if (!response) {
                toast.error("Error Occured. Please try again");
                setloader(false);
            }
            if (response.status === 200) {
                const Data = await response.json();
                console.log(Data)
                const order_items = Data.order_items;
                const order_details = Data.order_details;
                order_items.map((item) => {
                    Order_Items.push(item);
                })
                order_details.map((item) => {
                    Order_Details.push(item);
                })

                setOrderDetails(Order_Details);
                setOrderItems(Order_Items);
            }
        })
        setloader(false);
        setismapping(false);
    }
    // window.history.pushState(null, '', "");
    const printref = useRef();

    const handlePrint = useReactToPrint({
        content: () => printref.current,
    })





    // const get_Order = async () => {
    //   const response = await fetch(
    //     `${BASE_URL}/Orders/${props.id}`
    //   );
    //   if (response.status === 200) {
    //     const Data = await response.json();
    //     setOrderData(Data);
    //     const loaded_data = [];
    //     for (const key in Data) {
    //       loaded_data.push({
    //         id: Data[key].id,
    //         store: Data[key].store,
    //         phone_no: Data[key].phone_no,
    //         address: Data[key].address
    //       });
    //     }
    //     setOrderData(loaded_data);
    //   }
    // }

    useEffect(() => {
        const a = get_data();
        const timer = setInterval(() => {
            setflag(flag + 1);
        }, 1000);

    }, [])

    useEffect(() => {
        if (!ismapping) {
            setloader(true);
            handlePrint();
            const timer = setInterval(() => {
                clearTimeout(timer);
                navigate("/orders");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [flag])

    const DateHandler = (order_date) => {
        const date = new Date(order_date);
        const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        return formattedDate;
    }

    return (
        <div ref={printref}>
            <div className="mb-5 justify-center flex gap-3">
                <img src={logo} className="w-[10%] h-[5%]"></img>
                <span className="text-2xl font-medium mt-[3%]">BookMyOrder</span>
            </div>
            <Container className="mt-2 Poppins text-[11px] ">
                {OrderDetails.map((item, index) => (
                    <div key={index} className="mb-[5%] border-b-2 border-dotted border-b-black">
                        <div className="text-center flex gap-[5%] ml-[7%]">
                            <div>
                                <div>Order No</div>
                                <p className=" text-sm font-semibold">{item.id}</p>
                            </div>

                            <div>
                                <div >Store Name</div>
                                <p className=" text-sm font-semibold">{item.store}</p>
                            </div>

                            <div>
                                <div>Contact </div>
                                <p className=" text-sm font-semibold">{item.phone_no}</p>
                            </div>

                            <div>
                                <div>Address</div>
                                <p className="text-xs md:text-sm font-semibold">{item.address}</p>
                            </div>
                            <div>
                                <div>Order Date</div>
                                <p className="text-xs md:text-sm font-semibold">{DateHandler(item.order_Date)}</p>
                            </div>

                        </div>


                        <div style={{ width: "90%" }} className="ml-[7%] cart-summary">
                            <div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Price/unit</th>
                                            <th>Qty</th>
                                            <th>T.P</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderItems && OrderItems.filter((f) => f.orderId === item.id).map((item2, index) => (
                                            <tr key={index}>
                                                <td>{item2.productName}</td>
                                                <td>Rs. {item2.price}/-</td>
                                                <td>{item2.quantity}</td>
                                                <td>Rs. {item2.price * item2.quantity}/-</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-white font-semibold">
                                            <td colSpan={3}>Subtotal</td>
                                            <td colSpan={2} style={{ width: "20rem" }} className="font-medium">Rs. {item.total}/-</td>
                                        </tr>
                                    </tfoot>
                                    {OrderItems.length === 0 && <p>No Data Found</p>}
                                </Table>
                            </div>
                        </div>
                    </div>
                ))}
                
            </Container>
        </div>
    )
}
export default PrintOrders;
