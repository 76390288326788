import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../store/stateprovider";
import { action_type } from "../../store/reducer";
import Header from "../Wholeseller/Header";
import axios from "axios";
import share_icon from "../../images/icons8-share.svg";
import ShareModal from "./ShareModal";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Cookies from "js-cookie";

function Profile() {
  const name_ref = useRef("");
  const phone_ref = useRef("");
  const passcode_1 = useRef("");
  const passcode_2 = useRef("");
  const passcode_3 = useRef("");
  const passcode_4 = useRef("");
  const store_ref = useRef("");
  const address_ref = useRef("");
  const [copytext, setcopytext] = useState("Copy");
  const [share, setshare] = useState(false);
  const [update_disable, set_update_disable] = useState(true);

  const link_ref = useRef("");

  const [errors, seterrors] = useState(true);
  const [{ user }, dispatch] = useStateValue();
  const [IsRetailer, setIsRetailer] = useState(false);
  const [loader, setloader] = useState(false);
  // const data = JSON.parse(localStorage.getItem("user"))
  // const data = JSON.parse(Cookies.get("user"));
  

  useEffect(() => {
    setloader(true)
    const Edit = async () => {
      const response = await fetch(`${BASE_URL}/WholesellerControllerSignUp/${user.id}`).catch(error=>{
        console.log("abc")
      });
      if(!response){
        setloader(false);
        toast.error("Error Occured! Please try again");
      }
      const Data = await response.json();
      name_ref.current.value = Data.full_name
      phone_ref.current.value = Data.phone;
      passcode_1.current.value = Data.password[0];
      passcode_2.current.value = Data.password[1];
      passcode_3.current.value = Data.password[2];
      passcode_4.current.value = Data.password[3];
      store_ref.current.value = Data.busniess_name;
      address_ref.current.value = Data.address;
      link_ref.current.value = `https://bookmyorder.pk/${user.url}`;
      setloader(false);
    }

    Edit();
  }, [])

  useEffect(() => {
    if (user && user.type === "retailer") {
      setIsRetailer(true);
    }
  }, [])

  const initialValues = {
    name: "",
    phone: "",
    passcode_1: "",
    passcode_2: "",
    passcode_3: "",
    passcode_4: "",
    store: "",
    address: ""
  };
  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});
  const [IsSignup, setisSignUp] = useState(false);
  const [order_id, setOrder_id] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else {
            inputs[index + 1].focus();
            inputs[index + 1].select();
          }
        }
      })
    })
  }, [])




  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    set_update_disable(false);
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      const name_regex = /^[a-zA-Z\s]+$/;

    if (!name_ref.current.value) {
      errors.name = "Full Name is Required";
      // } else if (!regex.test(email_ref.current.value)) {
      //   errors.email = "Enter a Valid Email address";
    }
    else if (!name_regex.test(name_ref.current.value)) {
      errors.name = "Alphabets only";
      // } else if (!regex.test(email_ref.current.value)) {
      //   errors.email = "Enter a Valid Email address";
    }

    if (!phone_ref.current.value) {
      errors.phone = "Phone No is Required";
    } else if (!phone_regex.test(phone_ref.current.value)) {
      errors.phone = "Enter a Valid Phone No";
    }
    else if(phone_ref.current.value.length <11){
      errors.phone = "Enter a Valid Phone No";
    }
    

    if (!passcode_1.current.value || !passcode_2.current.value || !passcode_3.current.value || !passcode_4.current.value) {
      errors.password = "Passcode is Required";
    }
    if (!store_ref.current.value) {
      errors.store = "Store is Required";
    }
    if (!address_ref.current.value) {
      errors.address = "Address No is Required";
    }

    return errors;
  };

  useEffect(() => {
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0) {
      seterrors(false);
    }
    if (errors_count > 0) {
      seterrors(true);
    }
  }, [formError])
  

  const edituser = async () => {
    setloader(true);
    setFormErrors(validate(formValues));
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return toast.error("Please Enter all the required Fields");
    }
      let phone_no = phone_ref.current.value;
      if (phone_no.charAt(0) === "+92") {
        phone_no = "0" + phone_no.slice(1);
      }
      const wholeseller_data = {
        "id": user.id,
        "full_name": name_ref.current.value,
        "busniess_name": store_ref.current.value,
        "address": address_ref.current.value,
        "email": "",
        "phone": phone_no,
        "password": passcode_1.current.value + passcode_2.current.value + passcode_3.current.value + passcode_4.current.value,
        "url": user.url
      };
      const url = `${BASE_URL}/WholesellerControllerSignUp/${user.id}`;
      axios
        .put(url, wholeseller_data)
        .then((result) => {
          toast.success("Updated Successfully");
          setloader(false);
        })
        .catch((error) => {
          toast.error("Error occured please try again");
          console.log(error);
          setloader(false);
        });
      Cookies.remove("user");
      Cookies.set("user",JSON.stringify(wholeseller_data));
      dispatch({
          type: action_type.SET_USER,
          user: wholeseller_data,
          //if you look at the console.log(response) you will see that providerData is an array
        });
  };

  const Clear = () => {
    name_ref.current.value = "";
    phone_ref.current.value = "";
    passcode_1.current.value = "";
    passcode_2.current.value = "";
    passcode_3.current.value = "";
    passcode_4.current.value = "";
    store_ref.current.value = "";
    address_ref.current.value = "";
  };

  const copyLink = async () => {
    navigator.clipboard.writeText(link_ref.current.value);
    setcopytext("Copied");
    // navigate(`/home/${data.busniess_name}/${data.id}`);
    // await localStorage.removeItem("user");
    setTimeout(()=> {
      setcopytext("Copy");
    },3000)
  }

  const shareLink = () => {
    setshare(true);
  }



  return (
    <Fragment className="overflow-hidden">
      <Header></Header>
      <ToastContainer></ToastContainer>
      <Row style={{ marginLeft: "2.5%" }}>
        <Col md={9} xs={7}>
          <div className="breadcrum flex gap-5">
            <h3 className="text-2xl font-bold text-black mt-1 Poppins">Profile Details</h3>
            {loader && <Spinner className="mt-1" size="md"></Spinner>}
          </div>
          <Form>
            <Form.Group className="w-275 md:w-375 mt-1 Poppins text-sm">
              <div className="flex gap-10 md:gap-32">
                <Form.Label className=" ml-3 text-slate-500">Full Name</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.name}</Form.Label>
              </div>
              <Form.Control
                style={{ border: "1px solid #333333" }}
                className=" mb-2 text-slate-500" ref={name_ref} onChange={changehandler}>
              </Form.Control>

              <div className="flex gap-20 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Phone</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.phone}</Form.Label>
              </div>
              <Form.Control disabled onChange={changehandler} value={user.phone} style={{ border: "1px solid #333333" }} className=" mb-2" ref={phone_ref}></Form.Control>


              <div className="flex gap-4">
                <Form.Label className="ml-3 text-slate-500">Set Pin Code</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.password}</Form.Label>
              </div>
              <Row style={{ width: "50%" }} className="passcode mb-2">
                <div className="flex  gap-3">
                  <Col xs={6} md={4}>
                    <Form.Control type="password" onChange={changehandler} minLength={1} maxLength={1} id="input1" style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_1}></Form.Control>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} id="input2" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_2}></Form.Control>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} id="input3" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_3} ></Form.Control>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} id="input4" onChange={changehandler} style={{ border: "1px solid #333333",fontWeight:"900", fontSize:"25px" }} className="text-center" ref={passcode_4}></Form.Control>
                  </Col>
                </div>
              </Row>
              <div className="flex gap-10 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Busniess Name</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.store}</Form.Label>
              </div>
              <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={store_ref}></Form.Control>

              <div className="flex gap-10 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Address</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.address}</Form.Label>
              </div>
              <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-2 text-slate-500" ref={address_ref}></Form.Control>

              <div className="flex gap-10 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Store URL</Form.Label>
              </div>
              <div className="flex gap-0">
                <Form.Control disabled value={`https://bookmyorder.pk/${user.url}`} onChange={changehandler} style={{ border: "1px solid #333333", borderRight: "0px" }} className=" mb-2 text-slate-500" ref={link_ref}></Form.Control>
                <Button onClick={copyLink} variant="success" style={{ height: "45px", borderTopLeftRadius: "1px", borderBottomLeftRadius: "1px" }} className="-ml-12" >{copytext}</Button>
                <img onClick={shareLink} src={share_icon} className=" absolute ml-72 md:ml-96 rounded-3xl cursor-pointer  w-7 h-7 mt-2 hover:bg-gray-200 transition pr-1 "></img>
              </div>
              {loader ? <Button className="submit-button mt-2 mb-4"><Spinner size="sm"></Spinner></Button> :
              <Button disabled={update_disable} variant="success" onClick={edituser} className="submit-button mt-2 mb-4">Update</Button>}
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {share && <ShareModal link={link_ref.current.value} show={share} handleClose={() => setshare(false)}></ShareModal>}
      {IsRetailer && (
        <AlertSignedInAs show={IsRetailer}></AlertSignedInAs>
      )}
    </Fragment>
  );
}
export default Profile;
