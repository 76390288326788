import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddForm from "./AddForm";
import DashboardHeader from "./DashboardHeader";
import EditModal from "./EditModal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/CRUD.css";
import delete_icon from "../../images/delete-icon.svg";
import edit_icon from "../../images/edit-icon.svg";
import search_icon from "../../images/ri_search-line.svg";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Header from "./Header";
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import { useNavigate } from "react-router-dom";
import ColumnsModal from "./ColumnsModal";

function SelectProducts(props) {
  const [edit, setEdit] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [id, setid] = useState(0);


  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(Cookies.get("user"));

  const [{ user }] = useStateValue();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [error_msg, set_error_msg] = useState("");
  const [IsRetailer, setIsRetailer] = useState(false);
  const [loader, setloader] = useState(false);
  const [checkedItems, setcheckedItems] = useState([]);
  const [Loaded_Data, setLoaded_Data] = useState([]);
  const [length, setlength] = useState(0);
  const [mobile, setIsMobile] = useState(false);
  const [column_modal, set_column_modal] = useState(false);
  const [show_id, set_show_id] = useState(false);
  const [show_offer, set_show_offer] = useState(false);
  const [show_bonus, set_show_bonus] = useState(false);
  const [flag, setflag] = useState(0);
  const [sticky, setIsSticky] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
        setIsSticky(window.scrollY > window.innerHeight/6);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);






  const column_handler = (col) =>{
    if(col === "id"){
      set_show_id(!show_id);
    }
    if(col === "offer"){
      set_show_offer(!show_offer);
    }
    if(col === "bonus"){
      set_show_bonus(!show_bonus);
    }
  }

  const set_show_columns = (value) =>{
    set_show_id(value);
    set_show_offer(value);
    set_show_bonus(value);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000); // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(()=>{
    if(!mobile){
      set_show_columns(true);
    }
    else{
      set_show_columns(false);
    }
  },[mobile])



  const offer_ref = useRef();
  const bonus_ref = useRef();
  const total_ref = useRef();
  const checkbox_ref = useRef();
  const search_ref = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    } //We need to return it as a dependency
  }, [])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      get_SearchList();
    }
  }



  // const data = [
  //   {
  //     id: 1,
  //     product_name: "Panadol",
  //     manufacturer: "Pfizer",
  //     offer: 5,
  //     bonus: 1,
  //     Exp_date: "25/1/2023",
  //   },
  // ];

  useEffect(() => {
    if (user && user.type === "retailer") {
      setIsRetailer(true);
    }
  }, [])





  useEffect(() => {
    const GetProductLength = async () => {
      const response = await fetch(`${BASE_URL}/Admin/products/length/${user.id}`);
      const length = await response.text();

      setlength(length);
    }
    GetProductLength();
  }, [])

  const [currentPage, setcurrentPage] = useState(1);
  const recordsPerPage = 50;
  const npage = Math.ceil(length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  let lastIndex = currentPage * recordsPerPage;
  let firstIndex = lastIndex - recordsPerPage;
  let records = data;
  // const [records, setrecords] = useState();
  const [search, setsearch] = useState(false);



  //search

  const ChangeNpage = (id) => {
    const Items = data.filter((e) => e.checked === true);
    if (Items.length > 0) {
      return toast.error("Please Add or Un-check the selected items");
    }
    setcurrentPage(id)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const NextPage = () => {
    const Items = data.filter((e) => e.checked === true);
    if (Items.length > 0) {
      return toast.error("Please Add or Un-check the selected items");
    }
    if (currentPage !== npage) {
      setcurrentPage(currentPage + 1);
    }
  }

  const PrevPage = () => {
    const Items = data.filter((e) => e.checked === true);
    if (Items.length > 0) {
      return toast.error("Please Add or Un-check the selected items");
    }
    if (currentPage !== 1) {
      setcurrentPage(currentPage - 1);
    }
  }



  const get_data = async () => {
    setloader(true);
    lastIndex = currentPage * recordsPerPage;
    firstIndex = lastIndex - recordsPerPage;
    try {
      const response = await fetch(
        `${BASE_URL}/Admin/products/${user.id}/${firstIndex}/${lastIndex}`
      ).catch(error => {
        console.log("abc");
      });
      const Data = await response.json();
      const loaded_data = Object.keys(Data).map((key) => ({
        id: Data[key].id,
        product_name: Data[key].product_name,
        manufacturer: Data[key].manufacturer,
        offer: Data[key].offer,
        bonus: Data[key].bonus,
        total_price: Data[key].total_price,
        product_code: Data[key].product_code,
        checked: false, // Set 'checked' to false for each loaded item
      }));
      setloader(false);
      setData(loaded_data);
      if (loaded_data.length === 0) {
        set_error_msg("Data Not Found");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error Occurred. Please try again");
      setloader(false);
      set_error_msg("Error Occurred. Please try again");
    }
  };


  // const Get_Data = async () => {
  //   setloader(true);
  //   lastIndex = currentPage * recordsPerPage;
  //   firstIndex = lastIndex - recordsPerPage;
  //   const response = await fetch(
  //     `${BASE_URL}/Admin/products/${user.id}`);
  //   const Data = await response.json();
  //   const loaded_data = [];
  //   for (const key in Data) {
  //     loaded_data.push({
  //       id: Data[key].id,
  //       product_name: Data[key].product_name,
  //       manufacturer: Data[key].manufacturer,
  //       offer: Data[key].offer,
  //       bonus: Data[key].bonus,
  //       total_price: Data[key].total_price,
  //       checked: false
  //     });
  //   }
  //   setloader(false);
  //   return loaded_data;
  //   if (!data || loaded_data.length === 0 || data.length === 0) {
  //     set_error_msg("Data Not Found");
  //   }
  // };

  useEffect(() => {
    get_data();
  }, [currentPage]);


  const EditHandler = (id) => {
    setEdit(true);
    setid(id);
  };

  const DeleteHandler = (id) => {
    setDelete(true);
    if (window.confirm("Are You Sure") === true) {
      axios
        .delete(`${BASE_URL}/MedicalProducts/${id}`)
        .then((result) => {
          if (result.status === 200) {
            get_data();
            toast.success("Deleted Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const CloseHandler = () => {
    setEdit(false);
    setDelete(false);
    set_column_modal(false);
  };

  let categories = new Set();
  for (const key in data) {
    categories.add(data[key].manufacturer);
  }

  let filter_items = [];
  categories = Array.from(categories).sort();

  const filter_categories = [];
  for (const i of categories) {
    filter_categories.push({ cat: i });
    filter_items.push(data.filter((f) => f.manufacturer === i));
  }

  


  // const filter = data.filter((f) => {
  //   return f.manufacturer === "GSK";
  // });
  // console.log(filter);

  // const a = []

  //Search 
  const get_SearchList = async () => {
    setloader(true)
    if (search_ref.current.value !== "") {
      setsearch(true);
      const response = await fetch(`${BASE_URL}/Admin/product/search/${user.id}/${search_ref.current.value}`).catch(error => {
        console.log("abc")
      });
      const Data = await response.json();
      const loaded_data = [];

      for (const key in Data) {
        loaded_data.push({
          id: Data[key].id,
          product_name: Data[key].product_name,
          manufacturer: Data[key].manufacturer,
          offer: Data[key].offer,
          bonus: Data[key].bonus,
          total_price: Data[key].total_price,
          product_code: Data[key].product_code,
          checked: false
        });
      }
      // loaded_data.sort((a, b)=>b.id - a.id);
      setSearchList(loaded_data);
    }
    else{
      setsearch(false);
      get_data();
    }
    setloader(false);
  }
  let categories_search = new Set();
  for (const key in searchList) {
    categories_search.add(searchList[key].manufacturer);
  }

  categories_search = Array.from(categories_search).sort();

  const filter_categories_search = [];
  for (const i of categories) {
    filter_categories_search.push({ cat: i });
    filter_items.push(searchList.filter((f) => f.manufacturer === i));
  }
  
  const searchHandler = () => {
    if (search_ref.current.value === "" && search_ref.current.value.length === 0) {
      get_data();
      setsearch(false);
    }
  }

  // const searchHandler = async () => {
  //   if (search_ref.current.value !== "") {
  //     setloader(true)
  //     const data2 = await Get_Data();
  //     // get_SearchList(search_ref.current.value);
  //     const newList = data2.filter((d) => {
  //       return Object.values(d)
  //         .join(" ")
  //         .toLowerCase()
  //         .includes(search_ref.current.value.toLowerCase());
  //     });
  //     setSearchList(newList);
  //     setsearch(true);
  //     setloader(false)
  //   }
  //   else {
  //     get_data();
  //     Get_Data();
  //     setsearch(false);
  //   }
  // };



  const CheckHandler = (e) => {
    const value = e.target.value;
    data.map((item) => {
      if (item.product_name === value) {
        item.checked = !item.checked;
        setflag(flag+1);
      }
    });
    searchList.map((item) => {
      if (item.product_name === value) {
        item.checked = !item.checked;
        setflag(flag+1);
      }
    });
    //console.log(data.filter((e) => e.checked === true));
  }

  const OfferHandler = (e, id) => {
    const value = (e.target.value);
    data.map((item) => {
      if (item.id === id) {
        item.offer = value;
      }
    });
    searchList.map((item) => {
      if (item.id === id) {
        item.offer = value;
      }
    });

  }
  const BonusHandler = (e, id) => {
    const value = (e.target.value);
    data.map((item) => {
      if (item.id === id) {
        item.bonus = value;
      }
    });
    searchList.map((item) => {
      if (item.id === id) {
        item.bonus = value;
      }
    });
  }
  const PriceHandler = (e, id) => {
    const value = (e.target.value);
    data.map((item) => {
      if (item.id === id) {
        item.total_price = value;
      }
    });
    searchList.map((item) => {
      if (item.id === id) {
        item.total_price = value;
      }
    });
  }

  const AddProducts = async () => {
    setloader(true);
    let Items;
    if(!search){
    Items  = data.filter((e) => e.checked === true);
    }
    else{
    Items = searchList.filter((e) => e.checked === true);
    }
    if (Items.length > 0) {
      try {
        for (const key in Items) {
          const product_data = {
            "product_id": Items[key].id,
            "wholeseller_id": user.id,
            "product_name": Items[key].product_name,
            "manufacturer": Items[key].manufacturer,
            "offer": Items[key].offer,
            "bonus": Items[key].bonus,
            "total_price": Items[key].total_price,
            "product_code": Items[key].product_code
          }
          const response = await fetch(
            `${BASE_URL}/MedicalProducts`,
            {
              method: "POST",
              body: JSON.stringify(product_data),
              headers: { "Content-Type": "application/json" },
            }
          ).catch(error => {
            console.log("abc")
          });
          const data = await response.json();
          console.log(data);
        }
        navigate("/WholesellerHome");
        toast.success("Products Added Successfully");
      }
      catch {
        toast.error("Please Try Again");
      }
    }
    else {
      toast.error("No Items Selected");
    }
    setloader(false);
  }



  return (
    <div className="overflow-hidden">
      <Header></Header>
      <ToastContainer />
      <Row style={{ marginLeft: "2%", marginRight: "0.5%", marginTop: "1%" }}>
        <Col md={9} >
          <div className={`${sticky && "sticky drop-shadow-md bg-white"}`}>
            <Form.Group >
              <div className={`flex gap-3 ${sticky && "mt-3 transition-all ease-in-out duration-500"}`}>
                <InputGroup id="search" style={
                    sticky ? {
                      width: "63%", height: "50px",
                      marginBottom: "1.5%",
                      marginLeft:"3.5%",
                    } :
                      {
                        height: "50px",
                        marginBottom: "1.5%",
                      }}>
                  <Form.Control
                    className={`search`}
                    id="menu"
                    placeholder="Search"
                    ref={search_ref}
                    onChange={searchHandler}
                  >
                  </Form.Control>
                  <button onClick={get_SearchList} class="search-icon w-16" type="button">
                    <img src={search_icon} className="ml-auto mr-auto w-5 h-5" ></img>
                  </button>

                </InputGroup>
                {loader ? <Button style={{ height: "50px", marginLeft: "20px", width: "90px" }} variant="success"><Spinner size="sm"></Spinner></Button> :
                  <Button onClick={AddProducts} style={{ height: "50px", marginLeft: "20px", width: "90px" }} variant="success">Add</Button>
                }
              </div>
            </Form.Group>
          </div>
          <div className="table-scroll mb-[5%] text-sm " style={{ fontFamily: "Poppins", maxWidth:"100%", overflowX:"scroll" }}>
            <Table>
              <thead className="text-[11px] sm:text-xs md:text-sm" >
                <tr className="text-center">
                <th className={`${!show_id && 'hidden'}`}>ID</th>
                  <th>Product</th>
                  <th className={`${!show_offer && 'hidden'}`}>Offer</th>
                  <th className={`${!show_bonus && 'hidden'}`}>Bonus</th>
                  <th >T.P (Rs/-)</th>
                  <th>Select</th>
                  {mobile && <th onClick={()=>set_column_modal(true)} className="cursor-pointer text-green-500 hover:text-green-700 text-xl align-middle">+</th>}
                </tr>
              </thead>
              {filter_categories.length > 0 &&
                search_ref.current.value.length === 0 && (
                  filter_categories.map((item, index) => (
                    <tbody className="text-[11px] sm:text-xs md:text-sm xl:text-sm">
                      <tr
                        key={index.cat}
                        style={{ height: "50px" }}
                        className="Category"
                      >
                        <td
                          colspan="8"
                        >
                          <h1 className="text-lg">{item.cat}</h1>
                        </td>
                      </tr>

                      {data
                        .filter((f) => f.manufacturer === item.cat)
                        .map((item, index) => (
                          <tr className="text-center" key={index}>
                            <td className={`${!show_id && 'hidden'}`}>{item.id}</td>
                            <td>{item.product_name}</td>
                            <td className={`${!show_offer && 'hidden'}`}><input className="w-[2rem] md:w-[5rem] h-9 text-center border rounded-md" onChange={(event) => OfferHandler(event, item.id)} ref={offer_ref} defaultValue={item.offer}></input></td>
                            {/* <td><input className="w-[3rem] text-center border rounded-md " onChange={(event) => BonusHandler(event, item.id)} ref={bonus_ref} defaultValue={item.bonus}></input></td> */}
                            <td className={`${!show_bonus && 'hidden'}`}>
                              <select
                                className="border-solid border border-gray-400 w-[4pc] md:w-auto h-[38px] rounded-lg p-1"
                                onChange={(event) => BonusHandler(event, item.id)} ref={bonus_ref}
                                defaultValue={item.bonus}

                              >
                                <option value={""}>None</option>
                                <option>OLD TP</option>
                                <option>770 NET</option>
                                <option>77 NET</option>
                                <option>1600 NET</option>
                                <option>FRASH</option>
                                <option>ALPROZOLAM</option>
                                <option>OLD TPP</option>
                                <option>50 PR 7%</option>
                              </select>
                            </td>
                            <td><input className="w-[2rem] md:w-[5rem] h-9 text-center border rounded-md" onChange={(event) => PriceHandler(event, item.id)} ref={total_ref} defaultValue={item.total_price}></input></td>
                            <td>
                              {/* <div className="flex gap-2 buttons">
                              <img
                                onClick={() => EditHandler(item.id)}
                                className=" cursor-pointer mt-2"
                                src={edit_icon}
                              ></img>
                              <img
                                onClick={() => DeleteHandler(item.id)}
                                className=" cursor-pointer mt-2"
                                src={delete_icon}
                              ></img>
                            </div> */}
                              <input onChange={CheckHandler} defaultChecked={item.checked} value={item.product_name} type="checkbox" className="w-5 h-5 ml-2"></input>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ))
                )}
              {search && search_ref.current.value.length > 0 && categories_search.map((item, index) => (
                <tbody className="text-[11px] sm:text-xs md:text-sm xl:text-sm">
                  {searchList.filter((f) => f.manufacturer === item).length > 0 &&
                    <tr
                      key={index}
                      style={{ height: "50px" }}
                      className="Category"
                    >
                      <td
                        colspan="8"
                      >
                        <h1 className="text-lg">{item}</h1>
                      </td>
                    </tr>
                  }
                  {searchList
                    .filter((f) => f.manufacturer === item)
                    .map((item, index) => (
                      <tr className="text-center" key={index}>
                        <td className={`${!show_id && 'hidden'}`}>{item.id}</td>
                        <td>{item.product_name}</td>
                        <td className={`${!show_offer && 'hidden'}`}><input className="w-[2rem] md:w-[5rem] h-9 text-center border rounded-md" onChange={(event) => OfferHandler(event, item.id)} ref={offer_ref} defaultValue={item.offer}></input></td>
                        {/* <td><input className="w-[3rem] text-center border rounded-md " onChange={(event) => BonusHandler(event, item.id)} ref={bonus_ref} defaultValue={item.bonus}></input></td> */}
                        <td className={`${!show_bonus && 'hidden'}`}>
                          <select
                            className="border-solid border border-gray-400 w-[4pc] md:w-auto h-[38px] rounded-lg"
                            onChange={(event) => BonusHandler(event, item.id)} ref={bonus_ref}
                            defaultValue={item.bonus}
                          >
                            <option value={""}>None</option>
                            <option>OLD TP</option>
                            <option>770 NET</option>
                            <option>77 NET</option>
                            <option>1600 NET</option>
                            <option>FRASH</option>
                            <option>ALPROZOLAM</option>
                            <option>OLD TPP</option>
                            <option>50 PR 7%</option>
                          </select>
                        </td>
                        <td><input className="w-[2rem] md:w-[5rem] h-9 text-center border rounded-md" onChange={(event) => PriceHandler(event, item.id)} ref={total_ref} defaultValue={item.total_price}></input></td>
                        <td>
                          {/* <div className="flex gap-2 buttons">
                              <img
                                onClick={() => EditHandler(item.id)}
                                className=" cursor-pointer mt-2"
                                src={edit_icon}
                              ></img>
                              <img
                                onClick={() => DeleteHandler(item.id)}
                                className=" cursor-pointer mt-2"
                                src={delete_icon}
                              ></img>
                            </div> */}
                          <input onChange={CheckHandler} defaultChecked={item.checked} value={item.product_name} type="checkbox" className="w-5 h-5 ml-2"></input>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ))
              }
            </Table>
            {data.length === 0 && <p>{error_msg}</p>}
            {!searchList && <p>No Data Found</p>}
          </div>
          {!search &&
            <ul className="pagination justify-start md:justify-center overflow-x-scroll">
              <li className="page-item cursor-pointer">
                <a href="#" className="page-link" onClick={PrevPage}>Prev</a>
              </li>
              {
                numbers.map((item, index) => (
                  <li onClick={() => ChangeNpage(item)} className={`page-link cursor-pointer ${currentPage === item ? 'active' : ''}`}>
                    {item}
                  </li>
                ))
              }
              <li className="page-item cursor-pointer">
                <a href="#" className="page-link" onClick={NextPage}>Next</a>
              </li>
            </ul>
          }
        </Col>
        <Col>
          <h5 className=" text-center font-bold text-base">Tips/Guide</h5>
          <ul className="text-sm list-disc">
            <li>Search the Products</li>
            <li>Select the Products to be display on your Market</li>
            <li>Set your Offer and Price for the product</li>
            <li>Click Add </li>
          </ul>
        </Col>
      </Row>
      {IsRetailer && (
        <AlertSignedInAs show={IsRetailer}></AlertSignedInAs>
      )}
      {column_modal && mobile && (
      <ColumnsModal show={column_modal} show_id={show_id} show_offer={show_offer} show_bonus={show_bonus} handleClose={CloseHandler} column_handler={column_handler}></ColumnsModal>
      )}
    </div>
  );
}
export default SelectProducts;
