import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import BASE_URL from '../Config';
import { useState } from "react";
import { Spinner } from "react-bootstrap";



function EditModal(props) {
  const product_ref = useRef();
  const manufacturer_ref = useRef();
  const offer_ref = useRef();
  const bonus_ref = useRef();
  const total_price_ref = useRef();
  const [is_disabled, set_is_disabled] = useState(null);
  const [loader, setloader] = useState(false);

  const DeleteHandler = () => {
    setloader(true);
    props.DeleteHandler(props.id);
    setloader(false)
  };

  useEffect(() => {
    const Edit = async () => {
      setloader(true);
      // axios.get(`${BASE_URL}/MedicalProducts/${props.id}`).then((result) => {
      //   product_ref.current.value = result.data.product_name;
      //   manufacturer_ref.current.value = result.data.manufacturer;
      //   unit_price_ref.current.value = result.data.unit_price;
      //   quantity_ref.current.value = result.data.quantity;
      //   discount_ref.current.value = result.data.discount;
      //   Exp_date_ref.current.value = result.data.expiryDate;
      // })
      // .catch((error) => {
      //   console.log(error);
      // });

      const response = await fetch(
        `${BASE_URL}/Admin/${props.id}`
      ).catch(error=>{
        console.log("abc")
      });
      const Data = await response.json();
      product_ref.current.value = Data.product_name;
      manufacturer_ref.current.value = Data.manufacturer;
      offer_ref.current.value = Data.offer;
      bonus_ref.current.value = Data.bonus;
      total_price_ref.current.value = Data.total_price;
      set_is_disabled(Data.is_disabled)

      setloader(false);
    };
    Edit();
  }, [props.id]);

  const updatehandler = () => {
    setloader(true);
    const url = `${BASE_URL}/Admin/${props.id}`;
    if(manufacturer_ref.current.value === "Select"){
      setloader(false);
      return toast.error("Please Select a Manufacturer");
    }
    const product_data = {
      "id":props.id,
      "product_name": product_ref.current.value,
      "manufacturer": manufacturer_ref.current.value,
      "offer": offer_ref.current.value,
      "bonus": bonus_ref.current.value,
      "total_price": total_price_ref.current.value,
    };
    axios
      .put(url, product_data)
      .then((result) => {
        toast.success("Updated Successfully");
        props.get_data();
        props.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
      setloader(false)
      
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Row>
              <Col>
              <Form.Label className="text-xs text-black font-medium ml-2">Item Name</Form.Label>
                <Form.Control
                  required
                  placeholder="Product Name"
                  ref={product_ref}
                />
              </Col>
              <Col>
              <Form.Label className="text-xs text-black font-medium ml-2">Brand</Form.Label>
              <Form.Select
                style={{ height: "36px" }}
                className="border-solid border border-gray-400"
                ref={manufacturer_ref}
              >
                <option>Select</option>
                <option>ABBOTT</option>
                <option>A.R Laboratories</option>
                <option>Ali Gohar</option>
                <option>Asian Continental</option>
                <option>ATCO</option>
                <option>B H</option>
                <option>Bayer</option>
                <option>Bioganic</option>
                <option>Bosch</option>
                <option>C C L</option>
                <option>Coris</option>
                <option>DKT Pakistan</option>
                <option>Efroz Sons</option>
                <option>Feroz Sons</option>
                <option>Genix</option>
                <option>Genome Pharmaceuticals</option>
                <option>GETZ</option>
                <option>GSK</option>
                <option>Healthaid LTD</option>
                <option>Helix</option>
                <option>Highnoon</option>
                <option>Hilton</option>
                <option>Himont</option>
                <option>Horizon Pharma</option>
                <option>Hudson Pharma</option>
                <option>ICI Pakistan</option>
                <option>Indus Pharma</option>
                <option>Janssen</option>
                <option>Kaizen</option>
                <option>Lazma Bio Lab</option>
                <option>LundBeck</option>
                <option>Macter</option>
                <option>Martin Dow</option>
                <option>Mass Pharma</option>
                <option>Matrix</option>
                <option>Maxitech</option>
                <option>Medics Pharma</option>
                <option>Mendoza</option>
                <option>Merck</option>
                <option>Mixx Item</option>
                <option>MSD</option>
                <option>Nabiqasim</option>
                <option>Neutro Pharma</option>
                <option>Next Pharma</option>
                <option>Novartis</option>
                <option>Opal Laboratories</option>
                <option>Pacific</option>
                <option>Parke Davis</option>
                <option>Pfizer</option>
                <option>Pharmadic</option>
                <option>Pharmatec</option>
                <option>Pharmevo</option>
                <option>Platinum</option>
                <option>R G Pharmacutica</option>
                <option>Ramigton</option>
                <option>Pharmevo</option>
                <option>Pharmevo</option>
                <option>Pharmevo</option>
                <option>Pharmevo</option>
              </Form.Select>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
              <Form.Label className="text-xs text-black font-medium ml-2">Offer(%)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Offer"
                  ref={offer_ref}
                />
              </Col>
              <Col>
              <Form.Label className="text-xs text-black font-medium ml-2">Bonus</Form.Label>
              <Form.Select
                style={{ height: "36px" }}
                className="border-solid border border-gray-400"
                ref={bonus_ref}
              >
                <option>None</option>
                <option>OLD TP</option>
                <option>770 NET</option>
                <option>77 NET</option>
                <option>1600 NET</option>
                <option>FRASH</option>
                <option>ALPROZOLAM</option>
                <option>OLD TPP</option>
                <option>50 PR 7%</option>
              </Form.Select>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
              <Form.Label className="text-xs text-black font-medium ml-2">Total Price($)</Form.Label>
                <Form.Control
                  required
                  placeholder="Quantity"
                  ref={total_price_ref}
                />
              </Col>
              <Col></Col>
            </Row>
            <br></br>
          </Form>
        </Modal.Body>   
        <Modal.Footer>
          {is_disabled && !loader && <Button variant="secondary" onClick={()=>(DeleteHandler(props.id))}>Activate</Button>}
          {!is_disabled && !loader && <Button variant="secondary" onClick={()=>(DeleteHandler(props.id))}>Deactivate</Button>}
          {!loader ? <Button variant="primary" onClick={updatehandler}>Save Changes</Button> :
          <Button variant="primary"><Spinner size="sm"></Spinner></Button>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default EditModal;
