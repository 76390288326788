import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../store/stateprovider";
import { action_type } from "../store/reducer";
import Header from "./Wholeseller/Header";
import BASE_URL from './Config';
import Cookies from "js-cookie";


function Registration() {

  const name_ref = useRef("");
  const phone_ref = useRef("");
  const passcode_1 = useRef("");
  const passcode_2 = useRef("");
  const passcode_3 = useRef("");
  const passcode_4 = useRef("");
  const store_ref = useRef();
  const address_ref = useRef();
  const user_ref = useRef();
  const [user_as, setuser_as] = useState();
  const [loader, setloader] = useState(false);
  user_ref.current = user_as;

useEffect(()=>{
  if(Cookies.get('reg_user')){
    let reg_data = Cookies.get('reg_user');
    if(reg_data){
      reg_data = JSON.parse(reg_data);
      store_ref.current.value = reg_data.store;
      address_ref.current.value = reg_data.address;
      setuser_as(reg_data.user_as);
    }
  }
},[])
  




  const [errors, seterrors] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const phone = localStorage.getItem('phone');

  const initialValues = {
    name: "",
    phone: "",
    passcode_1: "",
    passcode_2: "",
    passcode_3: "",
    passcode_4: "",
    store: "",
    address: ""
  };
  const [formValues, setformValues] = useState(initialValues);
  const [formError, setFormErrors] = useState({});
  const [IsSignup, setisSignUp] = useState(false);
  const [order_id, setOrder_id] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    } //We need to return it as a dependency
  }, [])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      continue_handler();
    }
  }



  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (index === inputs.length - 1) {
            input.blur();
          }
          else {
            inputs[index + 1].focus();
          }
        }
      })
    })
  }, [])


  const SetPhoneInLocalStorage = (phone_no) => {
    const phone = localStorage.getItem("phone");
    if (phone) {
      localStorage.removeItem("phone");
    }
    localStorage.setItem("phone", phone_no);
  };

 


  const changehandler = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone_regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const name_regex = /^[a-zA-Z\s]+$/;

    if (!store_ref.current.value) {
      errors.store = "Store is Required";
    }
    if (!address_ref.current.value) {
      errors.address = "Address No is Required";
    }
    if (!user_ref.current) {
      errors.user = "Please Select the User";
    }

    return errors;
  };

  const SETRegData = () =>{
    Cookies.remove('reg_user');
    Cookies.set('reg_user',JSON.stringify({
      user_as: user_ref.current,
      store: store_ref.current.value,
      address: address_ref.current.value,
    }),{expires:2}); 
  }

  useEffect(() => {
    const errors_count = Object.keys(formError).length;
    if (errors_count === 0) {
      seterrors(false);
    }
    if (errors_count > 0) {
      seterrors(true);
    }
  }, [formError])

  const continue_handler = ()=>{
    setloader(true);
    setFormErrors(validate(formValues));
    const check_errors = validate(formValues);
    const check_errors_count = Object.keys(check_errors).length;
    if (check_errors_count > 0) {
      setloader(false)
      return toast.error("Please Enter all the required Fields");
    }
    SETRegData();
    navigate("/Registration2/:id");
    setloader(false);
  }

  

  const Clear = () => {
    name_ref.current.value = "";
    phone_ref.current.value = "";
    passcode_1.current.value = "";
    passcode_2.current.value = "";
    passcode_3.current.value = "";
    passcode_4.current.value = "";
    store_ref.current.value = "";
    address_ref.current.value = "";
  };

  

  
  

  return (
    <div className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <Header></Header>
      <Row style={{ marginLeft: "2.5%", marginTop:"1%" }}>
        <Col md={9} xs={7} className="mt-2">
          <div className="breadcrum flex">
            <h3 className="text-2xl font-bold text-black mt-1 Gilroy-Bold">Registration</h3>
            {/* <div style={{ marginLeft: "30%" }} className="flex gap-2 mt-2 Poppins">
              <h6 className="text-black">Already have an account?</h6>
              <p onClick={() => (navigate("/"))} className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
          </div>
          <div className="text-xs md:text-base flex gap-3 mt-3 mb-3">
            <div className="font-bold text-[#0A9A73]">Busniess Details</div>
            <div className=" h-0 w-[100%] md:w-[5%] mt-[5%] md:mt-[1%] border-black border-solid border"></div>
            <div className="text-[#0A9A73] opacity-60 " >Personal Details</div>
          </div>
          <Form>
            <Form.Group className="w-275 md:w-375 mt-1 Poppins text-sm">
              <div className="flex gap-10 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Register As</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.user}</Form.Label>
              </div>
              <div className={`flex gap-4 -mt-1 mb-3`}>
                <div className={`border-2 border-solid rounded-md w-32 ${user_as==="wholeseller" && 'font-medium border-black'}`}>
                  <label className="flex gap-2 mt-2 mb-2 ml-2"><Form.Check checked={user_as==="wholeseller" && true}  value="wholeseller"  onChange={(e) => setuser_as(e.target.value)} type="radio" name="group1"></Form.Check><span >Wholesaler</span></label>
                </div>
                <div  className={`border-2 border-solid rounded-md w-32 ${user_as==="retailer" && 'font-medium border-black'}`}>
                  <label className="flex gap-2 mt-2 mb-2 ml-2"><Form.Check checked={user_as==="retailer" && true} value="retailer" onChange={(e) => setuser_as(e.target.value)} type="radio" name="group1"></Form.Check><span className="text-sm">Retailer</span></label>
                </div>
              </div>

              <div className="flex gap-10 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Busniess Name</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.store}</Form.Label>
              </div>
              <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-3 -mt-1 text-slate-500" ref={store_ref}></Form.Control>

              <div className="flex gap-10 md:gap-32">
                <Form.Label className="ml-3 text-slate-500">Address</Form.Label>
                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.address}</Form.Label>
              </div>
              <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-3 -mt-1 text-slate-500" ref={address_ref}></Form.Control>

              {loader ? <Button className="submit-button mt-3 mb-4"><Spinner ></Spinner></Button> :
                <Button onClick={continue_handler} className="submit-button mt-3 mb-4">Continue</Button>
              }
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
export default Registration;
