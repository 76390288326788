import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Style/CartSummary.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useStateValue } from "../../store/stateprovider";
import { useNavigate, useParams } from "react-router-dom";
import { action_type } from "../../store/reducer";
import BASE_URL from '../Config';
import HeaderRetailer from "./HeaderRetailer";
import AlertSignedInAs from "./AlertSignedInAS";


function RecentlyVisitedUrls() {
  const [recentstores, setrecentstores] = useState([]);
  const navigate = useNavigate();
  const [{ user }] = useStateValue();
  const [Store, setstore] = useState("");
  const [IsSeller, setIsSeller] = useState(false);
  const [loader, setisloader] = useState(false);
  const [error_msg, set_error_msg] = useState("");


  const GetRecentStores = async () => {
    setisloader(true);
    const response = await fetch(`${BASE_URL}/RecentStore/${user.id}`).catch(error=>{
      console.log("abc")
    });
    const Data = await response.json();
    console.log(Data);
    const loaded_data = [];
    for (const key in Data) {
      loaded_data.push({
        url: `${Data[key].url}`,
        store: Data[key].store,
        phone: Data[key].phone,
        address: Data[key].address
      })
      setrecentstores(loaded_data);
    }
    setisloader(false)
    if (!recentstores || recentstores.length === 0) {
      set_error_msg("No Recent Stores Visited.");
    }
    if(!response){
      set_error_msg("No Recent Stores Visited.");
      toast.error("Error Occured. Please try again");
      setisloader(false);
    }
  }



  useEffect(() => {
    GetRecentStores();

  }, [])

  useEffect(() => {
    if (user && user.type === "seller") {
      setIsSeller(true);
    }
  }, [])

  useEffect(() => {
    setstore(user.store);
  }, [])

  return (
    <div>
      <HeaderRetailer hidden={true} store={Store}></HeaderRetailer>
      <Col className="mt-2">
        <div className="ml-[5%] md:ml-[3%] w-fit mt-4">
          <h5 className="font-bold">Recent Visited Suppliers</h5>
          {loader && <Spinner></Spinner>}
          <Row className="mt-4 text-sm">
            {recentstores.map((item, index) => (
              <Col className="cursor-pointer transition-all duration-75" onClick={() => (navigate(item.url))}>
                <div className="border-2 rounded-2xl w-[15pc] hover:shadow-md hover:shadow-sky-200 mb-5">
                  <div className="ml-7 mt-4 mb-4">
                    <div className="flex gap-2 mt-2"><p className=" font-medium">Store Name:</p><p className="font-bold">{item.store}</p></div>
                    <div className="flex gap-2"><p className=" font-medium">Phone:</p><p className="font-bold">{item.phone}</p></div>
                    <div className="flex gap-2"><p className=" font-medium">Address:</p><p className="font-bold" style={{maxWidth:"150px", wordBreak:"break-all"}}>{item.address}</p></div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {recentstores.length === 0 && <p className="text-sm mt-3">{error_msg}</p>}
        </div>
      </Col>
      {IsSeller && <AlertSignedInAs show={IsSeller}></AlertSignedInAs>}
    </div>
  )
}
export default RecentlyVisitedUrls