import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../store/stateprovider";
import { action_type } from "../store/reducer";
import Header from "./Wholeseller/Header";
import BASE_URL from './Config';
import Cookies from "js-cookie";
import { PinInput } from "react-input-pin-code";



function Registration2() {

    const name_ref = useRef("");
    const phone_ref = useRef("");
    const passcode_1 = useRef("");
    const passcode_2 = useRef("");
    const passcode_3 = useRef("");
    const passcode_4 = useRef("");
    const email_ref = useRef("");
    const store_ref = useRef("")
    const address_ref = useRef("");
    const user_as = useRef("")
    const [loader, setloader] = useState(false);

    useEffect(() => {
        try {
            const busniess_data = JSON.parse(Cookies.get("reg_user"));
            store_ref.current = busniess_data.store;
            address_ref.current = busniess_data.address;
            user_as.current = busniess_data.user_as;
        }
        catch (error) {
            navigate("/Registration/:id");
        }
    }, [])


    const [errors, seterrors] = useState(true);
    const [{ user }, dispatch] = useStateValue();

    const phone = localStorage.getItem('phone');

    const initialValues = {
        name: "",
        phone: "",
        passcode_1: "",
        passcode_2: "",
        passcode_3: "",
        passcode_4: "",
        store: "",
        address: ""
    };
    const [formValues, setformValues] = useState(initialValues);
    const [formError, setFormErrors] = useState({});
    const [IsSignup, setisSignUp] = useState(false);
    const [order_id, setOrder_id] = useState();
    const navigate = useNavigate();

    const [values, setValues] = useState(['', '', '', '']);
    passcode_1.current = values[0];
    passcode_2.current = values[1];
    passcode_3.current = values[2];
    passcode_4.current = values[3];

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        } //We need to return it as a dependency
    }, [])

    const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
            adduser();
        }
    }

    const SetPhoneInLocalStorage = (phone_no) => {
        const phone = localStorage.getItem("phone");
        if (phone) {
            localStorage.removeItem("phone");
        }
        localStorage.setItem("phone", phone_no);
    };

    const SetOTPInLocalStorage = (OTP) => {
        const otp = localStorage.getItem("OTP");
        if (otp) {
            localStorage.removeItem("OTP");
        }
        localStorage.setItem("OTP", OTP);
    }

    const send_code = async () => {
        var digits = '0123456789';
        var OTP = '';
        let phone_no_otp = phone_ref.current.value;
        let phone_no = phone_ref.current.value;
        
        if (phone_no.charAt(0) === "+92") {
            phone_no = "0" + phone_no.slice(1);
        }
        if (phone_no.charAt(0) === "0") {
            phone_no_otp = "92" + phone_no.slice(1);
        }

        for (var i = 0; i < 4; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        try {
            SetOTPInLocalStorage(OTP)
            if (user_as.current === "retailer") {
                const retailer_data = {
                    fullName: name_ref.current.value,
                    email: email_ref.current.value,
                    phone: phone_no,
                    store: store_ref.current,
                    address: address_ref.current,
                    password: passcode_1.current + passcode_2.current + passcode_3.current + passcode_4.current
                };
                //Cookies.set("retailer",JSON.stringify(retailer_data),{expires:2});
                localStorage.setItem("retailer",JSON.stringify(retailer_data));
            }
            if (user_as.current === "wholeseller"){
                const wholeseller_data = {
                    full_name: name_ref.current.value,
                    busniess_name: store_ref.current,
                    address: address_ref.current,
                    email: email_ref.current.value,
                    phone: phone_no,
                    password: passcode_1.current + passcode_2.current + passcode_3.current + passcode_4.current,
                    url: store_ref.current
                };
                //Cookies.set("wholeseller",JSON.stringify(wholeseller_data),{expires:2});
                localStorage.setItem("wholeseller",JSON.stringify(wholeseller_data));
            }
            //LKN6WHCDD0CMKDXC6DY4
            const apiKey = "LKN6WHCDD0CMKDXC6DY4";
            const phoneNumber = `${phone_no_otp}`;
            const message = `Your OTP is ${OTP}`;


            const response = await fetch(`https://panel.rapiwha.com/send_message.php?apikey=${apiKey}&number=${phoneNumber}&text=${message}`, {
                method: 'GET'
            });
            const Data = await response.json();
            console.log(Data);
            if(Data.success === true){
                SetPhoneInLocalStorage(phone_no);
                navigate("/RegistrationOTPVerification");
            }
        }
        catch (error) {
            toast.error("Please Try Again");
            setloader(false);
        }
    }

    const changehandler = (e) => {
        const { name, value } = e.target;
        setformValues({ ...formValues, [name]: value });
    };

    const validate = () => {
        const errors = {};
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phone_regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        const name_regex = /^[a-zA-Z\s]+$/;

        if (!name_ref.current.value) {
            errors.name = "Full Name is Required";
            // } else if (!regex.test(email_ref.current.value)) {
            //   errors.email = "Enter a Valid Email address";
        }
        else if (!name_regex.test(name_ref.current.value)) {
            errors.name = "Alphabets only";
            // } else if (!regex.test(email_ref.current.value)) {
            //   errors.email = "Enter a Valid Email address";
        }

        if (!phone_ref.current.value) {
            errors.phone = "Phone No is Required";
        }
        else if (!phone_regex.test(phone_ref.current.value)) {
            errors.phone = "Enter a Valid Phone No";
        }
        else if (phone_ref.current.value.length < 11) {
            errors.phone = "Enter a Valid Phone No";
        }

        if (passcode_1.current === '' || passcode_2.current === '' || passcode_3.current === '' || passcode_4.current === '') {
            errors.password = "Passcode is Required";
        }
        return errors;
    };

    useEffect(() => {
        const errors_count = Object.keys(formError).length;
        if (errors_count === 0) {
            seterrors(false);
        }
        if (errors_count > 0) {
            seterrors(true);
        }
    }, [formError])

    const adduser = async () => {
        setloader(true);
        setFormErrors(validate(formValues));
        const errors_count = Object.keys(formError).length;
        const check_errors = validate(formValues);
        const check_errors_count = Object.keys(check_errors).length;
        if (check_errors_count > 0) {
            setloader(false)
            return toast.error("Please Enter all the required Fields");
        }

        let phone_no = phone_ref.current.value;
        if (phone_no.charAt(0) === "+92") {
            phone_no = "0" + phone_no.slice(1);
        }
        SetPhoneInLocalStorage(phone_no);

        const retailer_data = {
            fullName: name_ref.current.value,
            email: email_ref.current.value,
            phone: phone_no,
            store: store_ref.current,
            address: address_ref.current,
            password: values[0] + values[1] + values[2] + values[3]
        };

        const wholeseller_data = {
            full_name: name_ref.current.value,
            busniess_name: store_ref.current,
            address: address_ref.current,
            email: email_ref.current.value,
            phone: phone_no,
            password: values[0] + values[1] + values[2] + values[3],
            url: store_ref.current
        };

        const response_retailer = await fetch(`${BASE_URL}/Retailers/OTP/${phone_no}`).catch(error => {
            console.log("abc")
        });
        if (!response_retailer) {
            setloader(false);
            return toast.error("Error Occured. Please try again");
        }

        if (response_retailer.status === 200) {
            setloader(false);
            return toast.error("Phone No Already Registered. Please SignIn");
        }

        const response_wholeseller = await fetch(`${BASE_URL}/WholesellerControllerSignUp/OTP/${phone_no}`).catch(error => {
            console.log("abc")
        });
        if (!response_wholeseller) {
            setloader(false);
            return toast.error("Error Occured. Please try again");
        }
        
        if (response_wholeseller.status === 200) {
            setloader(false);
            return toast.error("Phone No Already Registered. Please SignIn");
        }


        if (user_as.current === "retailer") {
            const response = await fetch(`${BASE_URL}/Retailers`, {
                method: "POST",
                body: JSON.stringify(retailer_data),
                headers: { "Content-Type": "application/json" },
            }).catch(error => {
                console.log("abc")
            });
            if (!response) {
                toast.error("Error Occured. Please try again");
                setloader(false);
            }

            const data = await response.json();
            console.log(data);
            toast.success("Retailer Registered Successfully");
            //localStorage.removeItem("retailer");
            // localStorage.setItem("retailer",JSON.stringify(retailer_data));
            // send_code(phone_no);
            // navigate("/RegistrationOTPVerification")
            Cookies.remove("reg_user");
            Clear()
            navigate("/registeredsuccessfully");
        }
        if (user_as.current === "wholeseller") {
            

            const response = await fetch(
                `${BASE_URL}/WholesellerControllerSignUp`,
                {
                    method: "POST",
                    body: JSON.stringify(wholeseller_data),
                    headers: { "Content-Type": "application/json" },
                }
            ).catch(error => {
                console.log("abc")
            });
            if (!response) {
                toast.error("Error Occured. Please try again");
                setloader(false);
            }

            const data = await response.json();
            console.log(data);
            if (response.status === 400) {
                setloader(false);
                return toast.error("Store Name Already Registered, Please Try a different Store Name");
            }
            toast.success("Wholeseller Registered Successfully");
            localStorage.removeItem("wholeseller");
            // localStorage.setItem("wholeseller",JSON.stringify(wholeseller_data));
            // send_code(phone_no);
            // navigate("/RegistrationOTPVerification")
            Clear();
            await Cookies.remove("reg_user");
            navigate("/registeredsuccessfully");
        }
    };

    const Clear = () => {
        name_ref.current.value = "";
        phone_ref.current.value = "";
        setValues(['', '', '', '']);
        email_ref.current.value = "";
    };

    const send_OTP = () => {
        setloader(true);
        setFormErrors(validate(formValues));
        const errors_count = Object.keys(formError).length;
        const check_errors = validate(formValues);
        const check_errors_count = Object.keys(check_errors).length;
        if (check_errors_count > 0) {
            setloader(false)
            return toast.error("Please Enter all the required Fields");
        }
        send_code();
    }



    const handleFocus = (event) => {
        event.target.select();
    }



    return (
        <div className="overflow-hidden">
            <ToastContainer></ToastContainer>
            <Header></Header>
            <Row style={{ marginLeft: "2.5%", marginTop: "1%" }}>
                <Col md={9} xs={7} className="mt-2">
                    <div className="breadcrum flex">
                        <h3 className="text-2xl font-bold text-black mt-1 Gilroy-Bold">Registration</h3>
                        {/* <div style={{ marginLeft: "30%" }} className="flex gap-2 mt-2 Poppins">
              <h6 className="text-black">Already have an account?</h6>
              <p onClick={() => (navigate("/"))} className="active cursor-pointer hover:underline ease-in-out">
                Login
              </p>
            </div> */}
                    </div>
                    <div className="flex gap-3 mt-3 mb-3">
                        <div onClick={() => navigate("/Registration/:id")} className="text-[#0A9A73] opacity-60 cursor-pointer hover:underline">Busniess Details</div>
                        <div className=" h-0 w-[100%] md:w-[5%] mt-[5%] md:mt-[1%] border-black border-solid border"></div>
                        <div className="font-bold text-[#0A9A73] cursor-pointer hover:underline">Personal Details</div>
                    </div>
                    <Form>
                        <Form.Group className="w-275 md:w-375 mt-1 Poppins text-sm">
                            <div className="flex gap-10 md:gap-32">
                                <Form.Label className=" ml-3 text-slate-500">Full Name</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.name}</Form.Label>
                            </div>
                            <Form.Control
                                style={{ border: "1px solid #333333" }}
                                className=" mb-3 -mt-1 text-slate-500" ref={name_ref} onChange={changehandler}>
                            </Form.Control>

                            <div className="flex gap-20 md:gap-32">
                                <Form.Label className="ml-3 text-slate-500">Phone</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.phone}</Form.Label>
                            </div>
                            <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-3 -mt-1 text-slate-500" ref={phone_ref}></Form.Control>

                            <div className="flex gap-20 md:gap-32">
                                <Form.Label className="ml-3 text-slate-500">Email <span className="text-xs">(Optional)</span></Form.Label>
                            </div>
                            <Form.Control onChange={changehandler} style={{ border: "1px solid #333333" }} className=" mb-3 -mt-1 text-slate-500" ref={email_ref}></Form.Control>


                            <div className="flex gap-4">
                                <Form.Label className="ml-3 text-slate-500">Set a Pin Code</Form.Label>
                                <Form.Label className=" text-red-600 ml-3 text-xs">{formError.password}</Form.Label>
                            </div>
                            <Row style={{ width: "50%" }} className="passcode mb-3">
                                {/* <div className="flex  gap-3 ">
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800", fontSize: "25px" }} id="input1" className="text-center -mt-1" ref={passcode_1}></Form.Control>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800", fontSize: "25px" }} id="input2" className="text-center -mt-1" ref={passcode_2}></Form.Control>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800", fontSize: "25px" }} id="input3" className="text-center -mt-1" ref={passcode_3} ></Form.Control>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control type="password" minLength={1} maxLength={1} onChange={changehandler} style={{ border: "1px solid #333333", fontWeight: "800", fontSize: "25px" }} id="input4" className="text-center -mt-1" ref={passcode_4}></Form.Control>
                  </Col>
                </div> */}
                                <PinInput onFocus={handleFocus} values={values} onChange={(value, index, values) => setValues(values)} focusBorderColor="rgb(13,110,253, 0.7)" validate={false} showState={false} mask={true} placeholder="" size="lg" inputStyle={{ border: "1px solid #666666", fontSize: "20px" }} inputClassName="bg-white font-[900]" containerClassName="gap-3"></PinInput>
                            </Row>

                            {loader ? <Button className="submit-button mt-3 mb-4"><Spinner ></Spinner></Button> :
                                <Button onClick={adduser} className="submit-button mt-3 mb-4">Register</Button>
                            }
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
export default Registration2;
