import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderRetailer from "./HeaderRetailer";
import { useParams } from "react-router-dom";
import "../Style/main.css";
import view_icon from "../../images/view-icon.svg"
import OrderSummary from "./OrderSummary";
import AlertSignedInAs from "./AlertSignedInAS";
import "../Style/CRUD.css";
import BASE_URL from '../Config';
import { useStateValue } from "../../store/stateprovider";


function OrdersRetailer() {
  const [orders, setOrders] = useState([]);
  const { url } = useParams();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [loader, setloader] = useState(null);
  const [showCartSummary, setCartSummary] = useState(false);
  const [order_Data, setOrder_Data] = useState({});
  const [IsSeller, setIsSeller] = useState(false);
  // const user = JSON.parse(localStorage.getItem("user"))
  const [{user}] = useStateValue();
  const [mobileView, setMobileView] = useState(false);
  const [error_msg, set_error_msg] = useState("");
  const [Store, setstore] = useState("");

  const [search, setsearch] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const recordsPerPage = 50;
  const npage = Math.ceil(orders.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  let lastIndex = currentPage * recordsPerPage;
  let firstIndex = lastIndex - recordsPerPage;
  let records = orders.slice(firstIndex, lastIndex);

   //Search 
  const [searchList, setSearchList] = useState([]);
  const search_ref = useRef();
 
  const searchHandler = () => {
    if (search_ref.current.value !== "") {
      const newList = orders.filter((d) => {
        return Object.values(d)
          .join(" ")
          .toLowerCase()
          .includes(search_ref.current.value.toLowerCase());
      });
      setSearchList(newList);
      setsearch(true);
    } else {
      get_data();
      setsearch(false);
    }
  }

  //Pagination
  const ChangeNpage = (id) => {
    setcurrentPage(id);
    window.scrollTo({
      top:0,
      behavior: "smooth"
    })
  }

  const NextPage = () => {
    if (currentPage !== npage) {
      setcurrentPage(currentPage + 1);
    }
  }

  const PrevPage = () => {
    if (currentPage !== 1) {
      setcurrentPage(currentPage - 1);
    }
  }

  useEffect(() => {
    if (user && user.type === "seller") {
      setIsSeller(true);
    }
  }, [])

  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setMobileView(true);
      } // Set breakpoint here
      if (window.innerWidth > 500) {
        setMobileView(false);
      }
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const get_data = async () => {
    setloader(true);
    const response = await fetch(
      `${BASE_URL}/OrdersCategory/${user.id}`
    ).catch(error=>{
      console.log("abc")
    });
    if(!response) {
      setloader(false);
      toast.error("Error Occured, Try again");
    }
    if (response.status === 200) {
      try {
        const Data = await response.json();
        const loaded_data = [];
        for (const key in Data) {
          loaded_data.push({
            id: Data[key].id,
            retailer_name: Data[key].retailer_name,
            Wholeseller_id: Data[key].wholeseller_id,
            Total: Data[key].total,
            totalItems: Data[key].totalItems,
            status: Data[key].status,
            Order_Date: Data[key].order_Date,
            seller_store: Data[key].seller_store,
            store: Data[key].store,
            products: Data[key].products,
            quantity: Data[key].quantity,
            phone_no: Data[key].phone_no,
            seller_phone_no: Data[key].seller_phone_no,
            address: Data[key].address
          });
        }
        // loaded_data.sort((a, b)=>b.id - a.id);
        setOrders(loaded_data);
      }
      catch (error) {
        setloader(false)
      }
    }
    setloader(false);
    if(!orders || orders.length === 0){
      set_error_msg("No Orders have been Placed");
    }
    if(response.status !== 200) {
      toast.error("PIN does'nt Match, Try again");
    }
  };

  useEffect(() => {
    get_data();
  }, []);

  useEffect(()=>{
    setstore(user.store);
  },[])

  const CloseHandler = () => {
    setCartSummary(false);
  }

  const CartSummaryHandler = (id, retailer_name, store, phone_no, address, total,order_date) => {
    const date = new Date(order_date);
    const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
    setCartSummary(true)
    const data = {
      id: id,
      retailer_name: retailer_name,
      seller_store: store,
      phone_no: phone_no,
      address: address,
      total: total,
      order_date: formattedDate,
    }
    setOrder_Data(data)
  }
  return (
    // <table className="order-table" striped>
    //           <thead style={{backgroundColor:"#CCEAFF"}}>
    //             <tr>
    //               {/* <th >Order No</th> */}

    //               <th >Order No</th>
    //               <th >Store</th>
    //               <th >Name</th>
    //               <th >Phone No.</th>
    //               <th >T.P</th>
    //               <th >Status</th>
    //               <th >Action</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {orders && orders.map((item, index) => (
    //               <tr key={index}>
    //                 {/* <td>{item.id}</td> */}

    //                 <td data-label="Order No">{item.id}</td>
    //                 <td data-label="Store">{item.seller_store}</td>
    //                 <td data-label="Name">{item.retailer_name}</td>
    //                 <td data-label="Phone No.">{item.phone_no}</td>
    //                 <td data-label="T.P">${item.Total}</td>
    //                 <td data-label="Status">{item.status}</td>
    //                 <td data-label="Action">
    //                   <div onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.seller_store, item.phone_no, item.address, item.Total)} className="flex gap-2">
    //                     <img
    //                       className=" ml-[85%] mt-3 cursor-pointer"
    //                       src={view_icon}
    //                     ></img>
    //                   </div>
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    <Fragment>
      <HeaderRetailer hidden={true} store={Store}></HeaderRetailer>
      <Row style={{ fontFamily: "Poppins", marginLeft: "1%", marginRight: "1.5%" }}>
        <Col md={9} className="ml-[2%] md:ml-[1.5%]">
          <div className="mt-4 mb-4">
            <h3 className="text-2xl font-bold">Orders</h3>
          </div>
          <div>
            <Form.Group>
              <div class="flex">
                <Form.Control
                  style={{
                    height: "50px",
                    marginBottom: "1.5%",
                  }}
                  class="search"
                  placeholder="Search"
                  onChange={searchHandler}
                  ref={search_ref}
                />
              </div>
            </Form.Group>
          </div>
          <div className=" text-xs md:text-sm" style={{ fontFamily: "Poppins" }}>
          {!mobileView && !search && (<Table className="text-center">
              <thead >
                <tr>
                  <th>Id</th>
                  <th>Store</th>
                  <th>Username</th>
                  <th>Phone</th>
                  <th>T.P</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders && records.map((item, index) => (
                  <tr key={index}>
                    <td data-label="No">{item.id}</td>
                    <td data-label="Store">{item.seller_store}</td>
                    <td data-label="Username">{item.retailer_name}</td>
                    <td data-label="Phone">{item.phone_no}</td>
                    <td data-label="T.P">Rs. {item.Total}/-</td>
                    <td data-label="Status">{item.status}</td>
                    <td data-label="Action">
                      <div className="flex gap-2 mt-3 action-buttons justify-center">
                        <img
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.seller_store, item.phone_no, item.address, item.Total, item.Order_Date)}
                          className=" cursor-pointer"
                          src={view_icon}
                        ></img>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>)}
            {mobileView && !search && <div className=" overflow-x-hidden">
              {orders && records.map((item, index) => (
                <Table className="mb-4">
                  <div className=" border">
                    <div className="flex gap-[40%] ml-1 mr-2 mt-3">
                      <div className="flex gap-2 border-0">
                        <b>Store: </b>
                        <p className=" text-sky-700 font-medium">{item.seller_store}</p>
                      </div>
                      <div className="flex gap-3 mb-3 border-0" >
                        <img
                          className=" cursor-pointer"
                          src={view_icon}
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.seller_store, item.phone_no,  item.address, item.Total, item.Order_Date)}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="border">
                    <Row >
                      <Col xs={6} style={{ borderRight: "2px solid #dee2e6", marginLeft: "6px", paddingTop: "20px" }}>
                        <div className="flex m-2 gap-2 ">
                          <b>Phone: </b>
                          <p>{item.phone_no}</p>
                        </div>
                        <div className="flex m-2 gap-2">
                          <b>Status: </b>
                          {item.status === "Pending" && (<p className=" text-yellow-400">{item.status}</p>)}
                          {item.status === "Cancel" && (<p className=" text-red-500">{item.status}</p>)}
                          {item.status === "Shipped" && (<p className=" text-green-600">{item.status}</p>)}
                          {item.status === "Delivered" && (<p className=" text-green-500">{item.status}</p>)}
                        </div>
                      </Col>
                      <Col style={{ paddingTop: "20px" }}>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>Total Price: </b>
                          <p>Rs. {item.Total}/-</p>
                        </div>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>No. of Items: </b>
                          <p>{item.totalItems}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Table>
              ))}
            </div>
            }


            {!mobileView && search && (<Table className="text-center" >
              <thead >
                <tr>
                  <th>No</th>
                  <th>Store</th>
                  <th>Username</th>
                  <th>Phone</th>
                  <th>T.P</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders && searchList.map((item, index) => (
                  <tr key={index}>
                    <td data-label="No">{item.id}</td>
                    <td data-label="Store">{item.seller_store}</td>
                    <td data-label="Username">{item.retailer_name}</td>
                    <td data-label="Phone">{item.phone_no}</td>
                    <td data-label="T.P">Rs. {item.Total}/-</td>
                    <td data-label="Status">{item.status}</td>
                    <td data-label="Action">
                      <div className="flex gap-2 mt-3 action-buttons justify-center">
                        <img
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.phone_no, item.seller_store, item.address, item.Total, item.Order_Date)}
                          className=" cursor-pointer"
                          src={view_icon}
                        ></img>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>)}
            {mobileView && search && <div className=" overflow-x-hidden">
              {orders && searchList.map((item, index) => (
                <Table className="mb-4">
                  <div className=" border">
                    <div className="flex gap-[40%] ml-1 mr-2 mt-3">
                      <div className="flex gap-2 border-0">
                        <b>Store: </b>
                        <p className=" text-sky-700 font-medium">{item.seller_store}</p>
                      </div>
                      <div className="flex gap-3 mb-3 border-0" >
                        <img
                          className=" cursor-pointer"
                          src={view_icon}
                          onClick={() => CartSummaryHandler(item.id, item.retailer_name, item.phone_no, item.seller_store, item.address, item.Total, item.Order_Date)}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="border">
                    <Row >
                      <Col xs={6} style={{ borderRight: "2px solid #dee2e6", marginLeft: "6px", paddingTop: "20px" }}>
                        <div className="flex m-2 gap-2 ">
                          <b>Phone: </b>
                          <p>{item.phone_no}</p>
                        </div>
                        <div className="flex m-2 gap-2">
                          <b>Status: </b>
                          {item.status === "Pending" && (<p className=" text-yellow-400">{item.status}</p>)}
                          {item.status === "Cancel" && (<p className=" text-red-500">{item.status}</p>)}
                          {item.status === "Shipped" && (<p className=" text-green-600">{item.status}</p>)}
                          {item.status === "Delivered" && (<p className=" text-green-500">{item.status}</p>)}
                        </div>
                      </Col>
                      <Col style={{ paddingTop: "20px" }}>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>Total Price: </b>
                          <p>Rs. {item.Total}/-</p>
                        </div>
                        <div className="flex mt-2 ml-1 gap-2">
                          <b>No. of Items: </b>
                          <p>{item.totalItems}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Table>
              ))}
            </div>
            }

            <p>{orders.length === 0 && error_msg}</p>
            {loader && <Spinner></Spinner>}
          </div>

          {!search &&
          <ul className="pagination justify-center ">
            <li className="page-item cursor-pointer">
              <a href="#" className="page-link" onClick={PrevPage}>Prev</a>
            </li>
            {
              numbers.map((item, index) => (
                <li onClick={() => ChangeNpage(item)} className={`page-link cursor-pointer ${currentPage === item ? 'active' : ''}`}>
                  {item}
                </li>
              ))
            }
            <li className="page-item cursor-pointer">
              <a href="#" className="page-link" onClick={NextPage}>Next</a>
            </li>
          </ul>
          }
        </Col>
      </Row>
      {showCartSummary && <OrderSummary data={order_Data} show={showCartSummary} handleClose={CloseHandler}></OrderSummary>}
      {IsSeller && <AlertSignedInAs show={IsSeller}></AlertSignedInAs>}
    </Fragment>
  );
}
export default OrdersRetailer;
