import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import pic from "../../images/heropic.jpg";
import { action_type } from "../../store/reducer";
import { useStateValue } from "../../store/stateprovider";
import HeaderRetailer from "./HeaderRetailer";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import { AiOutlineCloseCircle } from "react-icons/ai";
import search_icon from "../../images/ri_search-line.svg";
import "../Style/CRUD.css";
import Cart from "./Cart";
import CartSummary from "./CartSummary";
import CartMobile from "./CartMobile";
import QuantityModal from "./quantityModal";
import AlertSignedInAs from "./AlertSignedInAS";
import BASE_URL from '../Config';
import SellerInfoModal from "./SellerInfoModal";


function Home(props) {
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const [{ user, cartItems }, dispatch] = useStateValue();
  const { url } = useParams();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [quantity, setquantity] = useState(1);
  const [flag, setflag] = useState(0);
  const [loader, setloader] = useState(null);
  const [showCartSummary, setCartSummary] = useState(false);
  const [cartmobile, setcartmobile] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [IsSeller, setIsSeller] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [quantityModal, setquantityModal] = useState(false);
  const [quantityModalItem, setquantityModalItem] = useState([]);
  const [error_msg, set_error_msg] = useState("");
  const [seller_info, setSellerInfo] = useState(false);
  const [id, setid] = useState();
  const dummy = [];

  useEffect(() => {
    if (user && user.type === "seller") {
      setIsSeller(true);
    }
  }, [])

  useEffect(() => {
    if (url === "undefined" || url === "${store}") {
      set_error_msg("Please Enter the URL in the Browser");
    }
  }, [])

  const SaveStore = async () => {
    const path = window.location.pathname;
    const response_wholeseller = await fetch(`${BASE_URL}/WholesellerCategory/url/${url}`).catch(error => {
      console.log("abc");
    });
    const wholeseller_data = await response_wholeseller.json()

    const recentstore = {
      "retailer_id": user.id,
      "url": path,
      "store": wholeseller_data.busniess_name,
      "phone": wholeseller_data.phone,
      "address": wholeseller_data.address
    }

    const response = await fetch(`${BASE_URL}/RecentStore`,
      {
        method: "POST",
        body: JSON.stringify(recentstore),
        headers: { "Content-Type": "application/json" },
      }
    ).catch(error => {
      console.log("abc");
    })
  }

  useEffect(() => {
    SaveStore();
  }, [])


  const navigate = useNavigate();

  const email_ref = useRef();
  const phone_ref = useRef();
  const search_ref = useRef("");
  const quantity_ref = useRef("");

  const setMessage = () => {
    setcartmobile(true);
  }

  useEffect(() => {
    const tableContainer = document.querySelector('.table-scroll');

    // Check if the table overflows its container element
    if (tableContainer.scrollHeight > tableContainer.clientHeight) {
      // Show the scroll bar
      tableContainer.style.overflowY = 'scroll';
      tableContainer.style.maxHeight = "70%";
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setcartmobile(false);
        setShowCart(true);
      } // Set breakpoint here
      if (window.innerWidth <= 500) {
        setMobileView(true);
      } // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setShowCart(false);
        const tableContainer = document.querySelector('.table-scroll');
        tableContainer.style.overflowY = 'visible';
        tableContainer.style.maxHeight = "100%";
      } // Set breakpoint here
      if (window.innerWidth >= 500) {
        setMobileView(false);
      }
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const cartDispatch = () => {
    localStorage.setItem("cartItems", JSON.stringify(items));
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: items,
    });
  };

  const CloseHandler = () => {
    setCartSummary(false);
    setcartmobile(false);
    setquantityModal(false);
    setSellerInfo(false);
  }

  const remove_item = (id) => {
    items = cartItems.filter((item) => item.id !== id);
    props.setFlag(props.flag + 1);
    cartDispatch();
  };

  const get_data = async () => {
    setloader(true);
    const response_id = await fetch(`${BASE_URL}/WholesellerCategory/wholeseller/${url}`).catch(error => {
      console.log("abc")
    });
    if (!response_id) {
      toast.error("Error Occured. Please try again");
      set_error_msg("Error Occured. Please try again");
      setloader(false);
    }
    const Id = await response_id.text();
    setid(Id);

    const response = await fetch(
      `${BASE_URL}/WholesellerCategory/${url}/${Id}`
    ).catch(error => {
      console.log("abc")
    });
    if (!response) {
      toast.error("Error Occured. Please try again");
      setloader(false);
    }
    if (response.status === 200) {
      const Data = await response.json();
      const loaded_data = [];
      for (const key in Data) {
        loaded_data.push({
          id: Data[key].id,
          product_name: Data[key].product_name,
          manufacturer: Data[key].manufacturer,
          offer: Data[key].offer,
          quantity: 1,
          bonus: Data[key].bonus,
          total_price: Data[key].total_price,
          product_code: Data[key].product_code
        });
      }
      setData(loaded_data);
    }
    setloader(false)
    if (!data || data.length === 0) {
      set_error_msg("No Data Found");
    }
    if (response.status !== 200) {
      toast.error("PIN does'nt Match, Try again");
    }

  };

  useEffect(() => {
    get_data();
  }, []);


  const addqty = (id) => {
    data.map((item) => {
      if (item.id === id) {
        setquantity(quantity + 1);
        item.quantity++;
        flag(1);
      }
    });
  };

  const subqty = (id) => {
    data.map((item) => {
      if (item.id === id) {
        if (item.quantity > 1) {
          setquantity(quantity - 1);
          item.quantity--;
        }
      }
    });
  };

  const QuantityModalController = (item) => {

    setquantityModal(true);
    setquantityModalItem(item);
  }

  const addtoCart = (item) => {
    // const existingCartItemIndex = cartItems.findIndex(
    //   (item) => item.id === item.id
    // );
    // const existingCartItem = items[existingCartItemIndex];
    const cart = [];
    for (const key in items) {
      const product_data = {
        id: items[key].id + 1,
        product_name: items[key].product_name,
        manufacturer: items[key].manufacturer,
        offer: items[key].offer,
        quantity: items[key].quantity,
        bonus: items[key].bonus,
        total_price: items[key].total_price,
      }
      cart.push(product_data);
    }
    dispatch({
      type: action_type.SET_CART_ITEMS,
      cartItems: cart,
    });
    localStorage.setItem("cartItems", JSON.stringify(cart));
  };

  useEffect(() => {
    addtoCart();
  }, [items]);

  //caterizing by manufacturer
  const categories = new Set();
  for (const key in data) {
    categories.add(data[key].manufacturer);
  }

  let filter_items = [];

  const filter_categories = [];
  for (const i of categories) {
    filter_categories.push({ cat: i });
    filter_items.push(data.filter((f) => f.manufacturer === i));
  }

  const searchHandler = () => {
    if (search_ref.current.value !== "") {
      const newList = data.filter((d) => {
        return Object.values(d)
          .join(" ")
          .toLowerCase()
          .includes(search_ref.current.value.toLowerCase());
      });
      setSearchList(newList);
    } else {
      get_data();
    }
  };

  const checkout = async () => {
    //  const usr = await localStorage.getItem("user");
    if (cartItems.length !== 0) {
      if (user) {
        // navigate(`/loginCheckout/${id}`);
        setCartSummary(true);
      }
      else {
        navigate(`/phoneVerification/${url}`);
      }
    }
    else {
      toast.error("No Items Added to Cart");
    }
  };

  const set_seller_info = () => {
    setSellerInfo(true);
  }





  return (
    // <Fragment>
    //   <HeaderRetailer></HeaderRetailer>
    //   <br></br>
    //   <h1>Products Wholeseller {id}</h1>
    //   <Container>
    //     <Row style={{ gap: "5px" }}>
    //       {data && data.length > 0 ? (
    //         data.map((item, index) => (
    //           <Card
    //             key={index}
    //             style={{ position: "relative", width: "14rem" }}
    //           >
    //             <Card.Img variant="top" src={pic} />
    //             <Card.Body>
    //               <Card.Title>{item.product_name}</Card.Title>
    //               <Card.Text>
    //                 Some quick example text to build on the card title and make
    //                 up the bulk of the card's content.
    //               </Card.Text>
    //             </Card.Body>
    //             <ListGroup className="list-group-flush">
    //               <ListGroup.Item>
    //                 <b>Manufacturer: </b>
    //                 {item.manufacturer}
    //               </ListGroup.Item>
    //               <ListGroup.Item>
    //                 <b>Unit Price: </b>${item.unit_price}
    //               </ListGroup.Item>
    //               <ListGroup.Item>
    //                 <b>Discount: </b>
    //                 {item.discount}%
    //               </ListGroup.Item>
    //               <ListGroup.Item>
    //                 <b>Expiry Date: </b>
    //                 {item.expiryDate}
    //               </ListGroup.Item>
    //             </ListGroup>
    //             <Card.Body>
    //               <Card.Link href="#">
    //                 <Button onClick={() => setItems([...cartItems, item])}>Add To Cart</Button>
    //               </Card.Link>
    //             </Card.Body>
    //           </Card>
    //         ))
    //       ) : (
    //         <h1>Loading...</h1>
    //       )}
    //     </Row>
    //   </Container>
    // </Fragment>
    // <Fragment>
    //   <ToastContainer></ToastContainer>
    // <div className="dashboard-container" style={{ fontFamily: "Poppins" }}>
    //   <SideBar></SideBar>
    //   <div className="dashboard-content">
    //     {user ? <DashboardHeader></DashboardHeader> : <DashboardHeader btnText={"Sign In"} onClick={() => navigate('/SignIn')} ></DashboardHeader>}
    //     <div className="dashboard-content-container">
    //       <div className="dashboard-content-header">
    //         <h2>Place Order</h2>
    //       </div>
    //       <MDBContainer style={{ fontFamily: "Poppins", overflowY: "hidden" }}>
    //         <MDBRow className="justify-content-center align-items-center max-w-2xl m-auto">
    //           <MDBCol
    //             md="6"
    //             style={{ marginLeft: "auto", marginRight: "auto" }}
    //           >
    //             <MDBInput
    //               wrapperClass="mb-1"
    //               label="Email"
    //               size="md"
    //               id="form1"
    //               type="email"
    //               ref={email_ref}
    //             />
    //           </MDBCol>

    //           <MDBCol
    //             md="6"
    //             style={{ marginLeft: "auto", marginRight: "auto" }}
    //           >
    //             <MDBInput
    //               wrapperClass="mb-1"
    //               label="Phone Number"
    //               size="md"
    //               id="form4"
    //               type="number"
    //               ref={phone_ref}
    //             />
    //           </MDBCol>
    //         </MDBRow>
    //         <MDBRow>
    //           <MDBInput
    //             wrapperClass="mb-2"
    //             placeholder="Search Your Product"
    //             ref={search_ref}
    //             onChange={searchHandler}
    //           ></MDBInput>
    //         </MDBRow>

    //         {search_ref.current.value < 1 ? (
    //           <div className="justify-center overflow-x-scroll flex gap-3">
    //             {data.map((item, index) => (
    //               <MDBCol md="2" key={index}>
    //                 <MDBCard className="text-black">
    //                   <MDBCardImage
    //                     src={pic}
    //                     position="top"
    //                     alt="Apple Computer"
    //                     style={{ width: "100px", margin: "auto" }}
    //                   />
    //                   <h6 className="m-auto">Name: {item.product_name}</h6>
    //                   <p3 className="m-auto">Unit Price: ${item.unit_price}</p3>

    //                   <MDBInput
    //                     wrapperClass="mb-2 mt-2"
    //                     placeholder="Enter Quantity"
    //                     type="number"
    //                     required
    //                     ref={quantity_ref}

    //                   ></MDBInput>

    //                   <Button
    //                     className="btn btn-primary"
    //                     onClick={() => setItems([...cartItems, item])}
    //                   >
    //                     Add
    //                   </Button>
    //                 </MDBCard>
    //               </MDBCol>
    //             ))}
    //           </div>
    //         ) : (
    //           <div className="justify-center overflow-x-scroll flex gap-3">
    //             {searchList.map((item, index) => (
    //               <MDBCol md="2" key={index}>
    //                 <MDBCard className="text-black">
    //                   <MDBCardImage
    //                     src={pic}
    //                     position="top"
    //                     alt="Apple Computer"
    //                     style={{ width: "100px", margin: "auto" }}
    //                   />
    //                   <h6 className="m-auto">Name: {item.product_name}</h6>
    //                   <p3 className="m-auto">Unit Price: ${item.unit_price}</p3>

    //                   <MDBInput
    //                     wrapperClass="mb-2 mt-2"
    //                     placeholder="Enter Quantity"
    //                     type="number"
    //                     max="1000"
    //                     required
    //                   ></MDBInput>

    //                   <Button
    //                     className="btn btn-primary"
    //                     onClick={() => setItems([...cartItems, item])}
    //                   >
    //                     Add
    //                   </Button>
    //                 </MDBCard>
    //               </MDBCol>
    //             ))}
    //           </div>
    //         )}
    //         {search_ref.current.value &&  searchList.length === 0 && <p>No Data Found</p>}
    //         {data.length === 0 && <p>No Data Found</p>}

    //         {/* <MDBContainer>
    //           {cartItems && cartItems.length > 0 ? (
    //             cartItems.map((item, index) => (
    //               <MDBCard
    //                 className="rounded-3 mt-5"
    //                 style={{ height: "100px" }}
    //                 key={index}
    //               >
    //                 <MDBCardBody className="p-4 -mt-6">
    //                   <MDBRow className="justify-content-between align-items-center">
    //                     <MDBCol>
    //                       <MDBCardImage
    //                         className="rounded-3 w-20"
    //                         src={pic}
    //                         alt="Medicine"
    //                       />
    //                     </MDBCol>
    //                     <MDBCol md="3" style={{ fontSize: "15px" }}>
    //                       <p className="lead fw-normal mb-2">
    //                         {item.product_name}
    //                       </p>
    //                       <p>
    //                         <span className="text-muted">
    //                           Unit Price: {item.unit_price}
    //                         </span>
    //                         <br></br>
    //                         <span className="text-muted">
    //                           Quantity: {item.quantity}
    //                         </span>
    //                       </p>
    //                     </MDBCol>
    //                     <MDBCol className="d-flex align-items-center justify-content-around"></MDBCol>
    //                     <MDBCol>
    //                       <h5>$Total</h5>
    //                     </MDBCol>
    //                     <MDBCol className="text-end">
    //                       <AiOutlineCloseCircle onClick={() => remove_item(item.id)} className="cursor-pointer"></AiOutlineCloseCircle>
    //                     </MDBCol>
    //                   </MDBRow>
    //                 </MDBCardBody>
    //               </MDBCard>
    //             ))
    //           ) : (
    //             <h5>No Items Added</h5>
    //           )}
    //         </MDBContainer> */}
    //         <Button onClick={() => (setShowCart(true))}>View Added Items</Button>
    //       </MDBContainer>
    //       {/* {data.length !== 0 ? (
    //         <div className="dashboard-content-footer">
    //           {pagination.map((item, index) => (
    //             <span
    //               key={index}
    //               className={item === page ? "active-pagination" : "pagination"}
    //               onClick
    //             >
    //               {item}
    //             </span>
    //           ))}
    //         </div>
    //       ) : (
    //         <div className="dashboard-content-footer">
    //           <span className="empty-table">No data</span>
    //         </div>
    //       )} */}
    //     </div>
    //   </div>
    // </div>
    // {showCart && <Cart quantity={quantity} show={showCart} handleClose={() => (setShowCart(false))}></Cart>}
    // </Fragment>
    <div style={{ maxHeight: "100vh" }} className="overflow-hidden">
      <ToastContainer></ToastContainer>
      <HeaderRetailer set_seller_info={set_seller_info} setMessage={setMessage} ></HeaderRetailer>
      <Row style={{ marginLeft: "2%", marginRight: "1px" }}>
        <Col style={{ maxHeight: "100vh" }} md={9} xs={12}>
          <div>
            <Form.Group style={{ marginTop: "17px" }}>

              <InputGroup id="search" >
                <Form.Control
                  className="search"
                  id="menu"
                  placeholder="Search"
                  onChange={searchHandler}
                  ref={search_ref}
                />
                {/* <button onClick={searchHandler} class="search-icon w-16" type="button">
                    <img src={search_icon} className="ml-auto mr-auto w-5 h-5" ></img>
                  </button> */}
              </InputGroup>

            </Form.Group>
          </div>
          <div className="table-scroll" style={{ fontFamily: "Poppins", maxHeight: "70%" }}>
            {!mobileView ? (<Table>
              <thead className="text-[11px] sm:text-xs md:text-sm" >
                <tr className="text-center" >
                  <th>Id</th>
                  <th>Product</th>
                  <th >Offer</th>
                  <th style={{ paddingRight: "13px" }}>Order Qty</th>
                  <th >Bonus</th>
                  <th >T.P</th>
                  <th >Action</th>
                </tr>
              </thead>
              {filter_categories.length > 0 &&
                search_ref.current.value.length === 0 ? (
                filter_categories.map((item, index) => (
                  <tbody className="text-[11px] sm:text-xs md:text-sm">
                    <tr
                      key={index.cat}
                      style={{ height: "50px" }}
                      className="Category"
                    >
                      <td
                        colspan="8"

                      >
                        <h1 className="text-lg">{item.cat}</h1>
                      </td>
                    </tr>
                    {data
                      .filter((f) => f.manufacturer === item.cat)
                      .map((item, index) => (
                        <tr className="text-center" key={index}>
                          <td>{item.product_code}</td>
                          <td>{item.product_name}</td>
                          <td>{item.offer}%</td>
                          <td>
                            <div className="flex gap-1 justify-center">
                              <Button
                                onClick={() => subqty(item.id)}
                                className="qty-btn rounded w-7 sm:w-8 md:w-8 h-7"
                              >
                                <p className="mb-auto">-</p>
                              </Button>
                              <span className="qty-text rounded text-center h-7 w-8 sm:w-16">
                                <p className="text-xs sm:text-sm md:text-base mt-1">{item.quantity}</p>
                              </span>
                              <Button
                                onClick={() => addqty(item.id)}
                                className="qty-btn rounded  w-8 sm:w-8 md:w-8  h-7"
                              >
                                +
                              </Button>
                            </div>
                          </td>
                          <td>{item.bonus}</td>
                          <td>Rs. {item.total_price}/-</td>
                          <td>
                            <div className="flex gap-2 justify-center">
                              <button
                                onClick={() => setItems([...cartItems, item])}
                                className="add-btn"
                              >
                                <p className="text-center text-[9px] sm:text-xs md:text-xs" style={{ lineHeight: "30px" }}>Add</p>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ))
              ) : (
                <tbody className="text-[11px] sm:text-xs md:text-sm">
                  {searchList.map((item, index) => (
                    <tr key={index} className="text-center">
                      <td>{item.product_code}</td>
                      <td>{item.product_name}</td>
                      <td>{item.offer}%</td>
                      <td>
                        <div className="flex gap-1 justify-center">
                          <Button
                            onClick={() => subqty(item.id)}
                            className="qty-btn rounded w-8 h-7 "
                          >
                            -
                          </Button>
                          <span className="qty-text rounded text-center h-7 w-16">
                            <p>{item.quantity}</p>
                          </span>
                          <Button
                            onClick={() => addqty(item.id)}
                            className="qty-btn rounded w-8 h-7"
                          >
                            +
                          </Button>
                        </div>
                      </td>
                      <td>{item.bonus}</td>
                      <td>Rs. {item.total_price}/-</td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          <button
                            onClick={() => setItems([...cartItems, item])}
                            className="add-btn"
                          >
                            <p className="text-center text-[9px] sm:text-xs md:text-xs" style={{ lineHeight: "30px" }}>Add</p>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>) : (
              <Table>
                <thead className="text-[11px] sm:text-xs md:text-sm" >
                  <tr className="text-center">
                    <th>Product</th>
                    <th >Offer</th>
                    <th >Bonus</th>
                    <th >T.P</th>
                    <th >Action</th>
                  </tr>
                </thead>
                {filter_categories.length > 0 &&
                  search_ref.current.value.length === 0 ? (
                  filter_categories.map((item, index) => (
                    <tbody className="text-[11px] sm:text-xs md:text-sm">
                      <tr
                        key={index.cat}
                        style={{ height: "50px" }}
                        className="Category"
                      >
                        <td
                          colspan="8"
                        >
                          <h1 className="text-lg">{item.cat}</h1>
                        </td>
                      </tr>
                      {data
                        .filter((f) => f.manufacturer === item.cat)
                        .map((item, index) => (
                          <tr key={index} className="text-center">
                            <td>{item.product_name}</td>
                            <td>{item.offer}%</td>
                            <td>{item.bonus}</td>
                            <td>Rs. {item.total_price}/-</td>
                            <td>
                              <div className="flex gap-2 justify-center">
                                <button
                                  onClick={() => QuantityModalController(item)}
                                  className="add-btn"
                                  style={{ backgroundColor: "#005F45", color: "white" }}
                                >
                                  <p className="text-center text-[9px] sm:text-xs md:text-xs" style={{ lineHeight: "30px" }}>Add</p>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ))
                ) : (
                  <tbody className="text-[11px] sm:text-xs md:text-sm">
                    {searchList.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{item.product_name}</td>
                        <td>{item.offer}%</td>
                        <td>{item.bonus}</td>
                        <td>Rs. {item.total_price}/-</td>
                        <td>
                          <div className="flex gap-2 justify-center">
                            <button
                              onClick={() => QuantityModalController(item)}
                              className="add-btn"
                              style={{ backgroundColor: "#005F45", color: "white" }}
                            >
                              <p className="text-center text-[9px] sm:text-xs md:text-xs" style={{ lineHeight: "30px" }}>Add</p>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {loader && <Spinner></Spinner>}
            {search_ref.current.value && searchList.length === 0 && (
              <p>No Data Found</p>
            )}
            {data.length === 0 && <p>{error_msg}</p>}
          </div>
        </Col>
        {showCart && <Col md={3}>
          <Cart
            subqty={subqty}
            addqty={addqty}
            onClick={checkout}
            btnText={"Checkout"}
          ></Cart>
        </Col>}
      </Row>
      <Col md={5} xs={0} sm={0}>
        {cartmobile && <CartMobile
          subqty={subqty}
          addqty={addqty}
          onClick={checkout}
          btnText={"Checkout"}
          CloseHandler={CloseHandler}
        >
        </CartMobile>}
      </Col>
      {showCartSummary && <CartSummary show={showCartSummary} handleClose={CloseHandler}></CartSummary>}
      {IsSeller && <AlertSignedInAs show={IsSeller}></AlertSignedInAs>}
      {quantityModal && <QuantityModal items={items} setItems={setItems} cartItems={cartItems} addtoCart={addtoCart} data={data} item={quantityModalItem} show={quantityModal} handleClose={CloseHandler}></QuantityModal>}
      {seller_info && <SellerInfoModal show={seller_info} handleClose={CloseHandler}></SellerInfoModal>}
    </div>
  );
}

export default Home;
