import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import pic from "../../images/heropic.jpg";
import { action_type } from "../../store/reducer";
import { useStateValue } from "../../store/stateprovider";
import HeaderRetailer from "./HeaderRetailer";
import { useNavigate, useParams } from "react-router-dom";
import "../Style/CartMobile.css";
import delete_icon from "../../images/delete-icon.svg";
import { MdCancel } from 'react-icons/md';
import { motion } from 'framer-motion';
import BASE_URL from '../Config';


function CartMobile(props) {
    const mobile_height = window.innerHeight.toString();
    const [{ user, cartItems }, dispatch] = useStateValue();
    const [flag, setFlag] = useState(1);
    const [tot, setTot] = useState(0);
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchList, setSearchList] = useState([]);
    let items = [];

    useEffect(() => {
        const tableContainer = document.querySelector('.cart-container');

        // Check if the table overflows its container element
        if (tableContainer.scrollHeight > tableContainer.clientHeight) {
            // Show the scroll bar
            tableContainer.style.overflowY = 'scroll';
        }
    }, [items]);

    useEffect(() => {
        items = cartItems;
    }, [items]);

    const clearCart = () => {
        dispatch({
            type: action_type.SET_CART_ITEMS,
            cartItems: [],
        });
        localStorage.setItem("cartItems", JSON.stringify([]));
    };

    const cartDispatch = () => {
        localStorage.setItem("cartItems", JSON.stringify(items));
        setFlag(1);
        dispatch({
            type: action_type.SET_CART_ITEMS,
            cartItems: items,
        });
    };

    const remove_item = (id) => {
        items = cartItems.filter((item) => item.id !== id);
        cartDispatch();
    };

    useEffect(() => {
        let totalPrice = cartItems.reduce(function (accumulator, item) {
            return accumulator + item.quantity * item.total_price;
        }, 0);
        setTot(totalPrice);
    }, [tot, cartItems, props.addqty, props.subqty]);


    const closeHandler = () => {
        props.CloseHandler();
    }

    // const Buy_Now = async (event) => {
    //   event.preventDefault();
    //   for (const key in cartItems) {
    //     const product_data = {
    //       retailer_name: "PQRS",
    //       total: tot,
    //       status: "Pending",
    //       products: cartItems[key].product_name,
    //       wholeseller_id: id,
    //     };

    //     const response = await fetch("${BASE_URL}/Orders", {
    //       method: "POST",
    //       body: JSON.stringify(product_data),
    //       headers: { "Content-Type": "application/json" },
    //     });
    //     const data = await response.json();
    //     console.log(data);
    //   }
    //   toast.success("Updated Succesfully");
    //   clearCart();
    //   navigate(`/ordersRetailer/${id}`);
    // };

    return (
        <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            className="fixed z-50 top-0 right-0 w-[60%] text-sm bg-white drop-shadow-md"
            style={{ height: `${mobile_height}px` }}
        >
            <div className="cart-header text-sm flex">
                <p className="pt-4 pl-3">Your Order</p>
                <div className="ml-auto mr-6 mt-4 cursor-pointer" onClick={closeHandler}><MdCancel size={20} /></div>
            </div>
            <div className="underline"></div>
            <div style={{ maxHeight: "340px" }} className="cart-container overflow-x-hidden">
                <div className="cart-item pl-3">
                    {cartItems && cartItems.length > 0 ? (
                        cartItems.map((item, index) => (
                            <Row key={index}>
                                <Col>
                                    <h6>{item.product_name}</h6>
                                    <div className="flex gap-2">
                                        <p className="text-sm font-semibold text-green-600">
                                            {item.total_price}
                                        </p>
                                        <div className="flex gap-2 text-sm font-medium text-gray-600">
                                            <p>x</p>
                                            <p>{item.quantity}</p>
                                            <p>=</p>
                                        </div>
                                        <p className="flex gap-2 text-sm font-medium text-sky-400">
                                            ${item.total_price * item.quantity}
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <img className="pr-3 mt-2" onClick={() => remove_item(item.id)} src={delete_icon}></img>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <p>No items added</p>
                    )}
                </div>
                <div className={!props.btnText ? `absolute bottom-2` : `absolute w-[107%] bottom-2`}>
                    <div
                        className="total-mobile w-[350%]"
                    >
                        <div className="flex gap-4">
                            <p style={{ marginLeft: "5%", marginTop: "5%" }}>Total</p>
                            <h6 className="font-bold" style={{ marginTop: "5%" }}>
                                Rs. {tot}/-
                            </h6>
                        </div>
                    </div>
                    {props.btnText && <div className=""><Button onClick={props.onClick} className="button-mobile h-10"><p>{props.btnText}</p></Button></div>}
                </div>
            </div>
        </motion.div>
    );
}
export default CartMobile;