import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddForm from "./AddForm";
import DashboardHeader from "./DashboardHeader";
import EditModal from "./EditModal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/CRUD.css";
import delete_icon from "../../images/delete-icon.svg";
import edit_icon from "../../images/edit-icon.svg";
import search_icon from "../../images/ri_search-line.svg";
import AlertSignedInAs from "./AlertSignedInAs";
import BASE_URL from '../Config';
import Header from "./Header";
import Cookies from "js-cookie";
import { useStateValue } from "../../store/stateprovider";
import { useNavigate } from "react-router-dom";
import ColumnsModal from "./ColumnsModal";


function CRUD(props) {
  const [edit, setEdit] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [data, setData] = useState([]);
  const [id, setid] = useState(0);

  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(Cookies.get("user"));

  const [{ user }] = useStateValue();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [add, setAdd] = useState(false);
  const [error_msg, set_error_msg] = useState("");
  const [IsRetailer, setIsRetailer] = useState(false);
  const [loader, setloader] = useState(false);
  const [mobile, setIsMobile] = useState(false);
  const [column_modal, set_column_modal] = useState(false);
  const [show_id, set_show_id] = useState(false);
  const [show_offer, set_show_offer] = useState(false);
  const [show_bonus, set_show_bonus] = useState(false);

  const column_handler = (col) =>{
    if(col === "id"){
      set_show_id(!show_id);
    }
    if(col === "offer"){
      set_show_offer(!show_offer);
    }
    if(col === "bonus"){
      set_show_bonus(!show_bonus);
    }
  }

  const set_show_columns = (value) =>{
    set_show_id(value);
    set_show_offer(value);
    set_show_bonus(value);
  }
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000); // Set breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(()=>{
    if(!mobile){
      set_show_columns(true);
    }
    else{
      set_show_columns(false);
    }
  },[mobile])

  const navigate = useNavigate();
  // const data = [
  //   {
  //     id: 1,
  //     product_name: "Panadol",
  //     manufacturer: "Pfizer",
  //     offer: 5,
  //     bonus: 1,
  //     Exp_date: "25/1/2023",
  //   },
  // ];

  useEffect(() => {
    if (user && user.type === "retailer") {
      setIsRetailer(true);
    }
  }, [])

  const get_data = async () => {
    setloader(true);
    const response = await fetch(
      `${BASE_URL}/WholesellerCategory/${user.id}`
    ).catch(error=>{
      console.log("abc")
    });
    if(!response){
      toast.error("Error Occured. Please try again");
      setloader(false);
    }
    const Data = await response.json();
    const loaded_data = [];
    for (const key in Data) {
      loaded_data.push({
        id: Data[key].id,
        product_name: Data[key].product_name,
        manufacturer: Data[key].manufacturer,
        offer: Data[key].offer,
        bonus: Data[key].bonus,
        total_price: Data[key].total_price,
        product_code: Data[key].product_code
      });
    }
    setloader(false);
    setData(loaded_data);
    if (!data || loaded_data.length === 0 || data.length === 0) {
      set_error_msg("Data Not Found");
    }
    
  };

  useEffect(() => {
    get_data();
  }, []);

  const EditHandler = (id) => {
    setEdit(true);
    setid(id);
  };

  const DeleteHandler = (id) => {
    setDelete(true);
    if (window.confirm("Are You Sure") === true) {
      axios
        .delete(`${BASE_URL}/MedicalProducts/${id}`)
        .then((result) => {
          if (result.status === 200) {
            get_data();
            toast.success("Deleted Successfully");
          }
        })
        .catch((error) => {
          toast.error("Error Occured. Please try again");
          setloader(false);
          console.log(error);
        });
    }
  };

  const CloseHandler = () => {
    setEdit(false);
    setDelete(false);
    setAdd(false);
    set_column_modal(false);
  };


  const categories = new Set();
  for (const key in data) {
    categories.add(data[key].manufacturer);
  }

  let filter_items = [];

  const filter_categories = [];
  for (const i of categories) {
    filter_categories.push({ cat: i });
    filter_items.push(data.filter((f) => f.manufacturer === i));
  }

  // const filter = data.filter((f) => {
  //   return f.manufacturer === "GSK";
  // });
  // console.log(filter);

  // const a = []

  const search_ref = useRef();

  const searchHandler = () => {
    if (search_ref.current.value !== "") {
      const newList = data.filter((d) => {
        return Object.values(d)
          .join(" ")
          .toLowerCase()
          .includes(search_ref.current.value.toLowerCase());
      });
      setSearchList(newList);
    } else {
      get_data();
    }
  };

  return (
    <div className="overflow-hidden">
      <Header></Header>
      <ToastContainer />
      <Row style={{ marginLeft: "2%", marginRight: "0.5%", marginTop: "1%" }}>
        <Col md={9} xs={12}>
          <div className="mt-3 mb-3 flex gap-4">
            <h3 className="text-2xl font-bold">Product List</h3>
          </div>
          <div className="flex">
              <Form.Control
                className="search"
                id="menu"
                placeholder="Search"
                onChange={searchHandler}
                ref={search_ref}
              />
              {/* <button onClick={searchHandler} class="search-icon w-16" type="button">
                <img src={search_icon} className="ml-auto mr-auto w-5 h-5" ></img>
              </button> */}
            {loader ? <Button size="md" style={{ height: "50px", marginLeft: "20px", width: "90px" }} variant="success"><Spinner size="sm"></Spinner></Button> :
              <Button onClick={() => (navigate("/SelectProducts"))} size="md" style={{fontSize:"90%", height: "50px", marginLeft: "20px"}} variant="success">Add Products</Button>
            }
          </div>
          <div className="text-sm md:text-base" style={{ fontFamily: "Poppins", maxWidth:"100%", overflowX:"scroll" }}>
            <Table>
              <thead className="text-[11px] sm:text-xs md:text-sm">
                <tr style={{ textAlign: "center" }}>
                  <th className={`${!show_id && 'hidden'}`}>ID</th>
                  <th>Product</th>
                  <th className={`${!show_offer && 'hidden'}`}>Offer</th>
                  <th className={`${!show_bonus && 'hidden'}`}>Bonus</th>
                  <th>T.P</th>
                  <th >Action</th>
                  {mobile && <th onClick={()=>set_column_modal(true)} className="cursor-pointer text-green-500 hover:text-green-700 text-xl align-middle">+</th>}
                </tr>
              </thead>
              {filter_categories.length > 0 &&
                search_ref.current.value.length === 0 ? (
                filter_categories.map((item, index) => (
                  <tbody className="text-[12px] sm:text-xs md:text-sm xl:text-sm">
                    <tr
                      key={index.cat}
                      style={{ height: "50px" }}
                      className="Category"
                    >
                      <td
                        colspan="8"
                      >
                        <h1 className="text-lg">{item.cat}</h1>
                      </td>
                    </tr>
                    {data
                      .filter((f) => f.manufacturer === item.cat)
                      .map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className={`${!show_id && 'hidden'}`}>{item.product_code}</td>
                          <td>{item.product_name}</td>
                          <td className={`${!show_offer && 'hidden'}`}>{item.offer}%</td>
                          <td className={`${!show_bonus && 'hidden'}`}>{item.bonus}</td>
                          <td>Rs. {item.total_price}/-</td>
                          <td>
                            <div className="flex gap-2 mt-[0.75rem] justify-center">
                              <img
                                onClick={() => EditHandler(item.id)}
                                className=" cursor-pointer"
                                src={edit_icon}
                              ></img>
                              <img
                                onClick={() => DeleteHandler(item.id)}
                                className=" cursor-pointer"
                                src={delete_icon}
                              ></img>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ))
              ) : (
                <tbody className="text-[12px] sm:text-xs md:text-sm xl:text-sm">
                  {searchList.map((item, index) => (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td className={`${!show_id && 'hidden'}`}>{item.product_code}</td>
                      <td>{item.product_name}</td>
                      <td className={`${!show_offer && 'hidden'}`}>{item.offer}%</td>
                      <td className={`${!show_bonus && 'hidden'}`}>{item.bonus}</td>
                      <td>Rs. {item.total_price}/-</td>
                      <td>
                        <div className="flex gap-2 mt-[0.75rem] justify-center">
                          <img
                            className=" cursor-pointer"
                            onClick={() => EditHandler(item.id)}
                            src={edit_icon}
                          ></img>
                          <img
                            onClick={() => DeleteHandler(item.id)}
                            className=" cursor-pointer"
                            src={delete_icon}
                          ></img>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {data.length === 0 && <p>{error_msg}</p>}
            {!searchList && <p>No Data Found</p>}
          </div>
        </Col>
      </Row>
      {edit && (
        <EditModal id={id} get_data={get_data} show={edit} handleClose={CloseHandler}></EditModal>
      )}
      {IsRetailer && (
        <AlertSignedInAs show={IsRetailer}></AlertSignedInAs>
      )}
      {column_modal && mobile && (
      <ColumnsModal show={column_modal} show_id={show_id} show_offer={show_offer} show_bonus={show_bonus} handleClose={CloseHandler} column_handler={column_handler}></ColumnsModal>
      )}
    </div>
  );
}
export default CRUD;
